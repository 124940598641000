import axios from "../axios/axios";
import {
  DeleteResponse,
  Response,
  UpdateResponse,
  SingleResponse,
} from "../types/response";
import {
  InsuranceProviders,
  // BoardStage,
  ClientForms,
  ClientMessage,
  ClientAuthCode,
  ClientAuthorization,
} from "../types/client";
import { BoardStage } from "../types/board";

export async function createClientWithBasicDetails(clientObj: any) {
  try {
    const data = await axios.post(`/client/`, {
      ...clientObj,
    });
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

/**
 * create client with contact details
 * @param contactObj
 * @returns
 */

export async function createClientWithContactDetails(contactObj: any) {
  try {
    const data = await axios.post(`/contact/`, {
      ...contactObj,
    });
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function uploadClientImage(fileObj: any, id: string) {
  try {
    const formData = new FormData();
    formData.append("file", fileObj as any);
    const { data } = await axios.post<Response<ClientForms>>(
      `/client/` + id + `/upload-profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function uploadClientParentImage(
  fileObj: any,
  id: string,
  index: string
) {
  try {
    const formData = new FormData();
    formData.append("file", fileObj as any);
    formData.append("index", index);
    const { data } = await axios.post<Response<ClientForms>>(
      `/client/` + id + `/upload-parent-profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function updateClientWithBasicDetails(clientObj: any) {
  try {
    const data = await axios.put(`/client/` + clientObj.id, {
      ...clientObj,
    });
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function updateClientWithContactDetails(contactObj: any) {
  try {
    // const data = await axios.put(`/contact/` + contactObj.id, {
    //   ...contactObj,
    // });
    // return data.data;

    let data = null;
    if (contactObj.id) {
      data = await axios.put(`/contact/${contactObj.id}`, { ...contactObj });
    } else {
      data = await axios.post(`/contact/`, {
        ...contactObj,
      });
    }
    return { status: data.data.status, message: data.data.message };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getAllClient() {
  try {
    const { data } = await axios.get(`/client`);
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function deleteClientById(id: any) {
  try {
    const data = await axios.delete(`/client/` + id);
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getClientById(id: any) {
  try {
    const data = await axios.get(`/client/` + id);
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function createClientInsurance(insuranceProviders: any) {
  try {
    const { data } = await axios.post("/client/insuranceProvider", {
      clientInsurance: [...insuranceProviders],
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function insuranceAuthorization(insuranceAuthorizations: any[]) {
  //handle inner try/catch & use async in map
  try {
    const data = await Promise.all(
      insuranceAuthorizations.map(async (item: any) => {
        try {
          const jsonData = JSON.stringify(item);
          const data = new FormData();
          data.append("data", jsonData);
          item.files.forEach((file: any, index: any) => {
            data.append(`file`, file);
          });
          return await axios.post("/client/authorization", data);
        } catch (innerError: any) {
          console.error(
            `Error processing item:`,
            innerError?.response?.data || innerError
          );
          throw innerError; // Re-throw to propagate the error to Promise.all
        }
      })
    );

    return {
      status: true,
      data,
      message: "Authorizations updated successfully",
    };
  } catch (e: any) {
    console.error("Error in insuranceAuthorization:", e?.response?.data || e);
    return {
      status: false,
      data: null,
      message: e?.response?.data?.message || e.message || "An error occurred",
    };
  }
}

export async function updateClientAuthorizations(
  clientAuthId: string,
  clientAuthorization: ClientAuthorization
) {
  try {
    const jsonData = JSON.stringify(clientAuthorization);
    const data = new FormData();
    data.append("data", jsonData);

    //new file, not in container yet.
    clientAuthorization.files.forEach((file: any, index: any) => {
      if (!file.containerName) {
        data.append(`file`, file);
      }
    });

    const res = await axios.put(`/client/authorization/${clientAuthId}`, data);

    return { status: true, data: res.data };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function createClientAuthorizations(
  clientId: string,
  clientAuthorization: ClientAuthorization
) {
  try {
    const jsonData = JSON.stringify({ ...clientAuthorization, clientId });
    const data = new FormData();
    data.append("data", jsonData);

    //new file, not in container yet.
    clientAuthorization.files.forEach((file: any, index: any) => {
      if (!file.containerName) {
        data.append(`file`, file);
      }
    });

    const res = await axios.post(`/client/authorization`, data);

    return { status: true, data: res.data };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function deleteAuthorizationById(id: string) {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/client/authorization/${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function deleteAuthorizationFileById(authId: string, key: string) {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `client/authorization/${authId}/delete-files?key=${key}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function updateClientInsuranceInfoById(
  clientId: string,
  insuranceData: InsuranceProviders[]
) {
  try {
    const data = await Promise.all(
      insuranceData.map((item: InsuranceProviders) => {
        if (item.id) {
          const newInsurance: any = {
            id: item.id,
            insuranceProviderName: item.insuranceProviderName,
            // insuranceId:
            //   item.insuranceId.toString().length < 3 ? "" : item.insuranceId,
            clientId: clientId,
            isPrimary: item.isPrimary,
          };
          return axios.put(
            `/client/insuranceProvider/${item.id}`,
            newInsurance
          );
        } else {
          const newInsurance: InsuranceProviders = {
            id: "",
            insuranceProviderName: item.insuranceProviderName,
            insuranceId:
              item.insuranceId.toString().length < 3 ? "" : item.insuranceId,
            clientId: clientId,
            isPrimary: item.isPrimary,
          };
          return axios.post(`/client/insuranceProvider`, {
            clientInsurance: [newInsurance],
          });
        }
      })
    );

    return { status: true, data };
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function deleteClientInsuranceById(id: string) {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/client/insuranceProvider/${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getAllClientIntakeStage() {
  try {
    const { data } = await axios.get("/board/intake");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getClientBoards() {
  try {
    const { data } = await axios.get<Response<BoardStage>>(
      "/board?type=Client"
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const getClientMessagesById = async (clientId: string) => {
  try {
    const { data } = await axios.get<Response<ClientMessage>>(
      `/comments/client?entityId=${clientId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postClientMessageById = async (
  message: string,
  clientId: string
) => {
  try {
    const { data } = await axios.post<SingleResponse<ClientMessage>>(
      "/comment/addClientComment",
      {
        message,
        clientId,
        commentType: "User",
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// export const updateClientMessageById = async (
//   selectedMessage: string,
//   commentId: string
// ) => {
//   try {
//     const { data } = await axios.put<UpdateResponse>(
//       `/comment/client/${commentId}`,
//       {
//         message: selectedMessage,
//       }
//     );
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export const deleteClientMessageById = async (commentId: string) => {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/comment/client/${commentId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getInactiveClients = async () => {
  try {
    const { data } = await axios.get(`/inactive/client`);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const copyAuthorizationById = async (id: string) => {
  try {
    const { data } = await axios.post(`client/authorization/copy/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const ClientService = {
  createClientWithBasicDetails,
  createClientWithContactDetails,

  //move to new location!!
  // createClientWithAvailability,
  // updateClientWithAvailabilityDetails,
  // deleteAvailabilityById,
  updateClientWithBasicDetails,
  updateClientWithContactDetails,

  // updateClientMessageById,
  getAllClient,
  deleteClientById,
  deleteClientMessageById,
  getClientById,
  uploadClientImage,
  createClientInsurance,
  insuranceAuthorization,
  updateClientAuthorizations,
  createClientAuthorizations,
  deleteAuthorizationById,
  deleteAuthorizationFileById,
  updateClientInsuranceInfoById,
  deleteClientInsuranceById,
  getAllClientIntakeStage,
  // postClientCardToBoard,
  getClientBoards,
  //moveClientToAnotherBoard,
  //postClientFiles,
  //getClientFilesById,
  //deleteClientFileById,
  getClientMessagesById,
  postClientMessageById,
  getInactiveClients,
  copyAuthorizationById,
};

export default ClientService;
