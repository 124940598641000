import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import { ValidatorForm } from "react-material-ui-form-validator";
// import FileUpload from "react-material-file-upload";
import { MuiFileInput } from "mui-file-input";
import BackupIcon from "@mui/icons-material/Backup";
import { AppDispatch, RootState } from "../../../redux/store";
import { Credentials } from "../../../types/provider";
import { InsuranceProvider } from "../../../types/response";
import {
  PostProviderCredential,
  UpdateProviderCredentialById,
} from "../../../redux/features/providers-main/provider-slice";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ProviderAddNewInsuranceProps {
  providerId: string;
  // deleteSingleProviderInsurance: (id: string) => Promise<void>;
  providerInsurance?: Credentials;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const ProviderAddNewInsuranceProviderForm: React.FC<
  ProviderAddNewInsuranceProps
> = ({
  providerId,
  // deleteSingleProviderInsurance,
  providerInsurance,
  setOpenModal,
}) => {
  // #region variables region

  //defined dispatch
  const dispatch = useDispatch<AppDispatch>();

  //insurance Providers Data from redux
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //duplicate error image
  // eslint-disable-next-line
  const [duplicateErrorImage, setDuplicateErrorImage] =
    useState<boolean>(false);
  //keeps track of activeTab for particular authorization
  const [activeTab, setActiveTab] = useState<string>("basic");

  //track new added insurance index
  // const [newInsuranceIndex, setNewInsuranceIndex] = useState<number>(0);

  //displays the input bar
  // const [inputShow, setInputShow] = useState<boolean>(false);

  //store the input provider name from the input
  // const [inputValue, setInputValue] = useState<string>("");

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //store new files
  const [newFiles, setNewFiles] = useState<File[]>([]);

  //stores the current credential
  const [currentCredential, setCurrentCredential] = useState<Credentials>({
    name: providerInsurance ? providerInsurance.name : "",
    id: providerInsurance ? providerInsurance.id : "",
    providerId: providerId || "",
    insuranceId: providerInsurance ? providerInsurance.insuranceId : "",
    complete: providerInsurance ? providerInsurance.complete : false,
    expirationDate: providerInsurance ? providerInsurance.expirationDate : "",
    files: providerInsurance ? providerInsurance.files : [],
  });

  // #endregion

  // #region methods region

  // handle the form submition
  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    if (!currentCredential.providerId) {
      return;
    }

    if (!currentCredential.insuranceId || !currentCredential.expirationDate) {
      setHasError(true);
      return;
    }
    if (providerInsurance !== undefined) {
      dispatch(
        UpdateProviderCredentialById({
          newFiles, //new Files
          providerInsurance: currentCredential,
        })
      );
    } else {
      dispatch(
        PostProviderCredential({
          complete: currentCredential.complete,
          expirationDate: currentCredential.expirationDate,
          name: currentCredential.name,
          newFiles,
          providerId: currentCredential.providerId,
        })
      );
    }
    setHasError(false);
    setOpenModal(false);
  };

  //handles deleting providerInsurance
  // const deleteInsurance = (credentialId: string) => {
  //   if (providerInsurance[providerInsurance.length - 1].id !== "") {
  //     if (deleteSingleProviderInsurance) {
  //       deleteSingleProviderInsurance(credentialId);
  //     }
  //   } else {
  //     setErrorMessage("At least One credential should be added");
  //     setHasError(true);
  //     setTimeout(() => {
  //       setErrorMessage("");
  //       setHasError(false);
  //     }, 2000);
  //   }
  //   setDeletePopup(false);
  // };

  //remove focus on input fields
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  //returns the insurance provider name
  const returnInsuranceProviderName = (id: string): string => {
    let returnName: string = "";
    insuranceProviders.forEach((item: InsuranceProvider) => {
      if (item.id === id) {
        returnName = item.insuranceProviderName;
        return;
      }
    });
    return returnName;
  };

  // #endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm
          onSubmit={(e: React.FormEvent<Element>) => handleSubmit(e)}
        >
          <div className="stepperForm__inner">
            <Grid container spacing={3} sx={{ position: "relative" }}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <div className="authListBox">
                  <div className="authListBox__item">
                    <div className="authListBox__head">
                      <p className="authListBox__title">
                        {providerInsurance !== undefined
                          ? providerInsurance.name
                          : "Insurance Provider"}
                      </p>
                    </div>
                    <div className="authListBox__body">
                      <span
                        className={
                          activeTab === "basic"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("basic");
                        }}
                      >
                        Basic
                      </span>
                      <span
                        className={
                          activeTab === "files"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("files");
                        }}
                      >
                        Files
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "basic" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 9 }}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={
                            insuranceProviders.find(
                              (item) =>
                                item.insuranceProviderName ===
                                currentCredential.name
                            )?.id || ""
                          }
                          id="authInsuranceProvider"
                          onChange={(e: SelectChangeEvent<string>) => {
                            if (e.target.value !== "Insurance Provider") {
                              let name = returnInsuranceProviderName(
                                e.target.value
                              );
                              setCurrentCredential({
                                ...currentCredential,
                                insuranceId: e.target.value,
                                name: name,
                              });
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authInsuranceProvider");
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {insuranceProviders?.map(
                            (item: InsuranceProvider) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.insuranceProviderName}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {hasError && !currentCredential.name && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select insurance provider
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 3 }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="fromLabel"
                      >
                        Complete
                      </InputLabel>
                      <Box className="checkBox-group">
                        <FormControlLabel
                          label={""}
                          control={
                            <Checkbox
                              checked={currentCredential.complete}
                              onChange={(e) => {
                                setCurrentCredential({
                                  ...currentCredential,
                                  complete: e.target.checked,
                                });
                              }}
                            />
                          }
                        />
                      </Box>
                    </Grid>

                    {/* not in use */}
                    {/* {inputShow && (
                      <Grid size={{ xs: 12, md: 9 }}>
                        <Box className="fromGroup">
                          <TextField
                            autoFocus={true}
                            type="text"
                            placeholder={"Enter new insurance & hit enter key"}
                            value={inputValue}
                            id="input"
                            onKeyDown={(
                              //e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                              e: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              if (e.key === "Enter" && inputValue?.length > 0) {
                                document.getElementById("input")?.blur();

                                const newInsuranceList: InsuranceProvider[] =
                                  [];
                                insuranceProviders.forEach(
                                  (item: InsuranceProvider) => {
                                    const newObj: InsuranceProvider = {
                                      id: item.id,
                                      insuranceProviderName:
                                        item.insuranceProviderName,
                                    };
                                    newInsuranceList.push(newObj);
                                  }
                                );

                                newInsuranceList.push({
                                  id: `${newInsuranceIndex}`,
                                  insuranceProviderName: inputValue,
                                });
                                dispatch(
                                  setUpdatedInsuranceProviders(newInsuranceList)
                                );

                                dispatch(
                                  setCredentialInsuranceProvider({
                                    index: newInsuranceIndex,
                                    value: `${newInsuranceIndex}`,
                                    name: inputValue,
                                  })
                                );
                                setInputShow(false);
                                setNewInsuranceIndex(newInsuranceIndex + 1);
                                setInputValue("");
                              }
                            }}
                            onChange={(
                              e: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                              >
                            ) => {
                              setInputValue(e.target.value);
                            }}
                          />
                        </Box>
                      </Grid>
                    )} */}

                    <Grid size={{ xs: 12, sm: 9 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Expiration Date
                        </InputLabel>
                        <TextField
                          id="date"
                          placeholder="Select Date"
                          type="date"
                          value={currentCredential.expirationDate}
                          sx={{ width: 220 }}
                          onChange={(e) => {
                            setCurrentCredential({
                              ...currentCredential,
                              expirationDate: e.target.value,
                            });
                          }}
                        />
                      </Box>
                      {hasError && !currentCredential.expirationDate && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select expiration date
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Box className="fromGroup">
                    <Grid container spacing={3}>
                      <Grid
                        size={{ xs: 12, md: 6, lg: 12 }}
                        className="fileUploadBlock"
                      >
                        <Box className="uploadIconBox">
                          <BackupIcon color="primary" className="uploadIcon" />
                          <Typography>
                            Drag 'n' drop files here or click to add files
                          </Typography>
                        </Box>
                        <MuiFileInput
                          multiple
                          hideSizeText
                          sx={{
                            "& .css-1fel157 > span": { display: "none" },
                            "& .css-flami6": { display: "none" },
                            "& fieldset": { display: "none" },
                          }}
                          value={newFiles}
                          onChange={(e: File[]) => {
                            setNewFiles([...newFiles, ...e]);
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <div className="uploadProfile__img selectedIn-fileBox client-addAuth-uploadProfile">
                          {currentCredential.files.length + newFiles.length >
                          0 ? (
                            <>
                              {/* existingFiles */}
                              {currentCredential.files.map(
                                (item: any, index: number) => (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>{item.fileName}</h1>
                                  </div>
                                )
                              )}
                              {/* newFiles */}
                              {newFiles.map((item, index) => (
                                <div className="selectedIn-file" key={index}>
                                  <h1>{item.name}</h1>
                                  <span>
                                    <DeleteIcon
                                      onClick={() => {
                                        setNewFiles(
                                          newFiles.filter(
                                            (_, fileIndex) =>
                                              fileIndex !== index
                                          )
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          ) : (
                            <p className="selectedIn-fileBlank">
                              Upload insurance and assessment documents here.
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          {/* handle custom input for insurance provider */}
          <Box className="stepperForm__footer">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              onClick={() => setHasError(true)}
            >
              {"Save Changes"}
            </Button>
          </Box>
        </ValidatorForm>
        <Dialog open={deletePopup} className="smallModel">
          <div>
            <DialogContent>
              {/* Are you sure want to delete this client ? */}
              Do you want to delete this credential record ?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setDeletePopup(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  setDeletePopup(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Box>
    </>
  );
};

export default ProviderAddNewInsuranceProviderForm;
