import React, { useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BackupIcon from "@mui/icons-material/Backup";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { MuiFileInput } from "mui-file-input";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import { useAppSelector } from "../../../redux/store";

import {
  AuthCode,
  ClientAuthCode,
  ClientAuthorization,
  InsuranceProviders,
} from "../../../types/client";
import { InsuranceProvider } from "../../../types/response";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ClientEditAuthorizationFormProps {
  onSubmit: () => void;
  deleteSingleAuthorization: (id: string | undefined) => void;
  activeTabFromParent: string;
  clientInsurance: InsuranceProviders[];
  editAuthorization: ClientAuthorization;
  setEditAuthorization: (auth: any) => void;
}

const ClientEditAuthorizationForm: React.FC<
  ClientEditAuthorizationFormProps
> = ({
  onSubmit,
  deleteSingleAuthorization,
  activeTabFromParent,
  clientInsurance,
  editAuthorization,
  setEditAuthorization,
  //deleteAuthorizationFile,
}) => {
  // #region variable region

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //show for warning message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //insurance Providers Data
  const clientInsuranceProviders = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //duplicate error image
  const [duplicateErrorImage] = useState<boolean>(false);

  //duplicate error image
  const [duplicateErrorImageMessage] = useState<string>("");

  // active tab
  const [activeTab, setActiveTab] = useState<string>(
    activeTabFromParent ? activeTabFromParent : "basic"
  );

  // new files
  const [files, setfiles] = useState<File[]>([]);

  //store authorization from slice
  const authorizations = useAppSelector((state) => state.client.authorizations);

  //duplication error insurance
  const [isInsuranceError, setIsInsuranceError] = useState<boolean>(false);

  //auth code error handler
  const [duplicateAuthCodeError, setDuplicateAuthCodeError] =
    useState<boolean>(false);

  // stores authcode
  const authcode = useAppSelector(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );

  // #region methods region

  //remove focus on input field
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // function to used the checked the auth object
  const last = (array: ClientAuthCode[]): boolean => {
    if (array.length < 1) return false;
    return !Boolean(
      array[array.length - 1].authCode && array[array.length - 1].units
    );
  };

  //make sure AuthInsurance found in existing ClientInsurance
  const validateInsurance = (e: any) => {
    if (clientInsurance !== undefined) {
      const currentInsurance = clientInsuranceProviders.find(
        (insurance) => insurance.id === e.target.value
      );
      const found = clientInsurance.find(
        (item: any) =>
          item.insuranceProviderName === currentInsurance?.insuranceProviderName
      );
      if (!currentInsurance || found === undefined) {
        setIsInsuranceError(true);
        return false;
      } else {
        setIsInsuranceError(false);
        return true;
      }
    }
  };

  const handleStartDate = (date: Dayjs | null) => {
    const startDate = dayjs(date).format("MM/DD/YYYY");
    setEditAuthorization({
      ...editAuthorization,
      authPeriod: {
        ...editAuthorization.authPeriod,
        startDate,
      },
    });
  };

  //handle enddate of authperiod
  const handleEndDate = (date: Dayjs | null) => {
    const endDate = dayjs(date).format("MM/DD/YYYY");
    setEditAuthorization({
      ...editAuthorization,
      authPeriod: {
        ...editAuthorization.authPeriod,
        endDate,
      },
    });
  };

  const handleChange = (e: any) => {
    setEditAuthorization({
      ...editAuthorization,
      [e.target.name]: e.target.value,
    });
  };

  //handle submitting the data
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let error = false;

    if (
      editAuthorization.authPeriod.startDate === "Invalid Date" ||
      editAuthorization.authPeriod.startDate === "" ||
      editAuthorization.authPeriod.endDate === "Invalid Date" ||
      editAuthorization.authPeriod.endDate === ""
    ) {
      error = true;
    }

    if (isInsuranceError) {
      error = true;
    }

    //turn off by Hui... relax logic to allow partial save
    editAuthorization.auth.forEach((item: ClientAuthCode) => {
      if (!item.authCode || !item.units) {
        error = true;
      }
    });

    if (error) {
      setHasError(true);
    } else {
      onSubmit();
    }
  };

  // #endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          <div className="stepperForm__inner" style={{ marginBottom: 0 }}>
            {errorMessage && hasError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </div>

          <div className="stepperForm__inner">
            <Grid container spacing={3} sx={{ position: "relative" }}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <div className="authListBox">
                  <div className="authListBox__item">
                    <div className="authListBox__head">
                      <p className="authListBox__title">
                        {editAuthorization.authorizationName}
                      </p>
                      <span className="authListBox__removeBtn">
                        <Delete
                          onClick={() => {
                            if (authorizations.length > 0) {
                              deleteSingleAuthorization(editAuthorization.id);
                            } else {
                              setHasError(true);
                              setErrorMessage(
                                "At least one authorization should be present"
                              );
                              setTimeout(() => {
                                setHasError(false);
                                setErrorMessage("");
                              }, 3000);
                            }
                          }}
                        />
                      </span>
                    </div>
                    <div className="authListBox__body">
                      <span
                        className={
                          activeTab === "basic"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("basic");
                        }}
                      >
                        Basic
                      </span>
                      <span
                        className={
                          activeTab === "files"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("files");
                        }}
                      >
                        Files
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "basic" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Authorization Name
                        </InputLabel>
                        <TextValidator
                          onChange={handleChange}
                          name="authorizationName"
                          value={editAuthorization.authorizationName}
                          validators={["required"]}
                          errorMessages={["Please enter authorization name"]}
                          id="authorizationName"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationName");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={editAuthorization.insuranceId}
                          id="authInsuranceProvider"
                          name="insuranceId"
                          onChange={(e) => {
                            validateInsurance(e);
                            handleChange(e);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authInsuranceProvider");
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {clientInsuranceProviders.map(
                            (item: InsuranceProvider) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.insuranceProviderName}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {!errorMessage &&
                        hasError &&
                        !editAuthorization.insuranceId ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select insurance provider
                          </FormHelperText>
                        ) : null}
                        {isInsuranceError ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select another insurance provider
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Auth Number
                        </InputLabel>
                        <TextValidator
                          onChange={handleChange}
                          name="authorizationNumber"
                          value={editAuthorization.authorizationNumber}
                          id="authorizationNumber"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationNumber");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <InputLabel className="fromLabel">Auth Period</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="Start Date"
                              value={dayjs(
                                editAuthorization.authPeriod.startDate
                              )}
                              format="MM/DD/YYYY"
                              onChange={handleStartDate}
                            />
                          </Grid>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="End Date"
                              value={dayjs(
                                editAuthorization.authPeriod.endDate
                              )}
                              format="MM/DD/YYYY"
                              onChange={handleEndDate}
                            />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                      {hasError &&
                        (editAuthorization.authPeriod.endDate === "" ||
                          editAuthorization.authPeriod.startDate === "") && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select auth Start date and auth End Date
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid
                      size={{ xs: 12 }}
                      sx={{ position: "relative", textAlign: "right" }}
                    >
                      <Button
                        disabled={
                          authorizations ? last(editAuthorization.auth) : true
                        }
                        className={
                          authorizations
                            ? last(editAuthorization.auth)
                              ? "addBtnDisable"
                              : "addBtn"
                            : "addBtn"
                        }
                        onClick={() => {
                          const auth = [...editAuthorization.auth];
                          auth.push({
                            authCode: "",
                            units: "",
                            authApprovalUnit: "",
                          });
                          setEditAuthorization({
                            ...editAuthorization,
                            auth: auth,
                          });
                        }}
                      >
                        + Add Auth Code
                      </Button>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      {duplicateAuthCodeError && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select different auth code
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ mt: "-20px" }}>
                      {authorizations &&
                        editAuthorization.auth.map(
                          (
                            authCodeItem: ClientAuthCode,
                            authCodeIndex: number
                          ) => {
                            let maxUnits = 0;
                            if (
                              editAuthorization.authPeriod.startDate &&
                              editAuthorization.authPeriod.endDate &&
                              authCodeItem.authCode
                            ) {
                              const weeks =
                                moment(
                                  new Date(
                                    editAuthorization.authPeriod.endDate
                                  ),
                                  "MM/DD/YYYY"
                                ).diff(
                                  moment(
                                    new Date(
                                      editAuthorization.authPeriod.startDate
                                    ),
                                    "MM/DD/YYYY"
                                  ),
                                  "week"
                                ) * 5;
                              maxUnits =
                                (authcode.find(
                                  (item) =>
                                    item.conceptValue === authCodeItem.authCode
                                )?.data?.unitsPerDay || 0) * weeks;
                            }
                            return (
                              <div key={authCodeIndex}>
                                <Grid container spacing={3}>
                                  <Grid size={{ xs: 5 }}>
                                    {authCodeIndex === 0 ? (
                                      <InputLabel className="fromLabel">
                                        Auth Code
                                      </InputLabel>
                                    ) : (
                                      ""
                                    )}
                                    <Box className="fromGroup">
                                      <Select
                                        labelId="multiple-select-label"
                                        name="authCode"
                                        id="authCode"
                                        value={
                                          editAuthorization.auth[authCodeIndex]
                                            .authCode
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("authCode");
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          if (
                                            editAuthorization.auth.find(
                                              (item) =>
                                                item.authCode === e.target.value
                                            )
                                          ) {
                                            setDuplicateAuthCodeError(true);
                                            return;
                                          } else {
                                            setDuplicateAuthCodeError(false);
                                          }

                                          setEditAuthorization({
                                            ...editAuthorization,
                                            auth: editAuthorization.auth.map(
                                              (item, index) =>
                                                index === authCodeIndex
                                                  ? {
                                                      ...item,
                                                      authCode: e.target.value,
                                                    }
                                                  : item
                                            ),
                                          });
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        {authcode.map((item: AuthCode) => {
                                          return (
                                            <MenuItem
                                              key={item.id}
                                              value={item.conceptValue}
                                            >
                                              {item.conceptValue}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>

                                      {!errorMessage &&
                                      hasError &&
                                      !editAuthorization.auth[authCodeIndex]
                                        .authCode ? (
                                        <FormHelperText
                                          style={{ color: "#d32f2f" }}
                                        >
                                          Please select auth code
                                        </FormHelperText>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid size={{ xs: 5 }}>
                                    {authCodeIndex === 0 &&
                                      (maxUnits > 0 ? (
                                        <InputLabel className="fromLabel">
                                          # of Units (max: {maxUnits})
                                        </InputLabel>
                                      ) : (
                                        <InputLabel className="fromLabel">
                                          # of Units
                                        </InputLabel>
                                      ))}
                                    <Box className="fromGroup">
                                      <TextValidator
                                        placeholder="##"
                                        className="form-control"
                                        name="units"
                                        id="units"
                                        type="number"
                                        max={maxUnits}
                                        value={
                                          editAuthorization.auth[authCodeIndex]
                                            .units
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("units");
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          setEditAuthorization({
                                            ...editAuthorization,
                                            auth: editAuthorization.auth.map(
                                              (item, index) =>
                                                index === authCodeIndex
                                                  ? {
                                                      ...item,
                                                      units: e.target.value,
                                                    }
                                                  : item
                                            ),
                                          });
                                        }}
                                        validators={[
                                          "required",
                                          "matchRegexp:^[1-9][0-9]*$",
                                        ]}
                                        errorMessages={[
                                          "Please enter unit",
                                          "unit must be a positive number",
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid
                                    sx={{ m: 10 }}
                                    className="time-group__space"
                                  >
                                    <Delete
                                      className="removeTimeBtn"
                                      onClick={() => {
                                        const auth = [
                                          ...editAuthorization.auth,
                                        ];
                                        auth.splice(authCodeIndex, 1);
                                        setEditAuthorization({
                                          ...editAuthorization,
                                          auth,
                                        });
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          }
                        )}
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Box className="fromGroup">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="fromLabel"
                    >
                      {duplicateErrorImage && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {duplicateErrorImageMessage}
                        </FormHelperText>
                      )}
                    </InputLabel>
                    <Grid container spacing={3}>
                      <Grid
                        size={{ xs: 12, md: 6, lg: 12 }}
                        className="fileUploadBlock"
                      >
                        <Box className="uploadIconBox">
                          <BackupIcon color="primary" className="uploadIcon" />
                          <Typography>
                            Drag 'n' drop files here or click to add files
                          </Typography>
                        </Box>
                        <MuiFileInput
                          multiple
                          value={files}
                          sx={{
                            "& .css-1fel157 > span": { display: "none" },
                            "& .css-flami6": { display: "none" },
                            "& fieldset": { border: "none" },
                          }}
                          onChange={(e: File[]) => {
                            setfiles(e);
                            let temp: any = [...editAuthorization.files, ...e];

                            setEditAuthorization({
                              ...editAuthorization,
                              files: temp,
                            });
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <div className="uploadProfile__img ">
                          {editAuthorization?.files?.length > 0 ? (
                            editAuthorization?.files?.map(
                              (item: any, index: number) => {
                                return (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>
                                      {
                                        item.fileName
                                          ? item.fileName //for existing file
                                          : item.name //for just uploaded one
                                      }
                                    </h1>
                                    <span>
                                      <Delete
                                        onClick={() => {
                                          const files = [
                                            ...editAuthorization.files,
                                          ];
                                          files.splice(index, 1);
                                          setEditAuthorization({
                                            ...editAuthorization,
                                            files,
                                          });
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p className="selectedIn-fileBlank">
                              Upload insurance and assessment documents here.
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button type="submit" className="button">
              {"Save Changes"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default ClientEditAuthorizationForm;
