import axios from "../axios/axios";
import { BoardStage } from "../types/board";
import {
  EmploymentDetails,
  ContactDetails,
  Credentials,
  ProviderForms,
  // BoardStage,
  ProviderMessages,
  IManagementList,
  ProviderCertificate,
} from "../types/provider";
import {
  Response,
  SingleResponse,
  DeleteResponse,
  UpdateResponse,
} from "../types/response";
// import ProviderInsuranceDetailsForm from "../pages/providers/providers-forms/ProviderInsuranceDetailsForm";
import _ from "lodash";

export const postEmploymentProviderDetails = async (
  reqData: EmploymentDetails
) => {
  try {
    const { data } = await axios.post<SingleResponse<EmploymentDetails>>(
      `/provider`,
      {
        ...reqData,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postSupervisorTeam = async (obj: IManagementList) => {
  try {
    const { data } = await axios.post<SingleResponse<IManagementList>>(
      `/provider/team`,
      {
        ...obj,
      }
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postProviderCases = async (obj: IManagementList) => {
  try {
    const { data } = await axios.post<SingleResponse<IManagementList>>(
      `/provider/cases`,
      {
        ...obj,
      }
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postContactProviderDetails = async (reqData: ContactDetails) => {
  try {
    const { data } = await axios.post<SingleResponse<ContactDetails>>(
      `/contact`,
      {
        ...reqData,
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//Deprecate!! use availability.service now!
// export const postAvailabilityProviderDetails = async (
//   reqData: AvailabilityDetails[]
// ) => {
//   try {
//     const { data } = await axios.post<SingleResponse<AvailabilityDetails>>(
//       `/availability`,
//       {
//         availabilities: reqData,
//       }
//     );

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export const GetAllProvidersEndpoint = async () => {
  try {
    const { data } = await axios.get<Response<ProviderForms>>(`/providers`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetTitleNames = async () => {
  try {
    const { data } = await axios.get(`/getTitleNames`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetProviderById = async (id: string) => {
  try {
    const { data } = await axios.get<SingleResponse<ProviderForms>>(
      `/provider/${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const DeleteProviderById = async (id: string) => {
  try {
    const { data } = await axios.delete<DeleteResponse>(`/provider/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
export async function deleteCredentialById(id: string) {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/provider/providerInsurance/${id}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const deleteCredentialFileById = async (fileObj: {
  id: string;
  key: string;
}) => {
  try {
    const { data } = await axios.post<DeleteResponse>("file/delete-files", {
      ...fileObj,
      type: "provider-insurance",
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateProviderEmploymentById = async (
  id: string,
  reqData: EmploymentDetails
) => {
  try {
    const { data } = await axios.put<UpdateResponse>(`/provider/${id}`, {
      ...reqData,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateProviderContactById = async (
  id: string,
  reqData: ContactDetails
) => {
  try {
    let data = null;
    if (id) {
      data = await axios.put<UpdateResponse>(`/contact/${id}`, {
        ...reqData,
      });
    } else {
      data = await axios.post(`/contact/`, {
        ...reqData,
      });
    }
    return { status: data.data.status, message: data.data.message };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const buildFormData = (data: any) => {
  const formData = new FormData();
  _.forOwn({ ...data }, function (value, key) {
    formData.append(key, value);
  });
  return formData;
};

const generateFormData = (pD: Credentials) => {
  const data = new FormData();
  Object.entries(pD).forEach((item) => {
    if (item[0] === "files") {
      data.append("files", item[1]);
    } else if (item[0] === "newFiles") {
      item[1].forEach((item: any) => {
        data.append("file", item);
      });
    } else {
      data.append(item[0], item[1]);
    }
  });
  return data;
};

export const updateProviderCredentialById = async (
  providerInsurance: Credentials,
  newFiles: File[]
) => {
  try {
    const updatedInsurace: any = {
      ...providerInsurance,
      files: JSON.stringify(providerInsurance.files), //existing Files for update purpose
      newFiles: newFiles,
    };
    const { data } = await axios.put(
      `/provider/${providerInsurance.providerId}/providerInsurance/${providerInsurance.id}`,
      generateFormData(updatedInsurace)
    );
    return data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export const postProviderCredential = async (payload: {
  expirationDate: string;
  complete: boolean;
  providerId: string;
  name: string;
  newFiles: File[];
}) => {
  const formData = new FormData();
  formData.append("expirationDate", payload.expirationDate);
  formData.append("complete", payload.complete.toString());
  formData.append("providerId", payload.providerId);
  formData.append("name", payload.name);
  payload.newFiles.forEach((file: File) => {
    formData.append("file", file);
  });
  try {
    const { data } = await axios.post("/provider/providerInsurance", formData);
    return data;
  } catch (e) {
    throw e;
  }
};

//Deprecate!! use availability.service now!
// export async function updateProviderAvailabilityById(availability: any) {
//   try {
//     let data = null;
//     // if (availability.id?.length > 0) {
//     //   data = await axios.put(`/availability/${availability.id}`, {
//     //     ...availability,
//     //   });
//     // } else {
//     data = await axios.post(`/availability`, {
//       availabilities: availability,
//     });
//     // }
//     return { status: data.data.status, message: data.data.message };
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// }

// export const GetfromDynamicEntityId = async (
//   clientAvailabilityId: string,
//   providerAvailabilityIds: string[]
// ) => {
//   try {
//     const data = await axios.post(`/schedules/remainingHours`, {
//       clientAvailabilityId,
//       providerAvailabilityIds,
//     });

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export async function uploadProviderImage(fileObj: any, id: string) {
  try {
    const formData = new FormData();
    formData.append("file", fileObj as any);
    const { data } = await axios.post<Response<ProviderForms>>(
      `/provider/` + id + `/upload-profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getProviderOnBoardingStages() {
  try {
    const { data } = await axios.get("/board/onboarding");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function getProviderBoards() {
  try {
    const { data } = await axios.get<Response<BoardStage>>(
      "/board?type=Provider"
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

// export async function deleteAvailabilityById(id: string) {
//   try {
//     const { data } = await axios.delete<DeleteResponse>(`/availability/${id}`);

//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// }

export const getProviderMessagesById = async (providerId: string) => {
  try {
    const { data } = await axios.get<Response<ProviderMessages>>(
      `/comments/provider?entityId=${providerId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postProviderMessageById = async (
  message: string,
  providerId: string
) => {
  try {
    const { data } = await axios.post<SingleResponse<ProviderMessages>>(
      "/comment/addProviderComment",
      {
        message,
        providerId,
        commentType: "User",
      }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// export const updateProviderMessageById = async (
//   selectedMessage: string,
//   commentId: string
// ) => {
//   try {
//     const { data } = await axios.put<UpdateResponse>(
//       `/comment/provider/${commentId}`,
//       {
//         message: selectedMessage,
//       }
//     );
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

export const deleteProviderMessageById = async (commentId: string) => {
  try {
    const { data } = await axios.delete<DeleteResponse>(
      `/comment/provider/${commentId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getInactiveProviders = async () => {
  try {
    const { data } = await axios.get(`/inactive/provider`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const postCertificates = async (
  certificateName: string,
  expirationDate: string,
  providerId: string,
  files: File[]
) => {
  const formData = new FormData();
  formData.append("certificateName", certificateName);
  formData.append("expirationDate", expirationDate);
  formData.append("providerId", providerId);
  files.forEach((file) => {
    formData.append("file", file);
  });
  try {
    const { data } = await axios.post("/provider/certificate", formData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateCertificateById = async (
  payload: ProviderCertificate,
  providerId: string,
  newFiles: File[]
) => {
  const formData = new FormData();
  formData.append("certificateName", payload.certificateName);
  formData.append("expirationDate", payload.expirationDate);
  formData.append("files", JSON.stringify(payload.files));
  newFiles.forEach((file) => {
    formData.append("file", file);
  });

  try {
    const { data } = await axios.put(
      `/provider/${providerId}/certificate/${payload.id}`,
      formData
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const deleteCertificateById = async (certificateId: string) => {
  try {
    const { data } = await axios.delete(
      `/provider/certificate/${certificateId}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const ProviderService = {
  updateProviderContactById,
  updateProviderCredentialById,
  updateProviderEmploymentById,

  // postAvailabilityProviderDetails,
  // updateProviderAvailabilityById,
  // deleteAvailabilityById,

  // updateProviderMessageById,
  postEmploymentProviderDetails,
  postContactProviderDetails,
  postProviderCases,
  postSupervisorTeam,

  GetAllProvidersEndpoint,
  GetProviderById,
  DeleteProviderById,
  deleteProviderMessageById,
  deleteCredentialById,
  deleteCredentialFileById,

  //GetfromDynamicEntityId,
  getProviderBoards,
  getProviderOnBoardingStages,
  // postProviderCardToBoard,
  uploadProviderImage,
  //moveProviderToAnotherBoard,
  //getProviderFilesById,
  //postProviderFiles,
  //deleteProviderFileById,
  getProviderMessagesById,
  postProviderMessageById,
  GetTitleNames,
  getInactiveProviders,
  postCertificates,
  updateCertificateById,
  deleteCertificateById,
  postProviderCredential
};

export default ProviderService;
