import { ClickEventArgs, MenuEventArgs } from "@syncfusion/ej2-navigations";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Group,
  Sort,
  Inject,
  Filter,
  Edit,
  TextWrapSettingsModel,
  Selection,
  SelectionSettingsModel,
  ContextMenu,
  ExcelExport,
  PdfExport,
  GroupSettingsModel,
  Toolbar,
  ContextMenuItemModel,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getDataView } from "../../../service/dataview.service";
import { dataViewClient, dataViewProvider } from "../../../types/planning";
import { ISelectedRecord } from "../../schedules-main/Schedules";
import { Switch, Typography } from "@mui/material";

interface IDataTable {
  addIntoCalendar?: Function;
  setSelectedRecords?: Dispatch<SetStateAction<ISelectedRecord>>;
  type: string;
  beginToMatch?: Function;
  filteredIds?: string[];
}

let refresh: boolean;

const DataTable: React.FC<IDataTable> = ({
  beginToMatch,
  addIntoCalendar,
  setSelectedRecords,
  type,
  filteredIds,
}) => {
  //show weekend Toggle
  const [showWeekend, setShowWeekEnd] = useState(false);
  const [tableData, setTableData] = useState<
    (dataViewProvider | dataViewClient)[]
  >([]);

  const fetchData = async () => {
    let data = await getDataView();
    setTableData(data);
    setFilteredData(data);
  };

  //const groupOptions: Object = { showGroupedColumn: false, columns: [], };
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  let gridInstance: GridComponent;

  function dataBound() {
    if (refresh) {
      gridInstance.groupColumn("name");
      refresh = false;
    }
  }

  function load(this: any) {
    refresh = this?.refreshing;
  }

  //filtered Data
  const [filteredData, setFilteredData] = useState<
    (dataViewProvider | dataViewClient)[]
  >([]);

  //toolbar for grid
  const toolbarOptions: string[] = ["PdfExport", "ExcelExport"];
  const created = () => {
    if (gridInstance) {
      let toolbar = gridInstance.element.querySelector(".e-toolbar")!;
      gridInstance.element.appendChild(toolbar);
    }
  };
  const toolbarClick = (args: ClickEventArgs) => {
    if (gridInstance) {
      if (args.item.id === "grid_pdfexport") {
        gridInstance.showSpinner();
        gridInstance.pdfExport();
      } else if (args.item.id === "grid_excelexport") {
        gridInstance.showSpinner();
        gridInstance.excelExport();
      }
    }
  };
  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  const rowSelected = () => {
    if (gridInstance) {
      // const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
      const selectedrecords: any[] = gridInstance.getSelectedRecords();
      if (setSelectedRecords)
        setSelectedRecords({
          id: selectedrecords[0]?.id,
          type: selectedrecords[0].type,
        });
    }
  };

  const calendarMenu: ContextMenuItemModel[] = [
    { text: "Add Into Calendar", target: ".e-content", id: "addintocalendar" },
  ];
  const matchMenu: ContextMenuItemModel[] = [
    { text: "Begin To Match", target: ".e-content", id: "beginToMatch" },
  ];

  const contextMenuClick = (args: MenuEventArgs) => {
    if (gridInstance) {
      // const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
      const selectedrecords: object[] = gridInstance.getSelectedRecords();
      const rows = JSON.parse(JSON.stringify(selectedrecords));

      const filterRow: any[] = [];
      rows.forEach((row: any) => {
        if (!filterRow.some((r) => r.id === row.id)) {
          filterRow.push(row);
        }
      });
      if (args.item?.id === "addintocalendar" && addIntoCalendar) {
        addIntoCalendar(filterRow);
      }

      if (args.item?.id === "beginToMatch" && beginToMatch) {
        beginToMatch(filterRow);
      }
    }
  };

  useEffect(() => {
    if (tableData.length === 0) fetchData();
  }, [tableData]);

  useEffect(() => {
    if (filteredIds !== undefined) {
      const data = tableData.filter((item) => filteredIds.includes(item.id));
      setFilteredData(data);
    }
  }, [filteredIds, tableData]);

  return (
    <div className="control-pane" style={{ width: "100%" }}>
      {(!tableData) ? (
        <div>loading...</div>
      ) : (
        <div>
          {type === "Match" && (
            <div
              style={{
                display: "flex",
                padding: "6px 0px",
                width: "100%",
                justifyContent: "end",
              }}
            >
              {" "}
              <Typography sx={{ padding: "0 10px", fontWeight: "bold" }}>
                Show Weekend
              </Typography>
              <Switch
                sx={{
                  "--Switch-thumbSize": "28px",
                }}
                checked={showWeekend}
                onChange={() => setShowWeekEnd(!showWeekend)}
              />
            </div>
          )}
          <div className="control-section">
            <GridComponent
              id="grid"
              dataSource={filteredData}
              allowPaging={true}
              ref={(grid) => (gridInstance = grid as GridComponent)}
              pageSettings={{ pageSize: type === "Match" ? 15 : 12 }} //pageSize for rows per pagelann
              width="auto"
              height="350"
              dataBound={dataBound.bind(this)}
              load={load}
              allowGrouping={true}
              allowFiltering={true}
              filterSettings={{ type: "Menu" }}
              groupSettings={groupOptions}
              allowSorting={true}
              allowResizing={true}
              allowTextWrap={true}
              textWrapSettings={textWrapSettings}
              selectionSettings={selectionSettings}
              rowSelected={rowSelected}
              contextMenuItems={
                type === "Calendar"
                  ? calendarMenu
                  : type === "Match"
                  ? matchMenu
                  : []
              }
              contextMenuClick={contextMenuClick}
              toolbar={toolbarOptions}
              created={created}
              toolbarClick={toolbarClick}
              allowPdfExport={true}
              allowExcelExport={true}
              pdfExportComplete={pdfExportComplete}
              excelExportComplete={excelExportComplete}
            >
              <ColumnsDirective>
                <ColumnDirective field="type" headerText="Type" width="80" />
                <ColumnDirective field="name" headerText="Name" width="150" />
                <ColumnDirective
                  field="timeRange"
                  headerText="Range"
                  width="120"
                />
                {showWeekend && (
                  <ColumnDirective
                    field="Sunday"
                    headerText="Sun"
                    width="150"
                    disableHtmlEncode={false}
                  />
                )}
                {/* <ColumnDirective field="Sunday_hr" headerText="Hr" width="40" /> */}
                <ColumnDirective
                  field="Monday"
                  headerText="Mon"
                  width="200"
                  disableHtmlEncode={false}
                />
                {/* <ColumnDirective field="Monday_hr" headerText="Hr" width="75" /> */}
                <ColumnDirective
                  disableHtmlEncode={false}
                  field="Tuesday"
                  headerText="Tue"
                  width="200"
                />
                {/* <ColumnDirective field="Tuesday_hr" headerText="Hr" width="75" /> */}
                <ColumnDirective
                  disableHtmlEncode={false}
                  field="Wednesday"
                  headerText="Wed"
                  width="200"
                />
                {/* <ColumnDirective
                field="Wednesday_hr"
                headerText="Hr"
                width="75"
              /> */}
                <ColumnDirective
                  disableHtmlEncode={false}
                  field="Thursday"
                  headerText="Thu"
                  width="200"
                />
                {/* <ColumnDirective field="Thursday_hr" headerText="Hr" width="75" /> */}
                <ColumnDirective
                  disableHtmlEncode={false}
                  field="Friday"
                  headerText="Fri"
                  width="200"
                />
                {/* <ColumnDirective field="Friday_hr" headerText="Hr" width="75" /> */}
                {showWeekend && (
                  <ColumnDirective
                    disableHtmlEncode={false}
                    field="Saturday"
                    headerText="Sat"
                    width="200"
                  />
                )}

                {/* <ColumnDirective field="Saturday_hr" headerText="Hr" width="75" /> */}
                <ColumnDirective
                  field="totalHr"
                  headerText="Total"
                  width="75"
                />
              </ColumnsDirective>

              <Inject
                services={[
                  Filter,
                  Page,
                  Group,
                  Sort,
                  Edit,
                  Selection,
                  ContextMenu,
                  Toolbar,
                  ExcelExport,
                  PdfExport,
                  Toolbar,
                  Resize,
                ]}
              />
            </GridComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;
