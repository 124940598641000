import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  useJsApiLoader,
  GoogleMap,
  InfoWindow,
  Libraries,
  Marker,
} from "@react-google-maps/api";
import { useUser } from "@descope/react-sdk";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";
import { GetAllProviders } from "../../../redux/features/providers-main/provider-list-slice";

import MapClientList from "../MapsList/MapClientList";
import MapProviderList from "../MapsList/MapProviderList";
import MapProviderProfile from "../Map-Profile/MapProviderProfile";
import MapClientProfile from "../Map-Profile/MapClientProfile";

import { ClientForms } from "../../../types/client";
import { ProviderForms } from "../../../types/provider";
import { ICenter, IMarkerData } from "../../../types/Common";
import { defaultUtilization } from "../../../constants/availability";

import { filterMaps } from "../../../helpers/maps-filter-helpers/maps-filter-helper";

import ClientMarker from "../../../assets/images/images-png/pin-green.png";
import ProviderMarker from "../../../assets/images/images-png/pin-blue.png";
import ClientHighlightMarker from "../../../assets/images/images-png/pin-red.png";
import ProviderHighlightMarker from "../../../assets/images/images-png/pin-red.png";

import "./mapDetails.scss";

const libraries: Libraries | undefined = ["places"]; // Define your required libraries here

const MapsDetails: React.FC = () => {
  // #region variable region

  //user from Descope
  const { user } = useUser();
  const currentTenantId = user?.customAttributes?.currentTenantId;

  const clientData = useAppSelector((state) => state.clientListSlice.listData);

  //stores client data according utlization date range
  const UtilizationClientData = useAppSelector(
    (state) => state.clientListSlice.utilizationListData
  );

  // handle change in client data
  const isDataUpdate = useAppSelector(
    (state) => state.clientListSlice.dataUpdate
  );

  // store variable for filtervalues
  const filterValues = useAppSelector((state) => state.mapsFilterSlice);

  // store variable for searchvalue
  const searchValue = useAppSelector((state) => state.activeStep.searchValue);

  //markers data
  const [markersData, setMarkersData] = useState<IMarkerData[]>([]);

  // *********************************************************
  //Provider Data fetching
  const providerData = useAppSelector(
    (state) => state.providerListSlice.listData
  );

  // *********************************************************
  // holds the popup data displyed on the map for provider or client
  const [popUpData, setPopUpData] = useState<
    ClientForms | ProviderForms | null
  >(null);

  //stores all selected Clients
  const [allSelectedClients, setSelectedClient] = useState<ClientForms[]>([]);

  //*********************************************************
  // eslint-disable-next-line
  const [clientDataItem, setClientDataItem] = useState<ClientForms>(
    clientData[0]
  );

  //stored selected provider
  const [selectedProvider, setSelectedProvider] = useState<ProviderForms[]>([]);

  //store markerMap data
  const [markerMap, setMarkerMap] = useState<{
    [key: string]: google.maps.Marker;
  }>({});

  //change to handle booking delete and edit
  // eslint-disable-next-line
  const [bookingChange, setBookingChange] = useState<boolean>(false);

  //handles openInfo for clients and providers
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (
    marker: google.maps.Marker,
    place: IMarkerData
  ) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  //selected place used for information address
  const [selectedPlace, setSelectedPlace] = useState<IMarkerData>({
    id: "",
    entityType: "",
    pos: {
      lat: 0,
      lng: 0,
    },
    fullAddress: "",
    name: "",
    utilization: defaultUtilization,
  });

  //dispatch defined
  const dispatch = useAppDispatch();

  //selected tab to display proper pages
  const [selectedTab, setSelectedTab] = useState<string>("Clients");

  //selected tab to display calendera and map

  // eslint-disable-next-line
  const [selectedApplicationTab, setSelectedApplicationTabs] =
    useState<string>("Map");

  //set Value for provider Profile popup
  const [activeProfile, setActiveProfile] = useState<boolean>(false);

  //set Value for Client Profile popup

  //binding action creators
  const dispatchAction = bindActionCreators(
    {
      //GetScheduleList,
      GetAllClientData,
      GetAllProviders,
      setResponseValue,
    },
    dispatch
  );

  //handle provider ids
  // eslint-disable-next-line
  const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>([]);

  //same dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);
  // #endregion

  // #region methods region

  //get key for map show
  const { isLoaded, loadError } = useJsApiLoader({
    //id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE",
    libraries: libraries, //googleMapLibraries, //must have here!!! since map will be loaded first.
  });

  const getClientPlaceFromId = (item: ClientForms) => {
    return markersData.filter((place: IMarkerData) => {
      return place.id === item.clientBasicDetails.id;
    })?.[0];
  };

  const getProviderPlaceFromId = (item: ProviderForms) => {
    return markersData.filter((place: IMarkerData) => {
      return place.id === item.employmentDetails.id;
    })?.[0];
  };

  // Function called after a delay
  const infOpen = () => {
    setInfoOpen(true);
  };

  const markerClickHandler = (place: IMarkerData) => {
    let setFlag = true;

    while (setFlag) {
      for (let i = 0; i < clientData.length; i++) {
        if (clientData[i].clientBasicDetails.id === place.id) {
          setPopUpData(clientData[i]);
          setActiveProfile(true);
          setFlag = false;
        }
      }
      for (let i = 0; i < providerData.length; i++) {
        if (providerData[i]?.employmentDetails.id === place.id) {
          setPopUpData(providerData[i]);
          setActiveProfile(true);
          setFlag = false;
        }
      }
    }
    // Remember which place was clicked
    setSelectedPlace(place);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setTimeout(infOpen, 0);
    // if you want to center the selected Marker
    // setCenter(place.pos);
  };

  const [center, setCenter] = useState<ICenter>({
    lat: 0,
    lng: 0,
  });

  const renderMap = () => {
    return (
      <GoogleMap
        center={center}
        zoom={12}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        options={{
          styles: [
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {markersData.map((place: IMarkerData, index: number) => (
          <Marker
            key={place.id}
            position={place.pos}
            // icon={place.entityType === "client"? ClientMarker : ProviderMarker}
            icon={{
              url:
                place.entityType === "client"
                  ? place.id === selectedPlace.id
                    ? ClientHighlightMarker
                    : ClientMarker
                  : place.id === selectedPlace.id
                  ? ProviderHighlightMarker
                  : ProviderMarker,
              scaledSize:
                place.id === selectedPlace.id
                  ? new google.maps.Size(70, 70)
                  : new google.maps.Size(50, 50),
            }}
            onLoad={(marker: google.maps.Marker) => {
              return markerLoadHandler(marker, place);
            }}
            onMouseOver={() => markerClickHandler(place)}
            onMouseOut={() => setInfoOpen(false)}
          />
        ))}
        {infoOpen && selectedPlace && (
          <InfoWindow
            anchor={markerMap[selectedPlace.id]}
            onCloseClick={() => {
              setInfoOpen(false);
              setActiveProfile(false);
              setPopUpData(null);
            }}
          >
            <div>
              <h3>{selectedPlace.name}</h3>
              <div>{selectedPlace.fullAddress}</div>
              <div>
                Avg Wk Booked:&emsp;&emsp;
                {selectedPlace.utilization.weeklyAvgBooked.toFixed(2)}
              </div>
              <div>
                Avg Wk Available:&ensp;&ensp;&nbsp;
                {selectedPlace.utilization.weeklyAvgAvailability.toFixed(2)}
              </div>
              <div>
                Avg Wk Remaining:&ensp;
                {selectedPlace.utilization.weeklyAvgRemainedByAvailability.toFixed(
                  2
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  // function for take a data from Provider list
  const providerIndexFunc = (item: ProviderForms) => {
    setPopUpData(item);
    setActiveProfile(true);
    setSelectedPlace(getProviderPlaceFromId(item));
  };

  //function for take a data from Clients list
  const clientIndexFunc = (item: ClientForms) => {
    setClientDataItem(item);
    setPopUpData(item);
    setActiveProfile(true);
    setSelectedPlace(getClientPlaceFromId(item));
  };

  //returns if the object passed is a provider
  const isProvider = (item: ProviderForms | ClientForms | null): boolean => {
    if (item) {
      if ("employmentDetails" in item) return true;
    }
    return false;
  };

  //access the customer details
  //is Checked payment done or not

  //store subscription plan data
  // #endregion

  // #region useEffect region

  //fetch and checked payment status
  useEffect(() => {
    let arr: ProviderForms[] = [];
    arr.push(providerData[0]);
    setClientDataItem(clientData[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData.length, providerData.length]);

  useEffect(() => {
    // if (payment && customerSubscriptions.data[0].id !== " ") {
    if (!currentTenantId) {
      return;
    }
    if (!clientData.length) {
      dispatchActionRef.current.GetAllClientData();
    }
    if (!providerData.length) {
      dispatchActionRef.current.GetAllProviders();
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenantId,clientData.length,providerData.length]);

  //Generates markers data from ClientForms Data &
  useEffect(() => {
    // eslint-disable-next-line
    const markerDesign: IMarkerData[] = [];

    let isCenterSet = false;

    filterMaps<ClientForms>(filterValues, searchValue, clientData).forEach(
      (item: ClientForms, index: number) => {
        if (
          !isCenterSet &&
          item?.clientContactDetails?.address?.fullAddress?.trim().length > 0 &&
          item?.clientContactDetails?.address?.lat !== 0
        ) {
          setCenter({
            lat: parseFloat(`${item?.clientContactDetails?.address?.lat}`),
            lng: parseFloat(`${item?.clientContactDetails?.address?.lng}`),
          });
          isCenterSet = true;
        }
        const markerDataClient: IMarkerData = {
          id: item?.clientBasicDetails?.id,
          entityType: "client",
          pos: {
            lat: parseFloat(`${item?.clientContactDetails?.address?.lat}`),
            lng: parseFloat(`${item?.clientContactDetails?.address?.lng}`),
          },
          fullAddress:
            item?.clientContactDetails?.address?.fullAddress +
            " " +
            item?.clientContactDetails?.city +
            " " +
            item?.clientContactDetails?.state +
            " " +
            item?.clientContactDetails?.zipCode,
          name:
            item?.clientBasicDetails?.childFirstName +
            " " +
            item?.clientBasicDetails?.childLastName,
          utilization: item?.clientUtilization,
        };

        markerDesign.push(markerDataClient);

        if (isCenterSet) setSelectedPlace(markerDataClient);
      }
    );

    filterMaps<ProviderForms>(filterValues, searchValue, providerData).forEach(
      (item: ProviderForms) => {
        if (
          !isCenterSet &&
          item?.contactDetails?.address?.fullAddress?.trim().length > 0 &&
          item?.contactDetails?.address?.lat !== 0
        ) {
          setCenter({
            lat: parseFloat(`${item?.contactDetails?.address?.lat}`),
            lng: parseFloat(`${item?.contactDetails?.address?.lng}`),
          });
          isCenterSet = true;
        }

        const markerDataProvider: IMarkerData = {
          id: item?.employmentDetails?.id,
          entityType: "provider",
          pos: {
            lat: parseFloat(`${item?.contactDetails?.address?.lat}`),
            lng: parseFloat(`${item?.contactDetails?.address?.lng}`),
          },
          fullAddress:
            item?.contactDetails?.address?.fullAddress +
            " " +
            item?.contactDetails?.city +
            " " +
            item?.contactDetails?.state +
            " " +
            item?.contactDetails?.zipCode,
          name:
            item?.employmentDetails?.firstName +
            " " +
            item?.employmentDetails?.lastName,
          utilization: item?.providerUtilization,
        };

        markerDesign.push(markerDataProvider);

        if (isCenterSet) setSelectedPlace(markerDataProvider);
      }
    );

    setMarkersData(markerDesign);

    return () => {
      setMarkersData([]);
      setCenter({ lat: 0, lng: 0 });
    };
  }, [providerData, clientData, filterValues, searchValue]);

  // active tab false then any thing want to write in searchbox
  useEffect(() => {
    setActiveProfile(false);
    setInfoOpen(false);
  }, [filterValues, searchValue]);

  useEffect(() => {
    setActiveProfile(false);
    setInfoOpen(false);
  }, [selectedTab]);

  // #endregion

  return (
    <React.Fragment>
      {/* <Box sx={{ flexGrow: 1 }} className="mapWrapper"> */}
      <Grid container spacing={2} columns={16} className="mapWrapper">
        <Grid
          size={{ xs: 16, md: 3 }}
          display="flex"
          sx={{ flexDirection: "column", height: "100%" }}
        >
          <Box display={"flex"}>
            <ul className="innerTabs">
              <li className="innerTabs__item">
                <button
                  onClick={() => {
                    setSelectedTab("Clients");
                    setActiveProfile(false);
                  }}
                  className={selectedTab === "Clients" ? "active" : ""}
                >  
                  Clients
                </button>
              </li>
              <li className="innerTabs__item">
                <button
                  onClick={() => {
                    setSelectedTab("Employees");
                    setActiveProfile(false);
                  }}
                  className={selectedTab === "Employees" ? "active" : ""}
                >
                  Employees
                </button>
              </li>
            </ul>
          </Box>
          {selectedTab === "Clients" && (
            <MapClientList
              clientsListData={
                isDataUpdate ? UtilizationClientData.Clients : clientData
              }
              clientFunc={clientIndexFunc}
              setBookingChange={setBookingChange}
              activeTab={true}
              setSelectedClient={setSelectedClient}
              allSelectedClients={allSelectedClients}
              setSelectedProvider={setSelectedProvider}
              providerListData={providerData}
              selectedProvider={selectedProvider}
            />
          )}
          {selectedTab === "Employees" && (
            <MapProviderList
              setSelectedProviderIds={setSelectedProviderIds}
              setBookingChange={setBookingChange}
              providerListData={
                isDataUpdate ? UtilizationClientData.Provider : providerData
              }
              selectedProvider={selectedProvider}
              providerSelectdData={setSelectedProvider}
              providerFunc={providerIndexFunc}
              activeTab={true}
            />
          )}
        </Grid>
        <Grid size={{ xs: 16, md: 13 }}>
          <div className="scheduleMap">
            <div className="mapStatus">
              <h6 className="mapStatus__text">
                <img src={ClientMarker} alt={ClientMarker}></img>
                Client
              </h6>
              <h6 className="mapStatus__text">
                <img src={ProviderMarker} alt={ProviderMarker}></img>
                Employee
              </h6>
            </div>

            {activeProfile &&
              (isProvider(popUpData) ? (
                <div className="mapUserInfo">
                  <MapProviderProfile
                    providerDatapopUP={popUpData as ProviderForms}
                  />{" "}
                </div>
              ) : (
                <div className="mapUserInfo">
                  <MapClientProfile
                    clientDataPopUP={popUpData as ClientForms}
                  />{" "}
                </div>
              ))}

            <div style={{ height: "100vh", width: "100%" }}>
              {isLoaded && renderMap()}
              {loadError && (
                <>
                  <div>Map cannot be loaded right now, sorry.</div>
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      {/* </Box> */}
    </React.Fragment>
  );
};

export default MapsDetails;
