import ManualBoard from "./ManualBoard";
import { Board } from "../../../../types/board";
interface customInterface {
  board: Board;
  updateHandler?: any;
  callbacks: any;
  deleteHandler?: any;
  customIntakeModal: (id: string) => void;
}

const CustomBoard: React.FC<customInterface> = ({
  board,
  deleteHandler,
  updateHandler,
  callbacks,
  customIntakeModal,
}) => {
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          margin: "0px 10px",
        }}
      >
        <Typography mr={1} variant="h6" fontWeight={"bold"}>
          Stages
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              padding: "0px 10px",
            }}
            onClick={updateHandler}
          >
            <SettingsOutlined sx={{ fontSize: "25px" }} color="primary" />
            <Typography mr={1}> Setting </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              padding: "0px 10px",
            }}
            onClick={deleteHandler}
          >
            <Delete sx={{ fontSize: "25px" }} color="primary" />
            <Typography mr={1}> Delete </Typography>
          </div>
        </div>
      </div> */}
      <div className="cardBlock cardBlockClient">
        <div className="clientBoardSlider-module">
          <div id={board.id} className={`clientBoardSlider`}>
            <ManualBoard
              boardInfo={board}
              callbacks={callbacks}
              chatCommand={customIntakeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomBoard;
