import axios from "../axios/axios";
import { CustomForm } from "../types/businessSettings";
import {
  IFormRequestPayload,
  IUpdatedCommunicationSettings,
} from "../types/forms";

//fetch forms
export const getFormsByType = async (formType?: string) => {
  try {
    const url = formType ? `/form?formType=${formType}` : "/allForm";
    const { data } = await axios.get(url);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getPublicForm = async (formId: string) => {
  try {
    const { data } = await axios.get(`/publicform/${formId}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post Parent api
export const submitPublicForm = async (formId: string, formData: FormData) => {
  try {
    const { data } = await axios.post(
      `/publicform/${formId}`,
      formData //FormData includes surveyData & files
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post custom form
export const postCustomForm = async (payloadData: CustomForm) => {
  try {
    const { data } = await axios.post(`/form`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateFormActiveById = async (id: string, value: boolean) => {
  try {
    const { data } = await axios.patch(`/form/${id}?value=${value}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateFormTypeById = async (id: string, value: boolean) => {
  try {
    const { data } = await axios.patch(`/form/${id}?type=${value}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//fetch assessmentform data by ID
export const getFormById = async (id: string) => {
  try {
    const { data } = await axios.get(`/form/${id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update  active  Intake form
export const updateFormDataById = async (id: string, payloadData: any) => {
  try {
    const { data } = await axios.put(`/form/${id}`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const duplicateFormDataById = async (id: string) => {
  try {
    const { data } = await axios.post(`/duplicateform/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//fetch communicationSetting values
export const getCommunicationSetting = async (communicationType: string) => {
  try {
    const { data } = await axios.get(
      `/communicationSetting?type=${communicationType}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// updates assessment setting values
export const updateCommunicationSetting = async (
  id: string,
  payloadData: IUpdatedCommunicationSettings
) => {
  try {
    const { data } = await axios.put(
      `/communicationSetting/${id}`,
      payloadData
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//delete form
export const deleteFormById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/form/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//----------------------------------------------------------------
//Form Request Services

// create form request for entity
export const createFormRequest = async (payloadData: IFormRequestPayload) => {
  try {
    const { data } = await axios.post(`/formrequest`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// fetch all Form requests
export const getFormRequestsByType = async (formType: string) => {
  try {
    const { data } = await axios.get(`/formrequest?formType=${formType}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//fetch FormRequest by ID
export const getFormRequestById = async (id: string) => {
  try {
    const { data } = await axios.get(`/formrequest/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//reprocess FormRequest
export const reprocessFormRequest = async (id: string) => {
  try {
    const { data } = await axios.patch(`/formrequest/reprocess/${id}`);
    return data;
  } catch (e: any) {
    // throw e?.response?.data;
    throw e;
  }
};

export const updateSessionFormCheckList = async (
  id: string,
  checkList: string[]
) => {
  try {
    const { data } = await axios.put(`/form/${id}/update/checklist`, {
      checkList,
    });
    return data;
  } catch (e: any) {
    throw e;
  }
};

export const updateSessionFormApplicable = async (
  id: string,
  insurances: string[],
  authCodes: string[]
) => {
  try {
    const { data } = await axios.put(`/form/${id}/update/applicable`, {
      insurances: insurances,
      authCodes: authCodes,
    });
    return data;
  } catch (e: any) {
    throw e;
  }
};

const cleanUpFormRequests = async (formIds: string[]) => {
  try {
    const { data } = await axios.delete("/formRequests", {
      data: { formIds },
    });
    return data;
  } catch (e: any) {
    throw e;
  }
};

const FormService = {
  getFormsByType,
  getFormById,
  updateFormActiveById,
  updateFormTypeById,
  getPublicForm,
  submitPublicForm,
  postCustomForm,

  deleteFormById,
  updateFormDataById,
  getCommunicationSetting,
  updateCommunicationSetting,
  duplicateFormDataById,

  //formrequest sevices
  createFormRequest,
  getFormRequestsByType,
  getFormRequestById,
  reprocessFormRequest,
  cleanUpFormRequests,
};

export default FormService;
