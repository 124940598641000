import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import {
  BoardCard,
  Board as boardInterface,
} from "../../../../types/board";
import Board from "../../../trello-board/Board";
import { ITaskState } from "../../../../types/trello-board";
import { useDispatch } from "react-redux";
import {
  setBusinessUnitFilters,
  updateCustomBoardStage,
} from "../../../../redux/features/custom-board/custom-board-slice";
import { moveClientCardToAnotherBoard } from "../../../../redux/features/client-main/client-board-slice";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { OrgBU } from "src/types/company";
import {useAppSelector } from "src/redux/store";
import { GetAllProviders } from "src/redux/features/providers-main/provider-list-slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ClientBoardProps {
  boardInfo: boardInterface;
  callbacks: ((clientId: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const Boards = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

const ManualBoard: React.FC<ClientBoardProps> = ({
  boardInfo,
  callbacks,
  chatCommand,
}) => {
  //#region variable region

  //handle tasks
  const [tasks, setTasks] = useState<ITaskState>({});

  //get all clients
  const clientList = useAppSelector((state) => state.clientListSlice.listData);

  //get all provider
  const providerList = useAppSelector(
    (state) => state.providerListSlice.listData
  );

  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  const businessUnitsFilterDetails = useAppSelector(
    (state) => state.boardSlice.selectedBusinessUnits
  );

  const selectedBusinessUnits =
    businessUnitsFilterDetails?.find((item) => item.boardId === boardInfo.id)
      ?.businessUnits || [];

  //handle dispatch
  const dispatch = useDispatch();
  //#endregion

  //#region methods region
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  //handle ondrag end
  const onDragEnd = async (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (source.droppableId === destination?.droppableId) return;
    let selectedUser: any, fromStageId, toStageId;
    const removeUser = tasks[source.droppableId].filter((user) => {
      if (user.id === draggableId) {
        selectedUser = { ...user, boardColumnUpdatedAt: new Date().toString() };
        return false;
      } else {
        return true;
      }
    });

    if (destination && selectedUser) {
      setTasks({
        ...tasks,
        [source.droppableId]: removeUser,
        [destination.droppableId]: [
          selectedUser,
          ...tasks[destination.droppableId],
        ],
      });

      const updatedBoard = boardInfo.stages.map((stage) => {
        if (stage.stageName === source.droppableId) {
          fromStageId = stage.id;
          return {
            ...stage,
            boardColumnNames: stage.boardColumnNames.filter(
              (card) => card.id !== selectedUser.id
            ),
          };
        } else if (stage.stageName === destination.droppableId) {
          toStageId = stage.id;
          return {
            ...stage,
            boardColumnNames: [...stage["boardColumnNames"], selectedUser],
          };
        } else {
          return stage;
        }
      });

      if (fromStageId && toStageId && selectedUser) {
        dispatch(
          moveClientCardToAnotherBoard({
            fromBoardId: fromStageId,
            toBoardId: toStageId,
            cardId: selectedUser.id,
            index: destination.index,
          })
        );

        dispatch(
          updateCustomBoardStage({
            boardId: boardInfo.id,
            stages: updatedBoard,
          })
        );
      }
    }
  };

  //#endregion

  //#region useEffect region
  const setInitialBoardTasks = () => {
    const convertedTotask: any = {};

    const stages = [...boardInfo.stages].sort((a, b) => a.index - b.index);
    stages.forEach((stage) => {
      convertedTotask[stage.stageName] = stage.boardColumnNames;
    });
    return convertedTotask;
  };

  // useEffect(() => {
  //   setInitialBoardTasks();
  // }, [boardInfo]);

  //business unit filtering
  useEffect(() => {
    if (!boardInfo) {
      return;
    }
    if (selectedBusinessUnits.length > 0) {
      const inittasks = setInitialBoardTasks();

      // Common logic to filter entity IDs based on business units
      const filteredEntityIds =
        boardInfo.boardType === "client"
          ? clientList
              .filter((client) =>
                selectedBusinessUnits.some((bu: OrgBU) =>
                  client.clientBasicDetails.businessUnit.includes(bu.id)
                )
              )
              .map((client) => client.clientBasicDetails.id)
          : providerList
              .filter((provider) =>
                selectedBusinessUnits.some((bu: OrgBU) =>
                  provider.employmentDetails.businessUnit.includes(bu.id)
                )
              )
              .map((provider) => provider.employmentDetails.id);

      // Reduce tasks based on filtered entity IDs
      const filteredTasks = boardInfo.stages.reduce<Record<string, any[]>>(
        (acc, stage) => {
          //get cards with stage name
          const stageCards = inittasks[stage.stageName] || [];

          const filteredCards = stageCards.filter((card: BoardCard) =>
            filteredEntityIds.includes(card.entityId)
          );

          acc[stage.stageName] = filteredCards;
          return acc;
        },
        {}
      );
      setTasks(filteredTasks);
    } else {
      // Reset to initial tasks if no business units are selected
      const initialTask = setInitialBoardTasks();
      setTasks(initialTask);
    }
  }, [selectedBusinessUnits.length, boardInfo, clientList, providerList]);

  useEffect(() => {
    if (boardInfo.boardType === "provider" && providerList.length === 0) {
      dispatch(GetAllProviders());
    }
  }, [providerList.length, boardInfo]);
  //#endregion

  return (
    <>
      <div className="taskHeader">
        <Typography>Filter:</Typography>
        <div className="taskFormController">
          <FormControl className="task-outer">
            <InputLabel id="demo-simple-select-label">
              {"Business Unit"}
            </InputLabel>
            <Select
              className="form-control bufilterDropdown"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: "250px" }}
              // value={selectedBU?.id}
              // onChange={(e) =>
              //   setSelectedBU(
              //     businessUnits.find((item) => item.id === e.target.value)
              //   )
              // }
              value={selectedBusinessUnits}
              onChange={(e) => {
                const selectedId = e.target.value;
                let updatedSelectedBU: OrgBU[] = [...selectedBusinessUnits];

                // Check if the item is already selected
                const isSelected = selectedBusinessUnits.some(
                  (item) => item.id === selectedId
                );

                if (isSelected) {
                  // Remove the item if already selected
                  updatedSelectedBU = selectedBusinessUnits.filter(
                    (item) => item.id !== selectedId
                  );
                } else {
                  // Find the new item and add it if not already selected
                  const newBUFilter = businessUnits.find(
                    (item) => item.id === selectedId
                  );
                  if (newBUFilter) {
                    updatedSelectedBU = [...selectedBusinessUnits, newBUFilter];
                  }
                }
                dispatch(
                  setBusinessUnitFilters({
                    boardId: boardInfo.id,
                    businessUnits: updatedSelectedBU,
                  })
                );
              }}
              renderValue={(selected: OrgBU[]) =>
                selected.map((b) => b.businessUnitName).join(", ")
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  removeFocus("demo-simple-select");
                }
              }}
              MenuProps={MenuProps}
            >
              {businessUnits?.map((item: OrgBU) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox checked={selectedBusinessUnits.includes(item)} />
                  {item.businessUnitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={"boards"}
          type={"boards"}
          direction={"horizontal"}
        >
          {({ innerRef, droppableProps, placeholder }) => (
            <Boards ref={innerRef} {...droppableProps}>
              <div className="allStageCards">
                {Object.keys(tasks).map((stageName, index) => {
                  return (
                    <Board
                      key={stageName}
                      index={index}
                      stage={
                        boardInfo?.stages?.filter(
                          (stage: any) => stage.stageName === stageName
                        )[0]
                      }
                      tasks={tasks[stageName]}
                      callbacks={callbacks}
                      chatCommand={chatCommand}
                    />
                  );
                })}
              </div>
              {placeholder}
            </Boards>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ManualBoard;
