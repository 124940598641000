import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Box,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  Stepper,
  StepLabel,
  Step,
  Grid2,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  TextareaAutosize,
  TableContainer,
  Paper,
  Table,
  tableCellClasses,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SelectChangeEvent,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import "./groupBookingModal.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ALert from "../../../assets/images/images-svg/alert.svg";
import { Delete, Add } from "@mui/icons-material";
import { ReactComponent as Search } from "../../../assets/images/images-svg/searchIcon.svg";
import { useAppSelector } from "src/redux/store";
import { ClientAuthPayload, ClientForms } from "src/types/client";
import { MenuProps } from "../../../constants/providers";
import { searchClientText } from "src/utils/FilterUtils";
import { AvailabilityTimesForSchedulesBars } from "src/constants/schedules-filters";
import moment from "moment";
import { returnAuthorizationValue } from "src/constants/common";
import { OrgBU } from "src/types/company";
import { useDispatch } from "react-redux";
import { getOrgInfo } from "src/redux/features/organization/organization-slice";
import dayjs, { Dayjs } from "dayjs";
import { WeekDaysFullName } from "src/constants/schedule";
import { useUser } from "@descope/react-sdk";
import { setResponseValue } from "src/redux/features/api-response/api-response";
import { createGroupBooking } from "src/service/schedules.service";
import { GetProviderById } from "src/redux/features/providers-main/provider-list-slice";

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
  },

  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
  height: "50px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  marginBlock: "10px",
}));

interface IGroupBookingModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  argsdata: Record<string, any> | undefined;
}

interface clientBooking {
  clientId: string;
  service: string;
}

// enum BookingServices {
//   "97154" = "Group Treatments - 97154",
//   "97157" = "Group Treatments - 97157",
//   "97158" = "Group Treatments - 97158",
// }

const GroupBookingModal: React.FC<IGroupBookingModalProps> = ({
  open,
  setOpen,
  argsdata,
}) => {
  //descope
  const { user } = useUser();
  const dispatch = useDispatch();
  const [activeStep, setActiveSteps] = useState<number>(0);
  const [serviceData, setServiceData] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [bookedClientsDetail, setbookedClientsDetail] = useState<
    {
      clientId: string;
      authId: string;
      authCode: string;
    }[]
  >([]);
  const [serviceLocation, setSeviceLocation] = useState<any>();
  const [selectedClientData, setSelectedClientData] = useState<ClientForms[]>(
    []
  );

  //used to store mapping client data list
  const [dataList, setDataList] = useState<ClientForms[]>([]);
  const [userAddress, setUserAddress] = useState<string[]>([]);
  const [repeatService, setRepeatService] = useState<number>(0);
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  //store initial client start time
  const clientStartTime: string =
    argsdata &&
    moment(argsdata?.EndTime).format("hh:mm A") !==
      moment(argsdata.StartTime).format("hh:mm A")
      ? moment(argsdata.StartTime).format("hh:mm A")
      : "07:00 AM";
  //changed start time for client
  const [startTime, setStartTime] = useState<string>(clientStartTime);

  //store initial client end time
  const clientEndTime: string = argsdata
    ? moment(argsdata.EndTime).format("hh:mm A")
    : "06:00 PM";
  //changed end time for client
  const [endTime, setEndTime] = useState<string>(clientEndTime);

  const groupBookingStages = [
    "Select Service",
    "Add Client",
    "Location & Time",
  ];

  const groupBookingServices = [
    { value: 97154, label: "Group Treatments - 97154" },
    { value: 97157, label: "Group Treatments - 97157" },
    { value: 97158, label: "Group Treatments - 97158" },
  ];

  // State to manage selected weekdays
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>(
    argsdata
      ? (WeekDaysFullName[moment(argsdata?.StartTime).day()].split(
          " "
        ) as string[])
      : []
  );

  // Weekday options
  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  // Suggested info (mock data for now, replace with dynamic data if needed)
  const weekdaySuggestion = [
    { day: "Monday", availability: "5/5" },
    { day: "Tuesday", availability: "4/5" },
    { day: "Friday", availability: "3/5" },
  ];

  const endDateSuggestion = "8/1/2024";

  const Allclients = useAppSelector((state) => state.clientListSlice.listData);

  const Allproviders = useAppSelector(
    (state) => state.providerListSlice.listData
  );
  const selectedProvider = Allproviders.find(
    (provider) => provider.employmentDetails?.id === argsdata?.id
  );
  //business units
  const orgBuUnits = useAppSelector<OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //handle custom end date
  const handleDateChange = (newValue: Dayjs | null) => {
    setCustomEndDate(newValue);
    setRepeatService(3);
  };

  // Function to toggle the selection of weekdays
  const toggleWeekday = (index: number) => {
    let oldSelectedDays: string[] = [...selectedWeekdays];
    if (oldSelectedDays?.indexOf(WeekDaysFullName[index]) > -1) {
      oldSelectedDays = oldSelectedDays.filter(
        (item) => item !== WeekDaysFullName[index]
      );
    } else {
      oldSelectedDays.push(WeekDaysFullName[index]);
    }
    setSelectedWeekdays(oldSelectedDays);
  };
  //get match value
  const getMatchData = (item: ClientForms, searchVal: string): boolean => {
    const searchText = searchClientText(searchVal, item);
    if (!searchText) {
      return false;
    }
    if (searchVal === "") {
      return false;
    }
    return true;
  };
  const handleResult = (searchVal: string) => {
    let result: any[] = [];
    if (searchVal !== undefined) {
      if (searchVal !== "") {
        Allclients.map((item: ClientForms) => {
          return getMatchData(item, searchVal) && result.push(item);
        });
        setDataList(result);
      } else {
        setDataList([]);
      }
    }
  };

  const getSelectedClientList = (ID: string) => {
    let arr: ClientForms[] = [];

    let result = Allclients.filter(
      (unit) => unit.clientBasicDetails.id === ID
    )[0];

    let val = selectedClientData?.find(
      (item) => item.clientBasicDetails.id === ID
    );
    arr.push(result);

    if (!val) {
      setSelectedClientData([...arr, ...selectedClientData]);
      setbookedClientsDetail([
        ...bookedClientsDetail,
        {
          clientId: ID,
          authId: "",
          authCode: "",
        },
      ]);
    }

    return arr;
  };

  // //gets index from time
  const getIndexFromTimeValidation = (val: string): number => {
    for (let i = 0; i < AvailabilityTimesForSchedulesBars.length; i++) {
      if (AvailabilityTimesForSchedulesBars[i] === val) return i;
    }
    return -1;
  };

  //Returns the drop down values for start and end time
  const getAvailabilityTimes = (index: number): JSX.Element[] => {
    const data: JSX.Element[] = [];
    for (let i = index; i < AvailabilityTimesForSchedulesBars.length; i++) {
      data.push(
        <MenuItem key={i} value={AvailabilityTimesForSchedulesBars[i]}>
          {AvailabilityTimesForSchedulesBars[i]}
        </MenuItem>
      );
    }
    return data;
  };

  const onSubmitGroupBooking = async () => {
    const bookingDate = moment(argsdata?.StartTime).format("MM/DD/YYYY");
    if (!bookingDate || !argsdata?.id) return;

    dispatch(setResponseValue({ name: "pending", value: true }));
    setOpen(false);
    try {
      let bookingData: any = {
        bookedClientDetails: bookedClientsDetail,
        bookedProviderId: argsdata?.id,
        userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),

        serviceType: "Group Treatments",
        serviceLocation: serviceLocation,
        description: description,
        category: "Billable",

        fromTime: startTime,
        toTime: endTime,
        specificDate: bookingDate, //must pass it as local dtm!!!

        repeatService: repeatService,
        repeatWorkDays: selectedWeekdays,
        repeatCustomEnd: repeatService
          ? dayjs(customEndDate).format("ddd YYYY-MM-DD")
          : null,
        createdBy: user?.email,
      };
      const fromPlanning = false
      const data = await createGroupBooking(bookingData,fromPlanning);
      if (data) {
        dispatch(
          GetProviderById({
            providerId: bookingData.bookedProviderId,
          })
        );
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({ name: "message", value: data?.data?.message })
        );
      }
    } catch (e: any) {
      console.log("scheduling error:", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };
  useEffect(() => {
    if (orgBuUnits.length === 0) {
      dispatch(
        getOrgInfo({
          useLoader: false,
        })
      );
    }
  }, []);
console.log(activeStep,"activeStep");

  return (
    <Dialog open={open} className={"groupBooking largeModel"}>
      <Box className="largeModel__head">
        <Typography variant="h5" className="largeModel__title">
          Group Booking
        </Typography>
        <Button
          className="largeModel__closeBtn"
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <DialogContent>
        <Box className="largeModel__body">
          <Stepper
            activeStep={activeStep}
            sx={{ py: 3 }}
            alternativeLabel
            className="largeModel__stepperHead"
          >
            {groupBookingStages.map((stage, index) => (
              <Step key={index}>
                <StepLabel>{stage}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div style={{ padding: "0px 40px" }} className="groupBooking-step1">
            {activeStep === 0 ? (
              <Grid2 container spacing={4}>
                <Grid2 size={{ xs: 6 }}>
                  <InputLabel className="fromLabel">Employee</InputLabel>
                  <div className="userList-name m-15">
                    <img
                      src={
                        selectedProvider?.employmentDetails?.providerProfile
                          ?.url || ClientImg
                      }
                      className="userSmallCircle"
                      alt=""
                    />
                    <span style={{ marginLeft: 10 }}>
                      {selectedProvider?.employmentDetails?.firstName +
                        " " +
                        selectedProvider?.employmentDetails?.lastName}
                    </span>
                  </div>
                </Grid2>

                <Grid2 size={{ xs: 6 }}>
                  <InputLabel className="fromLabel">Group Service</InputLabel>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                      id="demo-multiple-name"
                      value={serviceData}
                      onChange={(e) => {
                        setServiceData(e.target.value);
                      }}
                      sx={{ height: "40px" }}
                      MenuProps={MenuProps}
                    >
                      {groupBookingServices.map(
                        (service: any, index: number) => (
                          <MenuItem key={index} value={service.value}>
                            <div className="optionsMenu">
                              <span>{`${service.label}`}</span>
                            </div>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                  <Box className="fromGroup">
                    <InputLabel className="fromLabel">Description</InputLabel>
                    <FormControl>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Description..."
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                          background: "#FFFFFF",
                          border: "1px solid #D9D9D9",
                          borderRadius: "2px",
                        }}
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid2>
              </Grid2>
            ) : activeStep === 1 ? (
              <Box className="fromGroup">
                <div className="check-AuthBlock">
                  {/* <div style={{ display: "flex" }}>
                    <input
                      type="checkbox"
                      // checked={repeatService === 2}
                      // onChange={() =>
                      //   repeatService === 2
                      //     ? setRepeatService(0)
                      //     : setRepeatService(2)
                      // }
                      style={{ width: "16px", height: "16px" }}
                    />
                    Load my clients
                  </div> */}
                </div>
                <Box className="innerModelSearch">
                  <Search
                    style={{
                      position: "absolute",
                      left: "7px",
                      top: "7px",
                      zIndex: 1,
                    }}
                  />
                  <TextField
                    id="input-with-sx"
                    label=""
                    placeholder="search client name"
                    variant="standard"
                    onChange={(e) => {
                      handleResult(e.target.value);
                    }}
                    onFocus={() => {
                      setDataList(Allclients);
                    }}
                  />
                </Box>
                <Box className="modelSearch-data">
                  {dataList.length > 0 && (
                    <>
                      {dataList.map((item) => {
                        return (
                          <Box
                            bgcolor={"#E6F4FF"}
                            boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.15)"}
                            borderRadius={"2px"}
                            mb={"2px"}
                            key={item.clientBasicDetails.id}
                            onClick={() =>
                              getSelectedClientList(item.clientBasicDetails.id)
                            }
                          >
                            <div style={{ display: "flex", gap: "8px" }}>
                              <img
                                src={
                                  item.clientBasicDetails?.clientProfile?.url ||
                                  ClientImg
                                }
                                className="userSmallCircle"
                                alt=""
                              />
                              {item.clientBasicDetails?.childFirstName}{" "}
                              {item.clientBasicDetails?.childLastName}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                color: "#096DD9",
                              }}
                            >
                              Add
                              <Add />
                            </div>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </Box>
                {hasError && !bookedClientsDetail.length && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select atleast one client.
                  </FormHelperText>
                )}

                <Box className="fromGroup" mt={4}>
                  <InputLabel
                    className="fromLabel"
                    style={{ color: "#096DD9" }}
                  >
                    Added Clients: <span>{selectedClientData.length}</span>
                  </InputLabel>
                  <TableContainer
                    component={Paper}
                    className="tableResponsive"
                    style={{ maxHeight: "160px", overflow: "auto" }}
                  >
                    <StyledTable
                      sx={{
                        borderCollapse: "separate",
                        cursor: "pointer",
                      }}
                      aria-label="customized table"
                    >
                      {selectedClientData.length > 0 ? (
                        <TableBody>
                          {selectedClientData.map((item) => {
                            let currentClient = bookedClientsDetail.find(
                              (client) =>
                                client.clientId === item.clientBasicDetails.id
                            );
                            return (
                              <StyledTableRow key={item.clientBasicDetails.id}>
                                <StyledTableCell>
                                  {item?.clientBasicDetails.childFirstName}{" "}
                                  {item?.clientBasicDetails.childLastName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Select
                                    // className="form-control"
                                    labelId="demo-simple-select-label"
                                    value={
                                      currentClient ? currentClient.authId : ""
                                    }
                                    name="Authorization"
                                    onChange={(e) => {
                                      const updatedClientDetail =
                                        bookedClientsDetail.map((client) => {
                                          if (
                                            client.clientId ===
                                            item.clientBasicDetails.id
                                          ) {
                                            return {
                                              ...client,
                                              authId: e.target.value,
                                            };
                                          }
                                          return client;
                                        });
                                      setbookedClientsDetail(
                                        updatedClientDetail
                                      );
                                    }}
                                    // renderValue={(selected: string) => selected}
                                    MenuProps={MenuProps}
                                    style={{
                                      width: "300px",
                                      height: "33px",
                                    }}
                                  >
                                    {item?.authorizations?.map(
                                      (
                                        item: ClientAuthPayload,
                                        index: number
                                      ) => {
                                        return (
                                          item.authPeriod &&
                                          item.auth.length > 0 && (
                                            <MenuItem
                                              key={index}
                                              value={item.id}
                                            >
                                              {returnAuthorizationValue(item)}
                                            </MenuItem>
                                          )
                                        );
                                      }
                                    )}
                                  </Select>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Select
                                    // className="form-control"
                                    labelId="demo-simple-select-label"
                                    value={
                                      currentClient
                                        ? currentClient.authCode
                                        : ""
                                    }
                                    onChange={(e) => {
                                      const updatedClientDetail =
                                        bookedClientsDetail.map((client) => {
                                          if (
                                            client.clientId ===
                                            item.clientBasicDetails.id
                                          ) {
                                            return {
                                              ...client,
                                              authCode: e.target.value,
                                            };
                                          }
                                          return client;
                                        });
                                      setbookedClientsDetail(
                                        updatedClientDetail
                                      );
                                    }}
                                    name="authCode"
                                    MenuProps={MenuProps}
                                    style={{
                                      width: "150px",
                                      height: "33px",
                                    }}
                                  >
                                    {item.authorizations?.map(
                                      (authorization) => (
                                        <MenuItem
                                          value={authorization.auth[0].authCode}
                                        >
                                          {authorization.auth[0].authCode}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </StyledTableCell>
                                <StyledTableCell
                                  onClick={() => {
                                    let result = selectedClientData.filter(
                                      (unit) =>
                                        unit.clientBasicDetails.id !==
                                        item.clientBasicDetails.id
                                    );
                                    setbookedClientsDetail(
                                      bookedClientsDetail.filter(
                                        (client) =>
                                          client.clientId !==
                                          item.clientBasicDetails.id
                                      )
                                    );
                                    setSelectedClientData(result);
                                  }}
                                >
                                  <Delete />
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={12}>
                              <div className="notFound-Block">
                                no clients added yet
                              </div>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      )}
                    </StyledTable>
                  </TableContainer>
                </Box>
              </Box>
            ) : (
              <Box>
                <Grid2 size={{ xs: 12 }} mb={2}>
                  <InputLabel className="fromLabel">
                    Service Location
                  </InputLabel>
                  <div className="fromGroup-chips">
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      MenuProps={MenuProps}
                      value={serviceLocation}
                      onChange={(e: SelectChangeEvent<string>) => {
                        setSeviceLocation(e.target.value);
                      }}
                    >
                      {orgBuUnits?.map((item, index: number) => {
                        return (
                          <MenuItem
                            value={item.businessUnitAddress}
                            key={index}
                          >
                            <b>Clinic</b> {item.businessUnitAddress}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </Grid2>

                <Grid2 size={{ xs: 12 }} mb={2}>
                  <Box className="fromGroup">
                    <Grid2 container alignItems="center">
                      <Grid2 size={{ xs: 12, lg: 6 }}>
                        <InputLabel className="fromLabel">Book Time</InputLabel>
                      </Grid2>
                    </Grid2>

                    <div className="time-group">
                      <Box className="time-group-select">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          MenuProps={{
                            PaperProps: {
                              style: { maxHeight: 200, width: 100 },
                            },
                          }}
                          value={startTime}
                          onChange={(e: any) => {
                            const toTimeIndex =
                              getIndexFromTimeValidation(e.target.value) + 4;
                            if (
                              toTimeIndex <
                              AvailabilityTimesForSchedulesBars.length
                            ) {
                              setStartTime(
                                AvailabilityTimesForSchedulesBars[toTimeIndex]
                              );
                            } else {
                              setStartTime(
                                AvailabilityTimesForSchedulesBars[
                                  AvailabilityTimesForSchedulesBars.length - 1
                                ]
                              );
                            }
                          }}
                        >
                          {getAvailabilityTimes(0)}
                        </Select>
                      </Box>
                      <span className="time-group__space">-</span>
                      <Box className="time-group-select">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          MenuProps={{
                            PaperProps: {
                              style: { maxHeight: 200, width: 100 },
                            },
                          }}
                          value={endTime}
                          onChange={(e: any) => setEndTime(e.target.value)}
                        >
                          {getAvailabilityTimes(
                            getIndexFromTimeValidation(startTime) + 1
                          )}
                        </Select>
                      </Box>
                    </div>
                  </Box>
                </Grid2>

                <Grid2 container size={{ xs: 12 }}>
                  <Grid2 size={{ xs: 6 }}>
                    <InputLabel className="fromLabel">Begin Date</InputLabel>
                    <p className="detailsCard-part__text">
                      {moment(argsdata?.StartTime).format("MM/DD/YYYY")}
                    </p>
                  </Grid2>

                  <Grid2 size={{ xs: 6 }}>
                    <InputLabel className="fromLabel">Ends On</InputLabel>
                    <div className="check-AuthBlock">
                      <div style={{ display: "flex" }}>
                        <input
                          // disabled={category === "Non Billable"}
                          type="checkbox"
                          checked={repeatService === 2}
                          onChange={() =>
                            repeatService === 2
                              ? setRepeatService(0)
                              : setRepeatService(2)
                          }
                          style={{ width: "16px", height: "16px" }}
                        />
                        End of Authorization
                      </div>
                    </div>
                    <div className="check-AuthBlock">
                      <div>
                        <input
                          type="checkbox"
                          checked={repeatService === 3 || repeatService === 1} //handle legacy repeatService=EndOfMonth
                          onChange={() =>
                            repeatService === 3 || repeatService === 1
                              ? setRepeatService(0)
                              : setRepeatService(3)
                          }
                          style={{ width: "16px", height: "16px" }}
                        />
                        End Date
                      </div>
                      <div className="datepickerBlock">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Controlled picker"
                            className="singleDatePicker"
                            value={customEndDate}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Grid2>

                  <Grid2 size={{ xs: 12 }} mb={2}>
                    <InputLabel className="fromLabel">Weekdays</InputLabel>
                    <Box className="weekdays-selection">
                      {weekdays.map((day, index) => (
                        <span
                          key={index}
                          className={`weekday-item ${
                            selectedWeekdays?.indexOf(WeekDaysFullName[index]) >
                            -1
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => toggleWeekday(index)}
                        >
                          {day}
                        </span>
                      ))}
                    </Box>
                  </Grid2>

                  <Box className="info-section" mb={2}>
                    <Box className="info-item">
                      <img src={ALert} alt="" />
                      <div>
                        <Typography variant="subtitle1" className="info-title">
                          Weekday Suggestion
                        </Typography>
                        <Typography variant="body2" className="info-details">
                          Most available weekdays:{" "}
                          {weekdaySuggestion
                            .map((item) => `${item.day} (${item.availability})`)
                            .join(", ")}
                        </Typography>
                      </div>
                    </Box>

                    <Box className="info-item">
                      <img src={ALert} alt="" />
                      <div>
                        <Typography variant="subtitle1" className="info-title">
                          End Date Suggestion
                        </Typography>
                        <Typography variant="body2" className="info-details">
                          Most eligible "End Date" for group session will be:{" "}
                          {endDateSuggestion}
                        </Typography>
                      </div>
                    </Box>

                    <Box className="info-item">
                      <img src={ALert} alt="" />
                      <Typography
                        variant="subtitle1"
                        className="info-title"
                        color="primary"
                      >
                        Go Pro Mode
                      </Typography>
                    </Box>
                  </Box>
                </Grid2>
              </Box>
            )}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className="largeModel__footer">
          <div>
            {activeStep !== 0 && (
              <Button
                variant="outlined"
                onClick={() => setActiveSteps(activeStep - 1)}
              >
                Previous
              </Button>
            )}
          </div>

          <Button
            variant="contained"
            onClick={() => {
              if (activeStep !== 2) {
                if (activeStep === 1 && !bookedClientsDetail.length) {
                  setHasError(true);
                  return;
                }
                setActiveSteps(activeStep + 1);
              } else {
                onSubmitGroupBooking();
              }
            }}
          >
            {activeStep !== 2 ? "Next" : "Add Booking"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default GroupBookingModal;
