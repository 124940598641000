import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, batch } from "react-redux";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Inject,
  ResourcesDirective,
  ResourceDirective,
  // ActionEventArgs,
  PopupOpenEventArgs,
  Resize,
  DragAndDrop,
  Day,
  Week,
  Month,
  Year,
  // TimelineMonth,
  Agenda,
  Schedule,
  NavigatingEventArgs,
  RenderCellEventArgs,
  // CellClickEventArgs,
  // EventClickArgs,
  // ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import { Internationalization, createElement } from "@syncfusion/ej2-base";
// import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Box, Switch, Typography } from "@mui/material";
import { RootState, useAppSelector } from "../../../../redux/store";
import { BookedSchedule } from "../../../../types/schedule";
import { ClientForms } from "../../../../types/client";
import { ProviderForms } from "../../../../types/provider";
// import { AvailabilityDetails } from "../../../../types/availability";
// import { ServiceColor } from "../../../../types/schedule";
// import { sortDataArray } from "../../../../utils/AvailabilityUtils";
import {
  populateBookedData,
  // populateUnavailableData,
  IBookingService,
  getResourceName,
  getResourceImage,
  getColor,
  handleDataBinding,
  handleRenderCell,
  // handleActionBegin,
  handlePopupOpen,
  onEventRendered,
  handlePopulateResources,
} from "../../../../utils/CalendarUtils";
import DiscoverBookingEditModal from "../../../schedules-main/booking-scheduler/DiscoverBookingEditModal";
import { filterSchedulesByIds } from "../../../../utils/ScheduleUtils";
import RebookingModal from "./RebookingModal";

//Provider side Calendar
const SubSchedulerNew: React.FC<IBookingService> = ({
  selectedClients,
  selectedProviders,
  isDataEdited,
  setIsDataEdited,
  providerList,
  clientList,
}) => {
  //#region variable region

  //for loader component
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //
  var islayoutChanged = true;

  //argdata store
  const [argsdata, setargsData] = useState<Record<string, any>>();

  // weekdays mentions here
  // const workingDays = [0, 1, 2, 3, 4, 5, 6];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<Record<string, any>[]>([]);

  //new and edit popup handler
  const [popup, setPopup] = useState<boolean>(false);

  //delete popup handler
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);

  //decide new and edit true value
  const [isNewPopup, setIsNewPopUp] = useState<boolean>(false);

  //for popup dropdown selection
  const [client, setClient] = useState<ClientForms>();
  const [provider, setProvider] = useState<ProviderForms>();

  //stores list data
  const Bookeddata = useSelector<RootState, BookedSchedule[]>(
    (state) => state.scheduleListSlice.bookedListData
  );
  //***********populate data *******************/
  // selected provider data
  const [listForCalendar, setListForCalendar] = useState<any[]>([]);

  // Unavailable Block Data
  // const [clientBlocks, setClientBlocks] = useState<any[]>([]);
  // const [providerBlocks, setProviderBlocks] = useState<any[]>([]);

  // Booked Block Data
  const [clientBooked, setClientBooked] = useState<any[]>([]);
  const [providerBooked, setProviderBooked] = useState<any[]>([]);

  //handles showWeekend
  const [weekController, setWeekController] = useState(true);

  //handle Rebooking
  const [isRebooking, setIsRebooking] = useState<boolean>(false);

  //#endregion
  //#region method region

  //--------------------------------------------------------------
  //Customize calendar resource headers
  function resourceHeaderTemplate(props: any): JSX.Element {
    return (
      <div className="template-wrap">
        <div
          className="person-name meetingPersonBox"
          style={{
            background: getColor(props),
          }}
        >
          <img src={getResourceImage(props)} height={30} width={30} />
          <span>{getResourceName(props)}</span>
        </div>
      </div>
    );
  }
  const headerTooltipTemplate = (props: any): string => {
    return `<div className="template-wrap">
      <div className="room-name">${getResourceName(props)}</div>
      </div>`;
  };

  //----------------------------------------------------------------
  //used for access features
  let schedule: any = new Schedule({
    height: "600px",
    width: "100%",
    selectedDate: new Date(),
    firstDayOfWeek: 3,
    // showWeekend: false,
    currentView: "TimelineDay",
    navigating: (args: NavigatingEventArgs) => {
      if (args?.currentView !== "TimelineMonth") {
        scheduleObj.current.headerRows = [];
      }
    },
  });

  //ref for scheduleObj
  let scheduleObj = useRef<ScheduleComponent>(schedule);

  //----------------------------------------------------------------
  //Scheduler Actions
  const onDataBinding = useCallback(() => {
    handleDataBinding(
      islayoutChanged,
      scheduleObj,
      selectedClients,
      selectedProviders
    );
  }, [islayoutChanged, selectedClients, selectedProviders]);

  //render on every action
  // function onActionBegin(args: ActionEventArgs): void {
  //   handleActionBegin(args, scheduleObj);
  // // console.log("calendar onActionBegin");
  // if (args.data !== undefined) {
  //   if (
  //     args.requestType === "eventCreate" ||
  //     args.requestType === "eventChange"
  //   ) {
  //     let data: Record<string, any> | undefined =
  //       args.data instanceof Array ? args.data[0] : args.data;
  //     args.cancel = !scheduleObj.isSlotAvailable(data);
  //   }
  // }
  // }

  //render every cell
  const onRenderCell = useCallback((args: RenderCellEventArgs) => {
    handleRenderCell(args);
  }, []);

  // const onCellClick = (args: CellClickEventArgs): void => {
  //   scheduleObj.current.openEditor(args, 'Add');
  // }
  // const onEventClick = (args: EventClickArgs): void => {
  //   if (!(args.event as any).RecurrenceRule) {
  //     scheduleObj.current.openEditor(args.event, 'Save');
  //   }
  //   else {
  //     scheduleObj.current.quickPopup.openRecurrenceAlert();
  //   }
  // }

  //function for popup handler
  // function onPopupOpen(args: PopupOpenEventArgs): void {
  //   handlePopupOpen(
  //     args,
  //     setargsData,
  //     setPopup,
  //     setIsDeletePopup,
  //     setIsNewPopUp,
  //     setIsRebooking
  //   );

  //   console.log("args, argsData", args, argsdata);
  // }

  const onPopupOpen = useCallback(
    (args: PopupOpenEventArgs) => {
      let data: Record<string, any> = args.data as Record<string, any>;
      setargsData(data);
      // console.log("args, argsData", args, argsdata);

      let checkClientList = clientList ? clientList : selectedClients;
      let foundClient = checkClientList.find((item: ClientForms) => {
        return item.clientBasicDetails?.id === data?.id;
      });
      if (foundClient) {
        setClient(foundClient);
      }

      let checkProviderList = providerList ? providerList : selectedProviders;
      let foundProvider = checkProviderList.find((item: ProviderForms) => {
        return item.employmentDetails?.id === data?.id;
      });
      if (foundProvider) {
        setProvider(foundProvider);
      }
      const isGroupBooking = foundClient?.clientBasicDetails.id === args.data?.id;

      // handlePopupOpen(
      //   args,
      //   setargsData,
      //   setPopup,
      //   setIsDeletePopup,
      //   setIsNewPopUp,
      //   setIsRebooking
      // );
      handlePopupOpen(
        args,
        setargsData,
        setPopup,
        setIsDeletePopup,
        setIsNewPopUp,
        setIsRebooking, //rebooking
        scheduleObj,
        undefined
      );
    },
    [argsdata]
  );

  // let data: Record<string, any> = args.data as Record<string, any>;
  // setargsData(data);

  // let foundClient = selectedClients?.find((item: ClientForms) => {
  //   return item.clientBasicDetails?.id === data?.id;
  // });
  // if (foundClient) {
  //   setClient(foundClient);
  // }

  // let foundProvider = selectedProviders.find((item: ProviderForms) => {
  //   return item.employmentDetails?.id === data?.id;
  // });
  // if (foundProvider) {
  //   setProvider(foundProvider);
  // }

  // if (args.data?.Id) {
  //   setIsNewPopUp(false); //edit
  // } else {
  //   setIsNewPopUp(true); // new
  // }

  // if (args.type === "Editor") {
  //   args.cancel = true;
  //   setPopup(true); //new

  //   // Create required custom elements in initial time
  //   if (!args.element.querySelector(".custom-field-row")) {
  //     let row: HTMLElement = createElement("div", {
  //       className: "custom-field-row",
  //     });
  //     let formElement: any = args.element.querySelector(".e-schedule-form");
  //     formElement?.firstChild.insertBefore(
  //       row,
  //       args.element.querySelector(".e-title-location-row")
  //     );
  //     let container: HTMLElement = createElement("div", {
  //       className: "custom-field-container",
  //     });
  //     let inputEle: HTMLInputElement = createElement("input", {
  //       className: "e-field",
  //       attrs: { name: "ServiceType" },
  //     }) as HTMLInputElement;
  //     container.appendChild(inputEle);
  //     row.appendChild(container);
  //     let dropDownList: DropDownList = new DropDownList({
  //       dataSource: [
  //         { text: "Assessment", value: "assessment" },
  //         { text: "Direct Therapy", value: "direct-therapy" },
  //         { text: "Parent Training", value: "parent-training" },
  //         { text: "Protocol modification", value: "protocol-modification" },
  //       ],
  //       fields: { text: "text", value: "value" },
  //       // value: (<{ [key: string]: Object }>(args.data)).EventType as string,
  //       floatLabelType: "Always",
  //       placeholder: "Service Type",
  //     });
  //     dropDownList.appendTo(inputEle);
  //     inputEle.setAttribute("name", "ServiceType");
  //   }
  // }

  // if (args.type === "DeleteAlert") {
  //   setIsDeletePopup(true);
  //   args.cancel = true;
  // }
  // }

  let instance = new Internationalization();
  const getDateHeaderText = (value: any) => {
    return instance.formatDate(value, { skeleton: "Ed" });
  };
  const dateHeaderTemplate = (props: any) => {
    return (
      <div>
        <div>{getDateHeaderText(props.date)}</div>
      </div>
    );
  };
  // console.log(selectedClients[0].bookedServices.length);

  //scheduler Component Code
  // const Scheduler = (data: Record<string, any>[]) => {
  // return (
  const Scheduler = React.useMemo(
    () => (
      <ScheduleComponent
        cssClass="block-events"
        // ref={(schedule) => (scheduleObj = schedule)}
        ref={scheduleObj}
        width="100%"
        height="650px"
        rowAutoHeight={true}
        // workDays={weekController ? workingDays : [1, 2, 3, 4, 5]}
        showWeekend={weekController}
        // workHours={{ highlight: true, start: "06:00", end: "21:00" }}
        // timeScale={{ interval: 60, slotCount: 2 }}
        currentView="Month"
        resourceHeaderTemplate={resourceHeaderTemplate.bind(this)}
        eventSettings={{
          dataSource: [...clientBooked, ...providerBooked], //data,
          fields: {
            id: "Id",
            subject: { title: "Summary", name: "Subject" },
            isAllDay: { name: "IsAllDay" },
            location: { title: "Location", name: "Location" },
            endTimezone: { name: "TimeZone" },
            startTimezone: { name: "TimeZone" },
            description: { title: "Description", name: "Description" },
            startTime: { title: "From", name: "StartTime" },
            endTime: { title: "To", name: "EndTime" },

            // recurrenceRule: { name: "Recurences" },
            // recurrenceException: { name: "RecurencesException" },
          },
        }}
        // quickInfoTemplates={{ header: header, content: content, footer: footer }}
        // showQuickInfo={false}
        // eventClick={onEventClick.bind(this)}
        // cellClick={onCellClick.bind(this)}
        eventRendered={onEventRendered.bind(this)}
        popupOpen={onPopupOpen.bind(this)}
        renderCell={onRenderCell.bind(this)}
        dataBinding={onDataBinding.bind(this)}
        group={{
          // enableCompactView: false,
          resources: ["CalendarList"],
          // headerTooltipTemplate: headerTooltipTemplate.bind(this), //CTO Review: didn't work? why
        }}
        dateHeaderTemplate={dateHeaderTemplate}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="id"
            title={selectedClients?.length > 0 ? "Client" : "Provider"}
            name="CalendarList"
            allowMultiple={true}
            dataSource={listForCalendar}
            textField="name"
            idField="id"
            groupIDField="GroupId"
            colorField="color"
            workDaysField="workDays"
            startHourField="start"
            endHourField="end"
          ></ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective
            option="TimelineDay"
            startHour="06:00"
            endHour="21:00"
          />
          {/* <ViewDirective
            option="TimelineWeek"
            startHour="06:00" endHour="21:00"
          />
          <ViewDirective
            option="TimelineMonth"
            // startHour="06:00" endHour="21:00"
          /> */}
          <ViewDirective option="Day" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Week" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Month" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Year" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Agenda" startHour="06:00" endHour="21:00" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            Month,
            Year,
            TimelineViews, //day & week
            // TimelineMonth,
            Resize,
            DragAndDrop,
            Agenda,
          ]}
        />
      </ScheduleComponent>
    ),
    [data]
  );
  //[data, onEventRendered, onPopupOpen, onRenderCell, onDataBinding, listForCalendar, selectedClients, selectedProviders, weekController]
  // };

  //#endregion

  const filterSchedulesByTimeline = useSelector((state: RootState) => {
    const today = new Date();
    let clientIds: any[] = [];
    let providerIds: any[] = [];

    if (selectedClients) {
      clientIds = selectedClients.map(
        (client: any) => client.clientBasicDetails?.id
      );
    }
    if (selectedProviders) {
      providerIds = selectedProviders.map(
        (provider: any) => provider.employmentDetails?.id
      );
    }

    return filterSchedulesByIds(
      Bookeddata,
      clientIds,
      providerIds,
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );
  });

  // The rows we need to show in the calendar
  const populateCalendarListData = () => {
    // let newList: any[] = [];
    // let obj: any;

    // //Add Clients Into Calendar List
    // for (let i = 0; i < selectedClients?.length; i++) {
    //   obj = {
    //     name: `${selectedClients[i]?.clientBasicDetails?.childFirstName}${" "}${
    //       selectedClients[i]?.clientBasicDetails?.childLastName
    //     }`,
    //     id: selectedClients[i]?.clientBasicDetails?.id,
    //     GroupId: 1, //selected client category
    //     color: "#865fcf",
    //     url: selectedClients[i]?.clientBasicDetails?.clientProfile?.url,
    //     type: "Client",
    //   };
    //   newList.push(obj);
    // }

    // //Add Providers Into Calendar List
    // for (let i = 0; i < selectedProviders?.length; i++) {
    //   obj = {
    //     name: `${selectedProviders[i]?.employmentDetails?.firstName} ${selectedProviders[i]?.employmentDetails?.lastName}`,
    //     id: selectedProviders[i]?.employmentDetails?.id,
    //     GroupId: 2, //selected provider category
    //     color: "#865fcf",
    //     url: selectedProviders[i]?.employmentDetails?.providerProfile?.url,
    //     type: "Provider",
    //   };
    //   newList.push(obj);
    // }
    const uniqueValue = handlePopulateResources(
      selectedClients,
      selectedProviders
    );
    setListForCalendar(uniqueValue);
  };

  /*
  const populateProviderBlockData = () => {
    let providerResults: any[] = [];

    let sorted: any;
    for (let provider of selectedProviders) {
      sorted = sortDataArray<AvailabilityDetails>(provider.availabilityDetails);
      for (let availabilityDetail of sorted) {
        populateUnavailableData(
          2, //groupId
          provider?.employmentDetails?.id,
          availabilityDetail.timeSlots,
          providerResults
        );
      }
    }

    flushSync(() => {
      setProviderBlocks(providerResults);
    });
  };

  const populateClientBlockData = () => {
    let clientResults: any[] = [];

    for (let client of selectedClients) {
      let sorted = sortDataArray<AvailabilityDetails>(
        client.availabilityDetails
      );

      sorted.forEach((availabilityDetail: any) => {
        populateUnavailableData(
          1, //groupId
          client?.clientBasicDetails?.id,
          availabilityDetail.timeSlots,
          clientResults
        );
      });
    }

    setClientBlocks(clientResults);
  };
  */

  // function populateProviderBookedData() {
  const populateProviderBookedData = () => {
    let providerResults: any[] = [];

    for (let i = 0; i < selectedProviders?.length; i++) {
      populateBookedData(
        "PROVIDER",
        selectedProviders[i]?.bookedServices,
        //filteredProviderSchedules,
        providerResults
      );
    }

    setProviderBooked(providerResults);
  };

  //render Booking and selected provider
  const populateClientBookedData = () => {
    let clientResults: any[] = [];

    for (let i = 0; i < selectedClients?.length; i++) {
      populateBookedData(
        "CLIENT",
        selectedClients[i]?.bookedServices,
        clientResults
      );
    }

    setClientBooked(clientResults);
  };

  //render Booking and selected provider
  useEffect(() => {
    //only after client has been populated successfully.
    //handle intital load or refresh to load

    if (
      (selectedClients !== undefined || selectedProviders !== undefined) &&
      (isLoading === true || isDataEdited === true)
    ) {
      // console.log(
      //   "begin reloading...",
      //   selectedClients,
      //   selectedProviders,
      //   isLoading,
      //   isDataEdited
      // );
      //setListForCalendar([]);
      setTimeout(() => {
        batch(() => {
          setData([]);
          setClientBooked([]);
          setProviderBooked([]);
          // setClientBlocks([]);
          // setProviderBlocks([]);

          populateCalendarListData();
          populateClientBookedData();
          populateProviderBookedData();
          // populateClientBlockData();
          // populateProviderBlockData();

          setIsLoading(false);
          setIsDataEdited(false);

          if (selectedClients?.length > 0) {
            setClient(selectedClients[0]);
          }
          if (selectedProviders?.length > 0) {
            setProvider(selectedProviders[0]);
          }
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //only inputs
    isDataEdited, //for availabilityChange
    selectedClients,
    selectedProviders,
    // Bookeddata,
    listForCalendar?.length,
    filterSchedulesByTimeline?.length,
    providerBooked,
    clientBooked,
    // clientBlocks,
    // providerBlocks,
  ]);

  // #endregion
  // console.log("booked", clientBooked, providerBooked);
  // console.log("data", client, provider);
  // console.log("client dropdown", clientList, selectedClients);
  // console.log("provider dropdown", providerList, selectedProviders);

  return (
    <>
      {/* <div style={{ height: "100px", backgroundColor: "black" }}></div> */}
      <div className="schedule-control-section">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <Typography sx={{ padding: "0 10px", fontWeight: "bold" }}>
            Show Weekend
          </Typography>
          <Switch
            sx={{
              "--Switch-thumbSize": "28px",
            }}
            checked={weekController}
            onChange={() => setWeekController(!weekController)}
          />
        </Box>
        {listForCalendar.length > 0 && (
          <div className="col-lg-12 schedule-control-section">
            <div className="control-wrapper">
              {
                // Scheduler([
                //   // ...clientBlocks,
                //   // ...providerBlocks,
                //   ...clientBooked,
                //   ...providerBooked,
                // ])
                Scheduler
              }
            </div>
          </div>
        )}

        {/* Add Booking & Edit Booking */}
        {popup && (
          <DiscoverBookingEditModal
            pageTitle={isNewPopup ? "Add Booking" : "Update Booking"}
            clientData={client}
            provider={provider}
            isOpen={popup}
            isEdit={isNewPopup ? false : true}
            isNew={isNewPopup ? true : false}
            closePopup={setPopup}
            argsdata={argsdata}
            // Bookeddata={Bookeddata}
            isDataEdited={isDataEdited}
            setIsDataEdited={setIsDataEdited}
            allSelectedClients={
              clientList !== undefined ? clientList : selectedClients
            }
            providersData={
              providerList !== undefined ? providerList : selectedProviders
            }
            fromPlanning={false}
            isAutoPlanned={false}
          />
        )}
        {isDeletePopup && (
          <>
            <DiscoverBookingEditModal
              pageTitle={"Cancel Booking"}
              clientData={client}
              provider={provider}
              isOpen={popup}
              isEdit={false}
              isNew={false}
              isDelete={true}
              closePopup={setIsDeletePopup}
              argsdata={argsdata}
              // Bookeddata={Bookeddata}
              isDataEdited={isDataEdited}
              setIsDataEdited={setIsDataEdited}
              allSelectedClients={selectedClients}
              providersData={selectedProviders}
              fromPlanning={false}
              isAutoPlanned={false}
            />
          </>
        )}

        {isRebooking && (
          <RebookingModal
            isOpen={isRebooking}
            setIsOpen={setIsRebooking}
            entityType={selectedClients.length > 0 ? "client" : "provider"}
            argsData={argsdata}
            setIsDataEdited={setIsDataEdited}
          />
        )}
      </div>
    </>
  );
};

export default SubSchedulerNew;
