import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ClientInitialState } from "../../../constants/client";
import { ReverseList } from "../../../helpers/reverse-list/reverse-list";
import ClientService from "../../../service/client.service";
import CommonService from "../../../service/common.service";
import { ClientForms, ListData } from "../../../types/client";
import { setResponseValue } from "../api-response/api-response";
import {
  setClientData,
  setClientFilesFromResponse,
  setClientMessages,
} from "./client-slice";
import { ProviderForms } from "../../../types/provider";
import MessageService from "src/service/message.service";

const initialState: ListData = {
  singleClient: { ...ClientInitialState },
  listDataChange: false,
  listData: [],
  recentlyAdded: [],
  clientFilesChange: false,
  clientMessagesChange: false,
  dataUpdate: false,
  loader: false,
  clientConcerns: [],
  inactiveListData: [],
  utilizationListData: {
    Clients: [],
    Provider: [],
  },
};

const ClientListSlice = createSlice({
  name: "clientList",
  initialState: initialState,
  reducers: {
    dataUpdated: (state, action) => {
      state.dataUpdate = action.payload.dataUpdate;
    },
    setListDataChange: (state) => {
      return {
        ...state,
        listDataChange: !state.listDataChange,
      };
    },
    setListData: (state) => {
      return {
        ...state,
        listData: [],
      };
    },
    setRecentlyAdded: (state) => {
      return {
        ...state,
        recentlyAdded: [],
      };
    },
    setSingleClientData: (state, action) => {
      return {
        ...state,
        singleClient: {
          ...action.payload.data,
        },
      };
    },
    setClientFilesDataChange: (state) => {
      return {
        ...state,
        clientFilesChange: !state.clientFilesChange,
      };
    },
    setClientMessagesChange: (state) => {
      return {
        ...state,
        clientMessagesChange: !state.clientMessagesChange,
      };
    },
    setLoader: (state) => {
      return {
        ...state,
        loader: !state.loader,
      };
    },
    clearSingleData: (state) => {
      return {
        ...state,
        singleClient: ClientInitialState,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(GetAllClientData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          listData: action.payload,
        };
      }
      return state;
    });
    builder.addCase(GetClientById.fulfilled, (state, action) => {
      return {
        ...state,
        singleClient: {
          ...action.payload,
        },
      };
    });
    builder.addCase(GetInactiveClients.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const reversedList = ReverseList<ClientForms>(action.payload);

        const list = reversedList.sort((a: ClientForms, b: ClientForms) => {
          return (
            (new Date(b?.clientBasicDetails?.createdAt) as any) -
            (new Date(a.clientBasicDetails?.createdAt) as any)
          );
        });

        return {
          ...state,
          inactiveListData: list,
        };
      }
      return state;
    });

    builder.addCase(GetUtilzationClients.fulfilled, (state, action) => {
      return {
        ...state,
        utilizationListData: {
          Clients: action.payload?.clients,
          Provider: action.payload?.provider,
        },
      };
    });

    // builder.addCase(GetAllClientConcerns.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     clientConcerns: action.payload,
    //   };
    // });
  },
});

export const GetAllClientData = createAsyncThunk(
  "clientList/GetAllClients",
  async (_, { dispatch }) => {
    dispatch(setLoader());
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ClientService.getAllClient();
      dispatch(setLoader());
      if (data.status) {
        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetClientById = createAsyncThunk(
  "clientList/GetClientById",
  async ({ clientId }: { clientId: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ClientService.getClientById(clientId);

      if (data.status) {
        const newData: ClientForms = {
          isEditClient: false,
          clientBasicDetails: {
            ...data.data.clientBasicDetails,
            files: data?.data?.clientBasicDetails?.files || [],
          },
          clientContactDetails: data.data.clientContactDetails
            ? { ...data.data.clientContactDetails }
            : { ...ClientInitialState.clientContactDetails },
          // availabilityDetails: data.data.availabilityDetails
          //   ? { ...data.data.availabilityDetails }
          //   : { ...ClientInitialState.availabilityDetails },
          availabilityDetails:
            data?.data?.availabilityDetails?.length > 0
              ? data.data.availabilityDetails
              : ClientInitialState.availabilityDetails,
          clientInsurance:
            data.data.clientInsurance?.length > 0
              ? data.data.clientInsurance
              : ClientInitialState.clientInsurance,
          authorizations:
            data.data.authorizations?.length > 0
              ? data.data.authorizations
              : ClientInitialState.authorizations,
          clientFiles: [],
          clientMessages: [],
          bookedServices:
            data.data.bookedServices?.length > 0
              ? data.data.bookedServices
              : [],
          schedules: [],
          clientUtilization:
            data.data.clientUtilization?.length > 0
              ? data.data.clientUtilization
              : ClientInitialState.clientUtilization,
          clientAuthUtilization:
            data.data.clientAuthUtilization?.length > 0
              ? data.data.clientAuthUtilization
              : ClientInitialState.clientAuthUtilization,
        };
        dispatch(setClientData(newData));
        // dispatch(dataUpdated({ dataUpdate: true }));

        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getClientFilesById = createAsyncThunk(
  "clientListSlice/getClientFilesById",
  async ({ clientId }: { clientId: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await CommonService.getFilesByEntityId(clientId);
      if (data.status) {
        dispatch(setClientFilesFromResponse(data.data));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getClientMessagesById = createAsyncThunk(
  "clientListSlice/getClientMessagesById",
  async ({ clientId }: { clientId: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, data } = await ClientService.getClientMessagesById(
        clientId
      );
      if (status) {
        dispatch(setClientMessages(data));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const postClientMessageById = createAsyncThunk(
  "clientListSlice/postClientMessageByID",
  async (
    {
      refetch,
      message,
      clientId,
    }: { refetch?: boolean; message: string; clientId: string },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message: responseMessage } =
        await ClientService.postClientMessageById(message, clientId);
      if (status) {
        if (refetch) {
          dispatch(getClientMessagesById({ clientId }));
        } else {
          dispatch(setClientMessagesChange());
        }
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const EditClientMessageById = createAsyncThunk(
  "clientListSlice/editClientMessageById",
  async (
    {
      clientId,
      selectedMessage,
      commentId,//message Id
    }: { clientId?: string; selectedMessage: string; commentId: string },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));

      // const { status, message: responseMessage } =
      //   await ClientService.updateClientMessageById(selectedMessage, commentId);
      
      //update message
      const { status, message: responseMessage } =
        await MessageService.updateMessage(commentId, selectedMessage);
        
      if (status) {
        if (clientId) {
          dispatch(getClientMessagesById({ clientId }));
        } else {
          dispatch(setClientMessagesChange());
        }
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteClientMessageById = createAsyncThunk(
  "clientListSlice/deleteClientMessageById",
  async (
    { clientId, commentId }: { clientId?: string; commentId: string },
    { getState, dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message: responseMessage } =
        await ClientService.deleteClientMessageById(commentId);
      if (status) {
        if (clientId) {
          dispatch(getClientMessagesById({ clientId }));
        } else {
          dispatch(setClientMessagesChange());
        }
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get in active providers
export const GetInactiveClients = createAsyncThunk(
  "/GetInactiveClients",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ClientService.getInactiveClients();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Client Concern Stages
// export const GetAllClientConcerns = createAsyncThunk(
//   "clientList/GetAllClientConcerns",
//   async (_, { dispatch }) => {
//     dispatch(
//       setResponseValue({
//         name: "pending",
//         value: true,
//       })
//     );
//     try {
//       const { data } = await DataCatalogService.GetAllConcernedStage();
//       return data;
//     } catch (e: any) {
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     } finally {
//       dispatch(
//         setResponseValue({
//           name: "pending",
//           value: false,
//         })
//       );
//     }
//   }
// );

export const GetUtilzationClients = createAsyncThunk(
  "/getUtilzationClientsData",
  async (
    {
      startDate,
      endDate,
      isClient,
    }: {
      startDate: string | null;
      endDate: string | null;
      isClient: boolean | null;
    },
    { dispatch, getState }
  ) => {
    let { clientListSlice, providerListSlice } = getState() as {
      clientListSlice: { listData: ClientForms[]; dataUpdate: boolean };
      providerListSlice: { listData: ProviderForms[]; dataUpdate: boolean };
    };

    const payloadData = {
      startDate,
      endDate,
      clientIds:
        isClient || isClient === null
          ? clientListSlice.listData.map(
              (client) => client.clientBasicDetails.id
            )
          : [],
      providerIds:
        isClient === null
          ? providerListSlice.listData.map(
              (provider) => provider.employmentDetails.id
            )
          : [],
    };
    // const payloadData = {
    //   startDate,
    //   endDate,
    //   clientIds: [],
    //   providerId: [],
    // };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const data = await CommonService.postFilteredUtilzation(payloadData);
      dispatch(dataUpdated({ dataUpdate: true }));
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setSingleClientData,
  setListDataChange,
  setListData,
  setRecentlyAdded,
  setClientFilesDataChange,
  setClientMessagesChange,
  dataUpdated,
  setLoader,
  clearSingleData,
} = ClientListSlice.actions;

export default ClientListSlice;
