import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import insuranceProviderService from "../../../service/insuranceProvider.service";

interface IInsuranceProvider {
  id: string;
  insuranceProviderName: string;
}

const initialState: IInsuranceProvider[] = [];

const insuranceProviderSlice = createSlice({
  name: "insuranceProviderSlice",
  initialState: {
    insuranceProviders: initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInsuranceProviders.fulfilled, (state, action) => {
      if (action.payload) {
        state.insuranceProviders = action.payload;
      }
    });
  },
});

export const getInsuranceProviders = createAsyncThunk(
  "insuranceProviderSlice/getInsuranceProviders",
  async () => {
    try {
      const { data } = await insuranceProviderService.getInsuranceProviders();
      // console.log(data);
      return data;
    } catch (error) {
      return null;
    }
  }
);

export default insuranceProviderSlice;
