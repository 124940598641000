import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Inject,
  ResourcesDirective,
  ResourceDirective,
  RenderCellEventArgs,
  PopupOpenEventArgs,
  Resize,
  DragAndDrop,
  Day,
  Week,
  Month,
  Year,
  // TimelineMonth,
  Agenda,
  Schedule,
  // NavigatingEventArgs,
} from "@syncfusion/ej2-react-schedule";
import { Internationalization } from "@syncfusion/ej2-base";
import {
  BookedSchedule,
  ICalenderlist,
  MatchedProvider,
  MatchOption,
} from "../../../types/schedule";
import { ClientForms } from "../../../types/client";
import moment from "moment";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector, batch, useDispatch } from "react-redux";
import { Box, Card, Switch, Typography } from "@mui/material";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { getAllBookingsData } from "../../../redux/features/schedules-main/schedule-list-slice";
import "../book-services/bookServices.scss";
import { InsuranceProvider } from "../../../types/response";
import {
  getResourceName,
  getResourceImage,
  getColor,
  populateBookedData,
  onEventRendered,
  handleDataBinding,
  handleRenderCell,
  handlePopupOpen,
  handlePopulateResources,
} from "../../../utils/CalendarUtils";
import { setRefreshValue } from "../../../redux/features/schedules-main/match-slice";
import {
  GetClientById,
  setSingleClientData,
} from "../../../redux/features/client-main/client-list-slice";
import Loader from "../../../layouts/loader/Loader";
import DiscoverBookingEditModal from "./DiscoverBookingEditModal";
import { filterSchedulesByIds } from "../../../utils/ScheduleUtils";

//component props interface
interface IBookServices {
  providersData: MatchedProvider[]; //
  setSelectedProviders: React.Dispatch<React.SetStateAction<MatchedProvider[]>>; //

  clientId: string; //
  isDataEdited: boolean; //
  setIsDataEdited: Dispatch<SetStateAction<boolean>>; //
  workingHoursMap: any[]; //
  gmtHours: number; //
  lastBookedDate: Date | undefined;
  selectedClients: ClientForms[];
  entityType: string;
}

const BookingSchedulerNew: React.FC<IBookServices> = ({
  clientId,
  providersData,
  isDataEdited,
  setIsDataEdited,
  lastBookedDate,
  selectedClients,
  entityType,
}) => {
  //#region variable region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    { getAllBookingsData, setSingleClientData, setRefreshValue, GetClientById },
    dispatch
  );

  //gets the selected single client from redux
  const clientData = useSelector<RootState, ClientForms>(
    (state) => state.clientListSlice.singleClient
  );

  //dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //stores list data
  const Bookeddata = useSelector<RootState, BookedSchedule[]>(
    (state) => state.scheduleListSlice.bookedListData
  );

  //for loader component
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //show all booking data
  const [showAllBookings, setShowAllBookings] = useState<boolean>(false);

  //dispatch for redux

  var islayoutChanged = true;

  //argdata store
  const [argsdata, setargsData] = useState<Record<string, any>>();

  const [eventData, setEventData] = useState<Record<string, any>[]>([]);

  //match values
  const matchValues = useSelector<RootState, MatchOption>(
    (state) => state.matchSlice
  );

  //ClientInsurance
  const clientInsurance = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //storing matched providers
  const [providers, setProviders] = useState<MatchedProvider[]>(providersData);

  //new and edit popup handler
  const [popup, setPopup] = useState<boolean>(false);

  //delete popup handler
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);

  //decide new and edit true value
  const [isNewPopup, setIsNewPopUp] = useState<boolean>(false);

  //for selected provider
  const [provider, setProvider] = useState<MatchedProvider>();

  const [weekController, setWeekController] = useState(true);

  const [selectedClient, ] = useState<ClientForms[]>(
    selectedClients.length > 0 ? selectedClients : [clientData]
  );

  // get Insurance name using Ids
  const getInsuranceName = (insuranceId: string): string => {
    const val = clientInsurance.find((item) => item.id === insuranceId);
    if (val) {
      return val.insuranceProviderName;
    }
    return "";
  };

  //used to stored Calender list
  const calenderList = useSelector<RootState, ICalenderlist[]>(
    (state) => state.bookingSchedulerSlice.listForCalendar
  );

  //***********populate data *******************/
  // selected provider data
  const [listForCalendar, setListForCalendar] = useState<ICalenderlist[]>([
    ...calenderList,
  ]);

  const rootState = useSelector((state: RootState) => state);

  //--------------------------------------------------------------
  //Customize calendar resource headers
  function resourceHeaderTemplate(props: any): JSX.Element {
    return (
      <div className="template-wrap">
        <div
          className="person-name meetingPersonBox"
          style={{
            background: getColor(props),
          }}
        >
          <img src={getResourceImage(props)} height={30} width={30} />
          <span>{getResourceName(props)}</span>
        </div>
      </div>
    );
  }

  // const headerTooltipTemplate = (props: any): string => {
  //   return `<div className="template-wrap">
  //     <div className="room-name">${getResourceName(props)}</div>
  //     </div>`;
  // };
  //#endregion

  //#region method region

  // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  //business units
  // const businessUnits = useSelector<RootState, OrgBU[]>(
  //   (state) => state.businessUnitsSlice.businessUnits
  // );

  // const businessConfig = () => {
  //   if (businessUnits.length > 0) {
  //     let arr:any[] = []
  //     businessUnits.forEach((item)=>{
  //       clientData.clientBasicDetails.businessUnit.forEach((res)=>{
  //           if(item.id === res){
  //             arr.push(item);
  //           }
  //       })
  //     })
  //     setBusiness(arr);
  // }};

  //--------------------------------------------------------------
  //used for access features
  let schedule: any = new Schedule({
    height: "600px",
    width: "100%",
    // selectedDate: new Date(),
    // firstDayOfWeek: 3,
    // // showWeekend: false,
    // currentView: "TimelineDay",

    // navigating: (args: NavigatingEventArgs) => {
    //   if (args?.currentView !== "TimelineMonth") {
    //     scheduleObj.current.headerRows = [];
    //   }
    // },
  });
  //ref for scheduleObj
  let scheduleObj = useRef<ScheduleComponent>(schedule);

  //----------------------------------------------------------------
  const onDataBinding = useCallback(() => {
    handleDataBinding(
      islayoutChanged,
      scheduleObj,
      selectedClient,
      providers,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islayoutChanged, selectedClient.length, providers, Bookeddata]);

  //render every cell
  const onRenderCell = useCallback((args: RenderCellEventArgs) => {
    handleRenderCell(args);
  }, []);

  // const onCellClick = (args: CellClickEventArgs): void => {
  //   scheduleObj.current.openEditor(args, 'Add');
  // }
  // const onEventClick = (args: EventClickArgs): void => {
  //   if (!(args.event as any).RecurrenceRule) {
  //     scheduleObj.current.openEditor(args.event, 'Save');
  //   }
  //   else {
  //     scheduleObj.current.quickPopup.openRecurrenceAlert();
  //   }
  // }

  const onPopupOpen = useCallback(
    (args: PopupOpenEventArgs) => {
      let data: Record<string, any> = args.data as Record<string, any>;
      let matchProvider = providersData.find((item: MatchedProvider) => {
        return item.employmentDetails?.id === data?.id;
      });
      setProvider(matchProvider);

      handlePopupOpen(
        args,
        setargsData,
        setPopup,
        setIsDeletePopup,
        setIsNewPopUp
      );
    },
    [providersData]
  );

  let instance = useMemo(() => {
    return new Internationalization();
  }, []);
  const getDateHeaderText = useCallback(
    (value: any) => {
      return instance.formatDate(value, { skeleton: "Ed" });
    },
    [instance]
  );
  const dateHeaderTemplate = (props: any) => {
    return (
      <div>
        <div>{getDateHeaderText(props.date)}</div>
      </div>
    );
  };

  const Scheduler = React.useMemo(() => {
    return (
      <ScheduleComponent
        cssClass="block-events"
        ref={scheduleObj}
        width="100%"
        height="650px"
        rowAutoHeight={true}
        showWeekend={weekController}
        currentView="TimelineDay"
        resourceHeaderTemplate={resourceHeaderTemplate.bind(this)}
        selectedDate={lastBookedDate}
        eventSettings={{
          dataSource: eventData,
          fields: {
            id: "Id",
            subject: { title: "Summary", name: "Subject" },
            isAllDay: { name: "IsAllDay" },
            location: { title: "Location", name: "Location" },
            endTimezone: { name: "TimeZone" },
            startTimezone: { name: "TimeZone" },
            description: { title: "Description", name: "Description" },
            startTime: { title: "From", name: "StartTime" },
            endTime: { title: "To", name: "EndTime" },
          },
        }}
        eventRendered={onEventRendered.bind(this)}
        popupOpen={onPopupOpen.bind(this)}
        renderCell={onRenderCell.bind(this)}
        dataBinding={onDataBinding.bind(this)}
        group={{
          resources: ["CalendarList"],
        }}
        dateHeaderTemplate={dateHeaderTemplate}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="id"
            title="Provider"
            name="CalendarList"
            allowMultiple={true}
            dataSource={listForCalendar}
            textField="name"
            idField="id"
            groupIDField="GroupId"
            colorField="color"
            workDaysField="workDays"
            startHourField="startHour"
            endHourField="endHour"
          ></ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective
            option="TimelineDay"
            startHour="06:00"
            endHour="21:00"
            timeScale={{ interval: 30, slotCount: 2 }}
          />
          {/* <ViewDirective 
              option="TimelineWeek" 
              startHour="06:00" endHour="21:00"
            />
            <ViewDirective 
              option="TimelineMonth" 
              showWeekNumber={true}
              // startHour="06:00" endHour="21:00"
            /> */}
          <ViewDirective
            option="Day"
            startHour="06:00"
            endHour="21:00"
            timeScale={{ interval: 30, slotCount: 2 }}
          />
          <ViewDirective
            option="Week"
            startHour="06:00"
            endHour="21:00"
            timeScale={{ interval: 30, slotCount: 2 }}
          />
          <ViewDirective option="Month" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Year" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Agenda" startHour="06:00" endHour="21:00" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            Month,
            Year,
            TimelineViews, //day & week
            // TimelineMonth,
            Resize,
            DragAndDrop,
            Agenda,
          ]}
        />
      </ScheduleComponent>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, listForCalendar]);
  // #endregion

  // The rows we need to show in the calendar
  const populateCalendarListData = useCallback(() => {
    // console.log("[populateCalendarListData]");
    const resourceList = handlePopulateResources(
      selectedClient,
      providers,
      true //from matchResults
    );
    return resourceList;
  }, [selectedClient, providers]);

  const populateClientBookedData = useCallback(() => {
    // console.log("[populateClientBookedData]...", clientData?.bookedServices?.length);
    if (clientData.bookedServices?.length !== 0 || selectedClient?.length > 0) {
      for (let i = 0; i < selectedClient.length; i++) {}
      let clientResults: any[] = [];

      populateBookedData(
        "CLIENT",
        selectedClients.length > 0 ? Bookeddata : clientData?.bookedServices,
        clientResults
      );

      return clientResults;
    }

    // setClientBooked(clientResults);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient.length, rootState]);

  const populateProviderBookedData = useCallback(() => {
    // console.log("[populateProviderBookedData]", Bookeddata?.length);
    let providerResults: any[] = [];
    const today = new Date();

    let providerIds: string[] = [];
    for (let i = 0; i < providers?.length; i++) {
      providerIds.push(providers[i].employmentDetails.id);
    }
    const providerBooking = filterSchedulesByIds(
      Bookeddata,
      [], //clientData Id List
      providerIds, //providerId List
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );

    populateBookedData(
      "PROVIDER",
      //providers[i]?.bookedServices,
      providerBooking,
      providerResults
    );

    return providerResults;
    // setProviderBooked(providerResults);
  }, [providers?.length, rootState]);

  //populate clientData
  useEffect(() => {
    // console.log("[get client data from local storage]");
    const newDispatchRef = dispatchActionRef.current;

    const data: any = localStorage.getItem("MatchValue");
    const parsedDATA = JSON.parse(data);

    if (data && data?.length > 0) {
      return () => {
        newDispatchRef.setRefreshValue(parsedDATA);
      };
    }

    const value: any = localStorage.getItem("ClientData");
    const parsedValue = JSON.parse(value);
    if (value && value?.length > 0) {
      return () => {
        newDispatchRef.setSingleClientData(parsedValue);
      };
    }
  }, [selectedClient.length]);

  // populate providers data
  useEffect(() => {
    setProviders(providersData);
  }, [providersData.length]);

  //----------------------------------------------------------------
  //Stage 1: render resource list first
  useEffect(() => {
    batch(() => {
      setIsLoading(false);
      // setShowAllBookings(false); //must have!!
    });

    if (selectedClient.length > 0) {
      // console.log("populate resources...");
      const resourceList = populateCalendarListData();

      batch(() => {
        setListForCalendar(resourceList);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // filterValues.ScheduleStatus,
    selectedClient,
    providersData,
  ]);

  //Stage 2: wait for resourceList & bookeddata complete, setIsLoading
  useEffect(() => {
    if (selectedClient.length > 0) {
      // console.log("reset isLoading...");

      batch(() => {
        setIsLoading(true); //set ready to load scheduler data
      });
    }
  }, [listForCalendar, Bookeddata]);

  //Stage 3a: wait for isLoading complete, populate event data
  //Stage 3b: wait for isLoading complete, populate work hours in OnDataBinding
  useEffect(() => {
    if (isLoading === true) {
      // console.log("begin scheduler...");

      const clientResults = populateClientBookedData();
      const providerResults = populateProviderBookedData();
      setShowAllBookings(true);
      if (clientResults)
        setEventData(() => {
          return [...clientResults, ...providerResults];
        });
      batch(() => {
        // setEventData([...clientResults, ...providerResults]);
        setIsLoading(false);
        // console.log("end scheduler...");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedClient?.length]);

  // console.log("isLoading:", isLoading, showAllBookings, eventData?.length);
  
  return (
    <div className="schedule-control-section">
      <Box className="detailsPageHeader">
        <Typography variant="h6" className="DetailsPageTitle">
          Your Bookings
        </Typography>
        <div className="baseSelected">
          {/* <p className="baseSelected__title">Based On:</p> */}
          <div className="fromGroup-chips">
            {entityType === "Client" && (
              <Card className="fromGroup-chip-active">
                {matchValues?.clientSelectedAuthorizationName}
              </Card>
            )}

            <Card className="fromGroup-chip-active">
              {/* {matchValues?.clientSelectedAuthorizationName.split(" ")[0]} */}
              {matchValues?.clientAuthInsuranceName}
              {" ("}
              {matchValues?.clientAuthCode}

              {")"}
            </Card>
            {matchValues?.selectedAvailability.map((item, index: number) => (
              <div key={index}>
                {item ? (
                  <Card className="fromGroup-chip-active" key={index}>
                    {item?.dayOfWeek} ({item.startTime} - {item.endTime})
                  </Card>
                ) : null}
              </div>
            ))}

            {matchValues.miles ? (
              <Card className="fromGroup-chip-active">
                {"< "}
                {matchValues.miles}
                {" Miles"}
              </Card>
            ) : null}

            {matchValues.clientAuthorization ? (
              <Card className="fromGroup-chip-active">
                {matchValues.clientAuthorization}
              </Card>
            ) : null}

            {matchValues.providerType.length > 0 ? (
              <Card className="fromGroup-chip-active">
                {`${matchValues.providerType.join(", ")}`}
              </Card>
            ) : null}

            {matchValues.specialities.length > 0 ? (
              <Card className="fromGroup-chip-active">
                {`${matchValues.specialities.join(", ")}`}
              </Card>
            ) : null}

            {matchValues.providerOnboardingStages.length > 0 ? (
              <Card className="fromGroup-chip-active">
                {`${matchValues.providerOnboardingStages.join(", ")}`}
              </Card>
            ) : null}

            {matchValues.providerOnboardingStatus ? (
              <Card className="fromGroup-chip-active">
                {matchValues.providerOnboardingStatus}
              </Card>
            ) : null}

            {matchValues.businessUnit ? (
              <Card className="fromGroup-chip-active">
                {matchValues.businessUnit}
              </Card>
            ) : null}
            {matchValues.insuranceProvider ? (
              <Card className="fromGroup-chip-active">
                {getInsuranceName(matchValues.insuranceProvider)}
              </Card>
            ) : null}
            {matchValues.availability.beginDate ? (
              <Card className="fromGroup-chip-active">
                {moment(matchValues.availability.beginDate).format(
                  "MM/DD/YYYY"
                )}
                {" - "}
                {moment(matchValues.availability.endDate).format("MM/DD/YYYY")}
              </Card>
            ) : null}
          </div>
        </div>
      </Box>
      <Box className="serviceList">
        {selectedClient.length > 0 &&
          selectedClient.map((item, index) => (
            <Box className="serviceItems" key={index}>
              <div
                className={"serviceCard serviceCard--green"}
                // clientToggle
                //   ? "serviceCard serviceCard--green"
                //   : "serviceCard serviceCard--deactivate"
                // }
                key={index}
              >
                <div className="serviceMainCard">
                  <div className="serviceCard__head">
                    <span className="serviceCard__authImage">
                      <img
                        src={
                          item?.clientBasicDetails?.clientProfile?.url ||
                          ClientImg
                        }
                        className="img-fluid"
                        alt="image_not_found"
                      />
                    </span>
                    <h5 className="serviceCard__authTitle">
                      {item?.clientBasicDetails?.childFirstName}{" "}
                      {item?.clientBasicDetails?.childLastName}
                    </h5>
                  </div>
                  <div className="serviceCard__body">
                    <div className="serviceCard__status">
                      <h6 className="serviceCard__status-value">
                        {item?.clientAuthUtilization?.weeklyAvgBooked.toFixed(
                          2
                        )}
                      </h6>
                      <span className="serviceCard__status-text">
                        Booked<br></br>(Weekly)
                      </span>
                    </div>
                    <div className="serviceCard__status">
                      <h6 className="serviceCard__status-value">
                        {item?.clientAuthUtilization?.weeklyAvgRemainedByAuthorization.toFixed(
                          2
                        )}
                      </h6>
                      <span className="serviceCard__status-text">
                        Remaining<br></br>(Weekly)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          ))}

        {/* <br></br> */}

        {providers?.map((provider: any, index: number) => {
          return (
            <Box className="serviceItems" key={index + 1}>
              <div
                className={"serviceCard serviceCard--purple"}
                // className={
                //   toggles[index]
                //     ? "serviceCard serviceCard--blue" //cardsColors[index]
                //     : "serviceCard serviceCard--deactivate"
                // }
                key={index + 1}
              >
                <div className="serviceMainCard">
                  <div className="serviceCard__head">
                    <span className="serviceCard__authImage">
                      <img
                        src={
                          provider?.employmentDetails?.providerProfile?.url ||
                          ProviderImg
                        }
                        className="img-fluid"
                        alt="image_not_found"
                      />
                    </span>
                    <h5 className="serviceCard__authTitle">
                      {provider?.employmentDetails?.firstName}{" "}
                      {provider?.employmentDetails?.lastName}
                    </h5>
                  </div>

                  {/* {provider?.providerUtilization?.timeTables?.length > 0 &&
                    provider?.providerUtilization?.timeTables[0].map(
                      (
                        strategyResult: UtilizationStrategyResult,
                        sIndex: number
                      ) => (
                        <div key={sIndex}> */}
                  {/* <div className="serviceCard__body">
                            <div className="serviceCard__status">
                              <h6 className="serviceCard__status-value">
                                {strategyResult.fillResultType}
                              </h6>
                              <span className="serviceCard__status-text">
                                <br></br>
                              </span>
                            </div>

                            <div className="serviceCard__status">
                              <h6 className="serviceCard__status-value">
                                {strategyResult.allocatedWeeks.toFixed(2)}
                              </h6>
                              <span className="serviceCard__status-text">
                                Across<br></br>(Weeks)
                              </span>
                            </div>
                          </div> */}

                  <div className="serviceCard__body">
                    <div className="serviceCard__status">
                      <h6 className="serviceCard__status-value">
                        {provider?.providerUtilization.weeklyAvgBooked.toFixed(
                          2
                        )}
                      </h6>
                      <span className="serviceCard__status-text">
                        Booked<br></br>(Weekly)
                      </span>
                    </div>

                    <div className="serviceCard__status">
                      <h6 className="serviceCard__status-value">
                        {provider?.providerUtilization.weeklyAvgRemainedByAvailability.toFixed(
                          2
                        )}
                      </h6>
                      <span className="serviceCard__status-text">
                        Available<br></br>(Weekly)
                      </span>
                    </div>
                  </div>

                  {/* <div className="serviceCard__body">
                            <div className="serviceCard__status">
                              <h6 className="serviceCard__status-value">
                                {strategyResult.fillableHours.toFixed(2)}
                              </h6>
                              <span className="serviceCard__status-text">
                                Fillable<br></br>(Hrs)
                              </span>
                            </div>

                            <div className="serviceCard__status">
                              <h6 className="serviceCard__status-value">
                                {strategyResult.unfillableHours.toFixed(2)}
                              </h6>
                              <span className="serviceCard__status-text">
                                Un-fillable<br></br>(Hrs)
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )} */}
                  {/* <div className="serviceCard__body">
                          <div className="serviceCard__status">
                          <h6 className="serviceCard__status-value">
                            {provider?.providerUtilization?.weeklyAvgBooked.toFixed(
                              2
                            )}
                          </h6>
                          <span className="serviceCard__status-text">
                            Booked<br></br>(Weekly)
                          </span>
                        </div>
                        <div className="serviceCard__status">
                          <h6 className="serviceCard__status-value">
                            {provider?.providerUtilization?.weeklyAvgRemainedByAvailability.toFixed(
                              2
                            )}
                          </h6>
                          <span className="serviceCard__status-text">
                            Remaining<br></br>(Weekly)
                          </span>
                        </div>
                        </div> */}
                </div>
              </div>
            </Box>
          );
        })}
      </Box>
      <br></br>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography sx={{ padding: "0 10px", fontWeight: "bold" }}>
          Show Weekend
        </Typography>
        <Switch
          sx={{
            "--Switch-thumbSize": "28px",
          }}
          checked={weekController}
          onChange={() => setWeekController(!weekController)}
          name="gilad"
        />
      </Box>

      {isLoading && <Loader isShow={isLoading} />}

      {/* after loading, show calendar component */}
      {showAllBookings === true && (
        <div className="col-lg-12 control-section">
          <div className="control-wrapper">{Scheduler}</div>
        </div>
      )}

      {popup && (
        <DiscoverBookingEditModal
          pageTitle={isNewPopup ? "Add Booking" : "Update Booking"}
          clientData={clientData}
          provider={provider}
          isOpen={popup}
          isEdit={isNewPopup ? false : true}
          isNew={isNewPopup ? true : false}
          closePopup={setPopup}
          argsdata={argsdata}
          // Bookeddata={Bookeddata}
          isDataEdited={isDataEdited}
          setIsDataEdited={setIsDataEdited}
          allSelectedClients={selectedClient}
          providersData={providers}
          fromPlanning={false}
          isAutoPlanned={false}
        />
      )}
      {isDeletePopup && (
        <>
          <DiscoverBookingEditModal
            pageTitle={"Cancel Booking"}
            clientData={clientData}
            provider={provider}
            isOpen={popup}
            isEdit={false}
            isNew={false}
            isDelete={true}
            closePopup={setIsDeletePopup}
            argsdata={argsdata}
            // Bookeddata={Bookeddata}
            isDataEdited={isDataEdited}
            setIsDataEdited={setIsDataEdited}
            allSelectedClients={[selectedClient]}
            providersData={providers}
            fromPlanning={false}
            isAutoPlanned={false}
          />
        </>
      )}
    </div>
  );
};

export default BookingSchedulerNew;
