import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Button,
  Box,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  CalendarTodayOutlined,
  Close,
  BorderColor,
  Delete,
} from "@mui/icons-material";
import moment from "moment";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  DeleteTaskById,
  GetMyTask,
  GetProjectById,
  PatchTaskById,
  ReorderTask,
  sortTask,
  GetAllTaskType,
} from "../../../../redux/features/project/taskManager-slice";

import AssignTaskModal from "./AssignTaskModal";
import DeleteConfirmation from "../../../../layouts/delete-confirmation/DeleteConfirmation";
import AddTaskModal from "./AddTaskModal";

import { dateToFromNowDaily } from "../../../../helpers/reverse-list/reverse-list";

import {
  FilterData,
  Priority,
  Status,
} from "../../../../constants/taskmanagement";
import { ITask } from "../../../../types/taskManager";

import "./BoardTask.scss";
import { GetAllClientData } from "../../../../redux/features/client-main/client-list-slice";
import { GetAllProviders } from "../../../../redux/features/providers-main/provider-list-slice";

// Custom Table
const StyledTable = styled(Table)(() => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

// Custom Table Col style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  padding: "5px 20px",
  flex: 2,
  minWidth: "180px",
}));

// Custom Table Row style
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "60px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "60px",
  borderRadius: "20px",
}));
export interface IUsers {
  id: string;
  name: string;
  profilePictureUrl: string;
}
interface IFilterData {
  [key: string]: string;
  DateRange: string;
  Status: string;
  Priority: string;
}

interface TaskHeaderProps {
  filterData: IFilterData;
  setFilterData: Dispatch<SetStateAction<IFilterData>>;
  isSort: { date: boolean; priority: boolean };
  setIsSort: Dispatch<
    SetStateAction<{
      date: boolean;
      priority: boolean;
    }>
  >;
}

interface RowComponentProps {
  task: ITask;
  projectId: string;
  setStatusAnchor: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setPriorityAnchor: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setCurrentTask: Dispatch<SetStateAction<ITask | undefined>>;
  setAddTaskModal: Dispatch<SetStateAction<boolean>>;
  setIsDelete: Dispatch<SetStateAction<boolean>>;
  setAssignTaskModal: Dispatch<SetStateAction<boolean>>;
  setAssignedTo: Dispatch<SetStateAction<IUsers | null | undefined>>;
  provider: DraggableProvided;
  handleUpdate: (type: string, value: string) => void;
  taskFor: string;
}

const TaskHeader: React.FC<TaskHeaderProps> = ({
  filterData,
  setFilterData,
  isSort,
  setIsSort,
}) => {
  return (
    <div className="taskHeader">
      <Typography>Filter:</Typography>
      <div className="taskFormController">
        {FilterData.map(({ data, label }, index) => (
          <div key={index}>
            <FormControl key={index} className="task-outer">
              <InputLabel id="demo-simple-select-label">{label}</InputLabel>
              <Select
                className="form-control"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterData[label]}
                style={{ height: "38px", width: "200px" }}
                onChange={(e) =>
                  setFilterData({ ...filterData, [label]: e.target.value })
                }
              >
                {data.map((item, index) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {label === "DateRange" && (
              <div
                onClick={() => setIsSort({ ...isSort, date: !isSort.date })}
                style={{ color: "#939393", marginLeft: "-12px" }}
              >
                {/* {isSort.date ? <North /> : <South />} */}
              </div>
            )}
            {label === "Priority" && (
              <div
                onClick={() =>
                  setIsSort({ ...isSort, priority: !isSort.priority })
                }
                style={{ color: "#939393", marginLeft: "-12px" }}
              >
                {/* {isSort.priority ? <North /> : <South />} */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const RowComponent: React.FC<RowComponentProps> = ({
  task,
  setStatusAnchor,
  setAddTaskModal,
  setIsDelete,
  setAssignTaskModal,
  setCurrentTask,
  setAssignedTo,
  provider,
  handleUpdate,
  setPriorityAnchor,
  taskFor,
}) => {
  return (
    <StyledTableRow
      onDoubleClick={() => {
        setAddTaskModal(true);
        setCurrentTask(task);
      }}
      ref={provider.innerRef}
      {...provider.dragHandleProps}
      {...provider.draggableProps}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...provider.draggableProps.style,
      }}
    >
      <StyledTableCell
        className="profile-picture"
        onClick={() => {
          if (task.assignedTo !== null) {
            setAssignedTo({
              id: task.assignedTo.id,
              name: task.assignedTo.firstName + " " + task.assignedTo.lastName,
              profilePictureUrl: task.assignedTo.profilePictureUrl,
            });
          } else {
            setAssignedTo(null);
          }
          setCurrentTask(task);
          setAssignTaskModal(true);
        }}
      >
        {/* <div className="image-outer">
          <img
            className="userSmallCircle"
            src={task.assignedTo?.profilePictureUrl || ProviderImg}
            alt=""
          />
        </div> */}
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
            {task?.assignedTo
              ? task?.assignedTo.firstName + " " + task?.assignedTo.lastName
              : ""}
          </Typography>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <Typography className="taskDetails_main">{task?.name}</Typography>
        {/* <Typography className="taskDetails_sub">{task?.description}</Typography> */}
      </StyledTableCell>
      <StyledTableCell>
        <Typography className="taskDetails_main">{task?.type}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography className="taskDetails_main">{taskFor}</Typography>
        {/* <Typography className="taskDetails_sub">{task?.description}</Typography> */}
      </StyledTableCell>
      <StyledTableCell>
        <div className="calender-outer">
          <input
            min={
              task.dueDate
                ? moment.utc(task.dueDate).format("MM/DD/YYYY")
                : new Date().toLocaleString()
            }
            style={{ position: "absolute", opacity: "0" }}
            type="date"
            onChange={(e) => {
              handleUpdate(
                "dueDate",
                moment.utc(e.target.value).format("MM/DD/YYYY")
              ); //store UTC date
              setCurrentTask(task);
            }}
          />
          <CalendarTodayOutlined />
          {task.dueDate ? moment(task.dueDate).format("LL") : ""}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div
          className={`task_priority_${task.priority}`}
          onClick={({ currentTarget }: any) => {
            setPriorityAnchor(currentTarget);
            setCurrentTask(task);
          }}
        >
          {task?.priority}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div
          className={`task-status-${task.status.replace(" ", "")}`}
          onClick={({ currentTarget }: any) => {
            setStatusAnchor(currentTarget);
            setCurrentTask(task);
          }}
        >
          {task?.status}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div
          className="taskDetails_main"
          // className={`task-status-${task.status.replace(" ", "")}`}
        >
          {task?.checklist?.filter((item) => item.checked === true).length} /{" "}
          {task?.checklist?.length}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="taskDetails_main">
          {dateToFromNowDaily(moment.utc(task?.updatedAt).toDate())}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        {/* {task.createdBy.authUserId === user?.userId && ( */}
        <div style={{ display: "flex", gap: "15px" }}>
          <BorderColor
            sx={{ cursor: "pointer" }}
            style={{ color: "#096DD9" }}
            onClick={() => {
              setAddTaskModal(true);
              setCurrentTask(task);
            }}
          />
          <Delete
            sx={{ cursor: "pointer" }}
            style={{ color: "#096DD9" }}
            onClick={() => {
              setIsDelete(true);
              setCurrentTask(task);
            }}
          />
        </div>
        {/* )} */}
      </StyledTableCell>
    </StyledTableRow>
  );
};

const BoardTask: React.FC<{ projectId: string }> = ({ projectId }) => {
  const dispatch = useAppDispatch();
  const [statusAnchor, setStatusAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  let openStatusAnchor = Boolean(statusAnchor);
  const [priorityAnchor, setPriorityAnchor] =
    useState<HTMLButtonElement | null>(null);
  let openPriorityAnchor = Boolean(priorityAnchor);
  const id =
    openStatusAnchor || openPriorityAnchor ? "simple-popover" : undefined;
  const [assignTaskModal, setAssignTaskModal] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<IFilterData>({
    DateRange: "",
    Priority: "",
    Status: "",
  });
  const [assignedTo, setAssignedTo] = useState<IUsers | null>();
  const tasks = useAppSelector((state) => state.taskManagerSlice.tasks);
  const [currentTask, setCurrentTask] = useState<ITask>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [addTaskModal, setAddTaskModal] = useState<boolean>(false);
  const [isSortData, setIsSortData] = useState<{
    date: boolean;
    priority: boolean;
  }>({
    date: false,
    priority: false,
  });

  const Allclients = useAppSelector((state) => state.clientListSlice.listData);
  const Allproviders = useAppSelector(
    (state) => state.providerListSlice.listData
  );

  //deletes tasks
  const deleteTask = () => {
    if (currentTask?.id) {
      dispatch(
        DeleteTaskById({
          id: currentTask.id,
        })
      );
    }
  };

  //updates status of task
  const handleUpdate = (type: string, value: string) => {
    if (currentTask) {
      dispatch(
        PatchTaskById({
          type: type, //"status",
          value: value,
          id: currentTask.id,
        })
      );
    }
    setStatusAnchor(null);
    setPriorityAnchor(null);
  };

  const filterTaskData = (data: ITask[]) => {
    //checking for filter criteria
    const isPriority =
      filterData.Priority !== "" && filterData.Priority !== "All";
    const isStatus = filterData.Status !== "" && filterData.Status !== "All";
    const isDate =
      filterData.DateRange !== "" && filterData.DateRange !== "All";

    //task filter
    if (isPriority || isStatus || isDate) {
      const result = data.filter((task) => {
        //checks for every single taslk
        const satisfiesPriority =
          !isPriority || task.priority === filterData.Priority;
        const satisfiesStatus = !isStatus || task.status === filterData.Status;
        const satifiesDate =
          !isDate || filterDataByTimeType(task, filterData.DateRange);

        //if satisfies all three conditions then return true
        return satisfiesPriority && satisfiesStatus && satifiesDate;
      });
      return result;
    } else {
      //for no filter criteria, exclude complete here.
      const data = tasks.filter((task) => task.status !== Status.Done);

      return data;
    }
  };

  //filter for timeType
  const filterDataByTimeType = (task: ITask, timeType: string) => {
    const currentDate = new Date(); // Get current date

    switch (timeType) {
      //case 1
      case "Current Month":
        const currentMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        return new Date(task?.createdAt) >= currentMonthStart;

      //case 2
      case "Last 3 Months":
        const threeMonthsAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          currentDate.getDate()
        );
        return new Date(task?.createdAt) >= threeMonthsAgo;

      //case 3
      case "Last Year":
        const lastYearStart = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return new Date(task?.createdAt) >= lastYearStart;

      default:
        return task;
    }
  };

  // sort task Data
  const sortTaskData = (item: ITask[]) => {
    let data: ITask[] = [];
    if (item?.length > 0) {
      let result = item.slice().sort((a: ITask, b: ITask) => {
        const priorityOrder = ["High", "Medium", "Low"];

        //if date and priority sort both is true
        if (isSortData.date && isSortData.priority) {
          return (
            (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any) &&
            priorityOrder.indexOf(a.priority) -
              priorityOrder.indexOf(b.priority)
          );
        }
        //if only date sorting is true
        else if (isSortData.date) {
          return (
            (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any)
          );
        }
        //if only priority sorting is true
        else if (isSortData.priority) {
          return (
            priorityOrder.indexOf(a.priority) -
            priorityOrder.indexOf(b.priority)
          );
        }
        //else just sort in descending order with createdAt property
        else {
          return 0;
        }
      });
      data = [...result];
      return data;
    }

    return item;
  };

  const dragHandler = ({ source, destination }: DropResult) => {
    if (projectId === "My Tasks") return;
    if (destination?.index === undefined) return;
    if (source.index === destination.index) return;
    const dragItem = tasks[source.index];
    const newtask = tasks.filter((_, i) => source.index !== i);
    newtask.splice(destination.index, 0, dragItem);
    dispatch(sortTask(newtask));
    const obj: Record<string, number> = {};
    newtask.forEach(({ id }, index) => (obj[id] = index));
    dispatch(ReorderTask(obj));
  };
  const handleAssignTask = () => {
    if (currentTask) {
      if (assignedTo && currentTask.id) {
        dispatch(
          PatchTaskById({
            type: "assign",
            value: assignedTo?.id,
            id: currentTask.id,
          })
        );
      }
    }

    setAssignTaskModal(false);
  };

  const handleTaskFor = (entityType: string, entityId: string) => {
    let name = "";
    if (entityType === "Client") {
      Allclients.forEach((client) => {
        if (client.clientBasicDetails.id === entityId) {
          name =
            client.clientBasicDetails.childFirstName +
            " " +
            client.clientBasicDetails.childLastName;
        }
      });
    } else {
      Allproviders.forEach((provider) => {
        if (provider.employmentDetails.id === entityId) {
          name =
            provider.employmentDetails.firstName +
            " " +
            provider.employmentDetails.lastName;
        }
      });
    }
    return name;
  };
  //#region useEffect region
  useEffect(() => {
    if (projectId === "My Tasks") {
      dispatch(GetMyTask());
    } else {
      dispatch(GetProjectById(projectId));
    }
    setFilterData({ DateRange: "", Priority: "", Status: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    dispatch(GetAllTaskType());
    if (Allclients.length === 0) {
      dispatch(GetAllClientData());
    }
    if (Allproviders.length === 0) {
      dispatch(GetAllProviders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TaskHeader
        filterData={filterData}
        setFilterData={setFilterData}
        isSort={isSortData}
        setIsSort={setIsSortData}
      />
      <Grid size={{ xs: 12 }} className="listingTable">
        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 820,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TableCell sx={{ flex: 2 }}>Assign To</TableCell>
                <TableCell sx={{ flex: 2 }}>Task Name</TableCell>
                <TableCell sx={{ flex: 2 }}>Task Type</TableCell>
                <TableCell sx={{ flex: 2 }}>Task For</TableCell>
                <TableCell sx={{ flex: 2 }}>Due Date</TableCell>
                <TableCell sx={{ flex: 2 }}>Priority</TableCell>
                <TableCell sx={{ flex: 2 }}>Status</TableCell>
                <TableCell sx={{ flex: 2 }}>Complete</TableCell>
                <TableCell sx={{ flex: 2 }}>Updated On</TableCell>
                <TableCell sx={{ flex: 2 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={dragHandler}>
              <Droppable
                droppableId="droppableId"
                direction="vertical"
                children={(provider) => (
                  <TableBody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                    // style={{maxWidth:"900px"}}
                  >
                    {sortTaskData(filterTaskData(tasks))?.map((data, index) => (
                      <Draggable
                        index={index}
                        key={data.id}
                        draggableId={data.id}
                        children={(provider) => (
                          <RowComponent
                            setPriorityAnchor={setPriorityAnchor}
                            setStatusAnchor={setStatusAnchor}
                            setIsDelete={setIsDelete}
                            setAddTaskModal={setAddTaskModal}
                            setCurrentTask={setCurrentTask}
                            setAssignTaskModal={setAssignTaskModal}
                            setAssignedTo={setAssignedTo}
                            task={data}
                            projectId={projectId}
                            provider={provider}
                            handleUpdate={handleUpdate}
                            taskFor={handleTaskFor(
                              data.entityType,
                              data.entityId
                            )}
                          />
                        )}
                      />
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              />
            </DragDropContext>
          </StyledTable>
        </TableContainer>
        <Popover
          id={id + "status"}
          open={openStatusAnchor}
          onClose={() => setStatusAnchor(null)}
          anchorEl={statusAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ width: "138px", padding: "10px" }}>
            {Object.values(Status).map((item, index) => (
              <MenuItem
                key={index}
                className={`task-status-${item.replace(" ", "")} mb-10`}
                value={item}
                onClick={() => handleUpdate("status", item)}
              >
                {item}
              </MenuItem>
            ))}
          </div>
        </Popover>
        <Popover
          id={id + "status"}
          open={openPriorityAnchor}
          onClose={() => setPriorityAnchor(null)}
          anchorEl={priorityAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ width: "138px", padding: "10px" }}>
            {Object.values(Priority).map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  className={`task-status-${item.replace(" ", "")} mb-10`}
                  value={item}
                  onClick={() => handleUpdate("priority", item)}
                >
                  {item}
                </MenuItem>
              );
            })}
          </div>
        </Popover>
        {/* <Popover
          id={id+"priority"}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ width: "138px", padding: "10px" }}>
            {Object.values(Priority).map((item, i) => (
              <MenuItem
                key={i}
                className={`task_priority_${item} mb-10`}
                value={item}
                onClick={() => handleUpdate("priority", item)}
              >
                {item}
              </MenuItem>
            ))}
          </div>
        </Popover> */}
      </Grid>
      {assignTaskModal && (
        <Dialog open={assignTaskModal} className="boardLargeModel">
          <div>
            <Grid>
              <Box className="largeModel__head">
                <Typography variant="h5" className="largeModel__title">
                  Assign To:
                </Typography>
                <Button
                  className="largeModel__closeBtn"
                  onClick={() => setAssignTaskModal(false)}
                >
                  <Close />
                </Button>
              </Box>
            </Grid>
          </div>
          <div className="create-new-board p-30px">
            <AssignTaskModal
              assignedTo={assignedTo}
              setAssignedTo={setAssignedTo}
              fromModal={false}
            />
            <Box className="create-new-board-footer">
              <Button onClick={() => handleAssignTask()} variant="contained">
                Assign
              </Button>
            </Box>
          </div>
        </Dialog>
      )}
      {isDelete && (
        <DeleteConfirmation
          name={"Task"}
          setModal={(value) => {
            if (value) {
              deleteTask();
            }
            setIsDelete(false);
          }}
        />
      )}
      {addTaskModal && (
        <Dialog open={addTaskModal} className="boardLargeModel">
          <AddTaskModal
            setAddTaskModal={setAddTaskModal}
            isEdit={true}
            currentTask={currentTask}
          />
        </Dialog>
      )}
    </div>
  );
};

export default BoardTask;
