import React from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Card, FormHelperText, InputLabel, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useState } from "react";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import { bestTimeContact, contactMethod } from "../../../constants/client";
import {
  resetSchoolAddress,
  setAddressForClient,
  setClientContactDetails,
  setSchoolAddressForClient,
} from "../../../redux/features/client-main/client-slice";

import "../../providers/provider/Providers.scss";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import VerifiedAddressImg from "../../../assets/images/images-svg/verifiedAddress.svg";
import {
  IAddress,
  IShouldBeComponent,
  IAddressWithLocation,
} from "../../../types/Common";
import { ClientContactDetails } from "../../../types/client";
// import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm } from "react-hook-form";
import { getAddressObject } from "src/utils/AddressUtils";
interface IClientContactDetails {
  onSubmit?: () => void;
}

const ClientContactDetailsForm: React.FC<IClientContactDetails> = ({
  onSubmit,
}) => {
  // #region variables region
  // eslint-disable-next-line
  const {
    control,
    formState: { errors },
  } = useForm();

  const [, setFullAddress] = useState<string>(
    useSelector<RootState, string>(
      (state) => state?.client?.clientContactDetails?.address?.fullAddress
    )
  );

  //whether phone is valid
  const [validPhone, setValidPhone] = useState<boolean>(true);

  //variable for handling school full Address
  const [, setSchoolFullAddress] = useState<string>(
    useSelector<RootState, string>(
      (state) => state?.client?.clientContactDetails?.schoolAddress?.fullAddress
    )
  );

  //used for handling display school address details
  const [displaySchoolAddress, setDisplaySchoolAddress] =
    useState<boolean>(false);

  //variable to store active step
  const activeStep = useSelector<RootState, number>(
    (state) => state.activeStep.activeStep
  );

  //stores Client Contact Details
  const clientContactDetails = useSelector<RootState, ClientContactDetails>(
    (state) => state.client.clientContactDetails
  );

  //handles editing of ClientContact details form
  const isEditClient = useSelector<RootState, boolean>(
    (state) => state.client.isEditClient
  );

  //used for  Dispatch
  const dispatch = useDispatch();

  //variable for handling Error
  const [hasError, setHasError] = useState<boolean>(false);

  //variable for storing address data
  const [addressObj, setAddressObj] = useState<IAddressWithLocation>({
    fullAddress: clientContactDetails.address.fullAddress,
    lat: 0,
    lng: 0,
  });

  //variable for storing address data
  const [schoolAddressObj, setSchoolAddressObj] =
    useState<IAddressWithLocation>({
      fullAddress: "",
      lat: 0,
      lng: 0,
    });

  // #endregion

  // #region methods region

  const handleChange = (e: any) => {
    const obj = {
      name: e?.target?.name,
      value: e?.target?.value,
    };
    dispatch(setClientContactDetails(obj));
  };

  //handles submission of Client Contact Details
  const handleSubmit = async () => {
    if (isEditClient) {
      /*TODO: still has 1 bug... comment this out by Hui temporarily
      when user only work on add-school address,
      in that case, the addressObj is not populated initially from API side.
      if we turn this code on, we will override addressObj with null. 
      if we want to turn this code on, we will need to ensure addressObj has initial data
      */
      //dispatch(setAddressForClient(addressObj));
      if (onSubmit) {
        onSubmit();
      }
    } else {
      dispatch(setActiveSteps(activeStep + 1));
    }
  };

  //handles selection of adderess while using google autocomplete
  const handleSelect = (e: any) => {
    let description = e?.label;
    if (!description) return;

    // Get latitude and longitude via utility functions
    //getGeocode({ address: description }).then(async (results: any) => {
    geocodeByAddress(description).then(
      async (results: google.maps.GeocoderResult[]) => {
        const addressObject = await getAddressObject(
          results[0].address_components
        );

        //Get lat & Long Data
        const { lat, lng } = await getLatLng(results[0]);

        //set City Field
        dispatch(
          setClientContactDetails({
            name: "city",
            value: addressObject?.city,
          })
        );

        //set State Field
        dispatch(
          setClientContactDetails({
            name: "state",
            value: addressObject?.province,
          })
        );

        //set ZipCode Field
        dispatch(
          setClientContactDetails({
            name: "zipCode",
            value: addressObject?.postal_code,
          })
        );

        let addressStr = description.split(",")[0];

        setFullAddress(addressStr);

        //TODO: need to store separately later.
        let tempObj = {
          lat: lat,
          lng: lng,
          fullAddress: addressStr,
        };
        setAddressObj(tempObj);
        dispatch(setAddressForClient(tempObj));
      }
    );
  };

  const handleSelectSchool = (e: any) => {
    let description = e?.label;
    if (!description) return;

    // Get latitude and longitude via utility functions
    //getGeocode({ address: description }).then(async (results: any) => {
    geocodeByAddress(description).then(
      async (results: google.maps.GeocoderResult[]) => {
        const addressObject = await getAddressObject(
          results[0].address_components
        );

        //Get lat & Long Data
        const { lat, lng } = await getLatLng(results[0]);

        //set City Field
        dispatch(
          setClientContactDetails({
            name: "schoolCity",
            value: addressObject?.city,
          })
        );

        //set State Field
        dispatch(
          setClientContactDetails({
            name: "schoolState",
            value: addressObject?.province,
          })
        );

        //set ZipCode Field
        dispatch(
          setClientContactDetails({
            name: "schoolZipCode",
            value: addressObject?.postal_code,
          })
        );

        let addressStr = description.split(",")[0];

        setSchoolFullAddress(addressStr);

        //TODO: need to store seperately later.
        let tempObj = {
          lat: lat,
          lng: lng,
          fullAddress: addressStr,
        };
        setSchoolAddressObj(tempObj);
        dispatch(setSchoolAddressForClient(tempObj));
      }
    );
  };

  //used for removing focus
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // #endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          {/* <h1 className="stepperForm__title"> Contact Information</h1> */}
          <div className="stepperForm__inner">
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">Phone Number</InputLabel>
                  <PhoneInput
                    className="border rounded-sm pl-2"
                    defaultCountry="US"
                    defaultValue={clientContactDetails?.phoneNumber}
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    control={control}
                    onChange={(e: any) => {
                      if (e?.length > 0) {
                        const phoneRegex =
                          /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
                        if (phoneRegex.test(e) && e.length === 12) {
                          setValidPhone(true);
                          dispatch(
                            setClientContactDetails({
                              name: "phoneNumber",
                              value: e,
                            })
                          );
                        } else {
                          setValidPhone(false);
                        }
                      } else {
                        setValidPhone(true); //optional is ok
                      }
                    }}
                  />
                  <span className="text-red-600 test-sm">
                    {errors.phone && "enter phone number"}
                  </span>
                  {!validPhone ? (
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      Phone number is not valid
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">Email</InputLabel>
                  <TextValidator
                    className="form-control"
                    onChange={handleChange}
                    name="email"
                    id="email"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("email");
                      }
                    }}
                    value={clientContactDetails?.email}
                    validators={["required", "isEmail"]}
                    errorMessages={["Please enter email", "email is not valid"]}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Home Address{" "}
                    {clientContactDetails?.address?.fullAddress?.trim().length >
                      0 && clientContactDetails?.address?.lat !== 0 ? (
                      <img
                        className="verifiedAddress"
                        src={VerifiedAddressImg}
                        alt="VerifiedAddressImg"
                      />
                    ) : (
                      ""
                    )}
                  </InputLabel>

                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE"
                    selectProps={{
                      defaultInputValue:
                        clientContactDetails.address?.fullAddress,
                      //placeholder: "please enter address here",
                      //value: clientContactDetails.address?.fullAddress, //NOTE: can't have this!!!
                      setValue: clientContactDetails.address?.fullAddress,
                      onChange: handleSelect,
                      debounce: 300,
                      styles: {
                        input: (provided: any) => ({
                          ...provided,
                          fontSize: 16,
                        }),
                        option: (provided: any) => ({
                          ...provided,
                          fontSize: 16,
                        }),
                        singleValue: (provided: any) => ({
                          ...provided,
                          fontSize: 16,
                        }),
                      },
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    onLoadFailed={(error) =>
                      console.error("Could not inject Google script", error)
                    }
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">City</InputLabel>
                  <TextValidator
                    className="form-control"
                    onChange={handleChange}
                    name="city"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        removeFocus("city");
                      }
                    }}
                    value={clientContactDetails?.city}
                    // validators={["required"]}
                    // errorMessages={["Please enter city"]}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 6, md: 5 }}>
                    <Box className="fromGroup">
                      <InputLabel className="fromLabel">State</InputLabel>
                      <TextValidator
                        className="form-control"
                        onChange={handleChange}
                        name="state"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            removeFocus("state");
                          }
                        }}
                        value={clientContactDetails?.state}
                        // validators={["required"]}
                        // errorMessages={["Please enter state"]}
                      />
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 6, md: 7 }}>
                    <Box className="fromGroup">
                      <InputLabel className="fromLabel">ZipCode</InputLabel>
                      <TextValidator
                        className="form-control"
                        onChange={handleChange}
                        name="zipCode"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            removeFocus("zipcode");
                          }
                        }}
                        value={clientContactDetails?.zipCode}
                        // validators={[ "required",]}
                        // errorMessages={["Please enter zip code"]}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {!displaySchoolAddress && (
                <Grid size={{ xs: 12 }} sx={{ position: "relative" }}>
                  <Button
                    onClick={() => setDisplaySchoolAddress(true)}
                    className="addAddressBtn"
                  >
                    + Add School Address
                  </Button>
                </Grid>
              )}

              {displaySchoolAddress && (
                <>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <Box className="fromGroup">
                      <InputLabel className="fromLabel">
                        School Address
                      </InputLabel>

                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE"
                        selectProps={{
                          defaultInputValue:
                            clientContactDetails.schoolAddress?.fullAddress,
                          //placeholder: "please enter address here",
                          setValue:
                            clientContactDetails.schoolAddress?.fullAddress,
                          onChange: handleSelectSchool,
                          debounce: 300,
                          styles: {
                            input: (provided: any) => ({
                              ...provided,
                              fontSize: 16,
                            }),
                            option: (provided: any) => ({
                              ...provided,
                              fontSize: 16,
                            }),
                            singleValue: (provided: any) => ({
                              ...provided,
                              fontSize: 16,
                            }),
                          },
                        }}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        onLoadFailed={(error) =>
                          console.error("Could not inject Google script", error)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box className="fromGroup">
                      <InputLabel className="fromLabel">City</InputLabel>
                      <TextValidator
                        className="form-control"
                        onChange={handleChange}
                        name="schoolCity"
                        id="schoolCity"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            removeFocus("schoolCity");
                          }
                        }}
                        value={clientContactDetails.schoolCity}
                        // validators={["required"]}
                        // errorMessages={["Please enter city"]}
                      />
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Grid container spacing={6}>
                      <Grid size={{ xs: 6, md: 5 }}>
                        <Box className="fromGroup">
                          <InputLabel className="fromLabel">State</InputLabel>
                          <TextValidator
                            className="form-control"
                            onChange={handleChange}
                            name="schoolState"
                            id="schoolState"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                removeFocus("schoolState");
                              }
                            }}
                            value={clientContactDetails.schoolState}
                            // validators={["required"]}
                            // errorMessages={["Please enter state"]}
                          />
                        </Box>
                      </Grid>
                      <Grid size={{ xs: 6, md: 7 }}>
                        <Box className="fromGroup">
                          <InputLabel className="fromLabel">ZipCode</InputLabel>
                          <TextValidator
                            className="form-control"
                            onChange={handleChange}
                            name="schoolZipCode"
                            id="schoolZipCode"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                removeFocus("schoolZipCode");
                              }
                            }}
                            value={clientContactDetails.schoolZipCode}
                            // validators={["required"]}
                            // errorMessages={["Please enter zip code"]}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 12 }} sx={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        setDisplaySchoolAddress(false);
                        dispatch(resetSchoolAddress({}));
                        setSchoolFullAddress("");
                      }}
                      className="addAddressBtn"
                    >
                      - Remove School Address
                    </Button>
                  </Grid>
                </>
              )}
              <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">Contact Method</InputLabel>
                  <div className="fromGroup-chips">
                    {contactMethod.map((item) => (
                      <Card
                        className={
                          clientContactDetails?.contactMethod === item.value
                            ? "fromGroup-chip-active"
                            : ""
                        }
                        onClick={() => {
                          const language = {
                            name: "contactMethod",
                            value: item.value,
                          };
                          dispatch(setClientContactDetails(language));
                        }}
                        key={item.key}
                      >
                        {item.value}
                      </Card>
                    ))}
                  </div>
                  {/* make this optional now.
                  {hasError && !clientContactDetails?.contactMethod ? (
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      Please select any contact method
                    </FormHelperText>
                  ) : (
                    ""
                  )} */}
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                <Box className="fromGroup">
                  <InputLabel className="fromLabel">
                    Best Time To Contact
                  </InputLabel>
                  <div className="fromGroup-chips">
                    {bestTimeContact.map((item) => (
                      <Card
                        className={
                          clientContactDetails?.bestTimeToContact === item.value
                            ? "fromGroup-chip-active"
                            : ""
                        }
                        onClick={() => {
                          const language = {
                            name: "bestTimeToContact",
                            value: item.value,
                          };
                          dispatch(setClientContactDetails(language));
                        }}
                        key={item.key}
                      >
                        {item.value}
                      </Card>
                    ))}
                  </div>
                  {/* no need to check here. 
                  {hasError &&
                  clientContactDetails?.bestTimeToContact?.length === 0 ? (
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      Please select best time
                    </FormHelperText>
                  ) : (
                    ""
                  )} */}
                </Box>
              </Grid>
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            {!isEditClient ? (
              <Button
                type="submit"
                color="inherit"
                disabled={activeStep === 0 ? true : false}
                className="border-button"
                onClick={() => dispatch(setActiveSteps(activeStep - 1))}
              >
                Previous
              </Button>
            ) : (
              ""
            )}

            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              onClick={() => {
                //only require email & valid field values
                if (clientContactDetails?.schoolAddress?.fullAddress === "") {
                  if (addressObj) {
                    const obj = {
                      name: "address",
                      value: addressObj,
                    };
                    dispatch(setClientContactDetails(obj));
                  }
                  if (clientContactDetails?.schoolAddress?.fullAddress === "") {
                    if (schoolAddressObj) {
                      const obj = {
                        name: "schoolAddress",
                        value: schoolAddressObj,
                      };
                      dispatch(setClientContactDetails(obj));
                    }
                  }
                } else {
                  let tempObj = {
                    lat: clientContactDetails.address.lat,
                    lng: clientContactDetails.address.lng,
                    fullAddress: clientContactDetails.address.fullAddress,
                  };
                  const obj = {
                    name: "address",
                    value: tempObj,
                  };

                  dispatch(setClientContactDetails(obj));
                }
              }}
            >
              {isEditClient ? "Save Changes" : "Next"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default ClientContactDetailsForm;
