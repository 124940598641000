import moment from "moment";
import { formattedDate } from "../../constants/common";
import {
  ClientAuthPayload,
  ClientForms,
  ISchedules,
  InsuranceProviders,
} from "../../types/client";
import {
  Credentials,
  ProviderFilters,
  ProviderForms,
} from "../../types/provider";
import { AvailabilityDetails } from "../../types/availability";
import {
  MatchResult,
  MatchedProvider,
  ScheduleFilters,
} from "../../types/schedule";
import { searchClientText, searchProviderText } from "../../utils/FilterUtils";
import { EntityScheduleStatus } from "../../constants/providers";

//checks if any value is selected filter filter or search
export const canFilterMatchOn = (
  filterValues: ScheduleFilters,
  searchVal: string
): boolean => {
  if (
    filterValues.businessUnit.length > 0 ||
    filterValues.availabilityStatus ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.onBoardingStage.length > 0 ||
    filterValues.clientStatus.length > 0 ||
    filterValues.intakeStage.length > 0 ||
    filterValues.ScheduleStatus ||
    filterValues.startDate ||
    filterValues.endDate ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//to sorting availabilitydeatails according to begindate
export const sortingAvailability = (item: ProviderForms) => {
  let sorted = [...item.availabilityDetails].sort(function (a, b) {
    return (
      (new Date(formattedDate(a?.beginDate)) as any) -
      (new Date(formattedDate(b?.beginDate)) as any)
    );
  });

  return sorted;
};

//to sorting availabilityDetails according to endDates
export const sortingAvailabilityEndDate = (item: ProviderForms) => {
  let sorted = [...item.availabilityDetails].sort(function (a, b) {
    return (
      (new Date(formattedDate(b?.endDate)) as any) -
      (new Date(formattedDate(a?.endDate)) as any)
    );
  });

  return sorted;
};

//logic for filtering a single provider and Client
export const filterSingleProvider = (
  item: MatchResult,
  filterValues: ScheduleFilters,
  searchVal: string
): boolean => {
  if (
    !filterValues.businessUnit.includes("All Units") &&
    filterValues.businessUnit.length > 0 &&
    !filterValues.businessUnit.some((ele) =>
      item.baseProvider!.employmentDetails.businessUnit.includes(ele)
    )
  ) {
    return false;
  }

  let onBoardingFlag = true;
  while (onBoardingFlag) {
    if (filterValues.onBoardingStage.length > 0) {
      if (filterValues.onBoardingStage.includes("All Stages")) {
        onBoardingFlag = false;
      } else {
        let count = 0;
        filterValues.onBoardingStage.forEach((matchValue: string) => {
          if (
            item.baseProvider!.employmentDetails.onBoardingStage === matchValue
          ) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        onBoardingFlag = false;
      }
    }
    onBoardingFlag = false;
  }

  if (filterValues.ScheduleStatus) {
    if (
      item.baseProvider!.providerUtilization.status ===
      filterValues.ScheduleStatus
    ) {
      return true;
    } else {
      return false;
    }
  }
  if (item.baseProvider!.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: MatchResult,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: AvailabilityDetails[] = [];
  // item.providerAvailability.filter((item: AvailabilityDetails) => {
  //   return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  // });

  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: AvailabilityDetails,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    // if (
    //   new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
    //     new Date(item.specificDate) &&
    //   new Date(item.specificDate) <=
    //     new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
    // ) {
    //   return true;
    // }
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(moment(item.endDate).format("YYYY-MM-DD"))
    ) {
      if (
        new Date(moment(item.endDate).format("YYYY-MM-DD")) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(moment(item.beginDate).format("YYYY-MM-DD")) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};
//logic for filtering a single provider and Client
export const filterSingleClient = (
  item: MatchResult,
  filterValues: ScheduleFilters,
  searchVal: string
): boolean => {
  var startDate =
    item.baseClient!.availabilityDetails &&
    item.baseClient!.availabilityDetails.length > 0
      ? new Date(item.baseClient!.availabilityDetails[0]?.beginDate as string)
      : null;
  var endDate =
    item.baseClient!.availabilityDetails &&
    item.baseClient!.availabilityDetails.length > 0
      ? new Date(
          item.baseClient!.availabilityDetails[
            item.baseClient!.availabilityDetails.length - 1
          ]?.endDate as string
        )
      : null;

  var today = new Date();
  if (item.baseClient!.clientBasicDetails) {
    if (
      !filterValues.businessUnit.includes("All Units") &&
      filterValues.businessUnit.length > 0 &&
      !filterValues.businessUnit.some((ele) =>
        item.baseClient!.clientBasicDetails.businessUnit.includes(ele)
      )
    ) {
      return false;
    }
    if (filterValues.clientStatus.length > 0) {
      if (
        !filterValues.clientStatus.includes(
          item.baseClient!.clientBasicDetails.clientType
        )
      ) {
        return false;
      }
    }
    if (
      filterValues.availabilityStatus === "Current" &&
      startDate &&
      endDate &&
      !(today >= startDate && today <= endDate)
    ) {
      return false;
    } else if (
      filterValues.availabilityStatus === "Expired" &&
      endDate &&
      !(today > endDate)
    ) {
      return false;
    }

    if (
      filterValues.intakeStage.length > 0 &&
      !filterValues.intakeStage.includes("All Stages")
    ) {
      if (
        !filterValues.intakeStage.includes(
          item.baseClient!.clientBasicDetails.intakeStage
        )
      ) {
        return false;
      }
    }
    if (
      !filterValues.concerns.includes("All Concerns") &&
      filterValues.concerns.length > 0
    ) {
      if (
        filterValues.concerns &&
        !item.baseClient!.clientBasicDetails.checklistHistory.includes(
          filterValues.concerns[0]
        )
      ) {
        return false;
      }
    }
  }
  if (
    !filterValues.insuranceProvider.includes("All Employees") &&
    filterValues.insuranceProvider.length > 0
  ) {
    let count = 0;
    item.baseClient?.authorizations.forEach((item: ClientAuthPayload) => {
      if (
        filterValues.insuranceProvider.includes(
          item.insurance.insuranceProviderName
        )
      ) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }

  // if (filterValues.ScheduleStatus) {
  //   if (
  //     item.baseClient!.clientUtilization.status === filterValues.ScheduleStatus
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  if (filterValues.ScheduleStatus) {
    if (
      filterValues.ScheduleStatus === EntityScheduleStatus.NoAvailability ||
      filterValues.ScheduleStatus === EntityScheduleStatus.AvailabilityExpired
    ) {
      //read from clientUtilization
      if (
        item.baseClient!.clientUtilization.status ===
        filterValues.ScheduleStatus
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      //read from clientAuthUtilization
      if (
        item.baseClient!.clientAuthUtilization?.status ===
        filterValues.ScheduleStatus
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (item.baseClient!.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  return true;
};

export const filterMatchOn = <T>(
  filterValues: ScheduleFilters,
  searchVal: string,
  data: MatchResult[]
): MatchResult[] => {
  return canFilterMatchOn(filterValues, searchVal)
    ? data.filter((item: MatchResult) => {
        if (item.baseClient === null) {
          return filterSingleProvider(item, filterValues, searchVal);
        } else {
          return filterSingleClient(item, filterValues, searchVal);
        }
      })
    : data;
};

//logic for handling the search and filter
// export const filterProvidersMatchOn = (
//   filterTypes: ScheduleFilters,
//   searchVal: string,
//   data: MatchedProvider[]
// ): MatchedProvider[] => {
//   return canFilterProviders(filterTypes, searchVal)
//     ? data.filter((item: MatchedProvider) => {
//         return filterSingleProvider(item, filterTypes, searchVal);
//       })
//     : data;
// };
