import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { ReactComponent as VectorUp } from "../../../assets/images/images-svg/VectorUp.svg";
// import FileUpload from "react-material-file-upload";
import { MuiFileInput } from "mui-file-input";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  ApplyReviewAction,
  GetImportExportRequest,
  GetReviewContext,
  PostImportRequest,
  setReviewContextToEmpty,
  setSelectedReviewRequests,
  UpdateImportSettings,
  updateReviewAction,
} from "../../../redux/features/import-export/import-export-slice";
import { IImportExportRequest } from "../../../types/tools";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Close } from "@mui/icons-material";
import { JsonView, collapseAllNested } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

export enum ReviewAction {
  Import = "Load",
  Ignore = "Ignore",
  InReview = "In Review",
}
const ImportDetails: React.FC = () => {
  // #region variables region

  //dispatch
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const pendingReviewslogs = useAppSelector<IImportExportRequest[]>((state) =>
    state.importExportSlice.requestLogs.filter(
      (item) => item.jobType === "Import" && item.status === "Pending"
    )
  );
  const { importSettings, selectedReviewRequests, reviewContext } =
    useAppSelector((state) => state.importExportSlice);

  //temp static data
  // #endregion
  //#region methods region
  const handleImportFile = async (files: any) => {
    // console.log({ files });
    // if (files.length === 1) {
    const formData = new FormData();
    //static data for now will change later.-----
    formData.append("entityType", "Client");
    formData.append("needReview", "true");
    //-------------------------------------------
    formData.append("file", files);
    await dispatch(PostImportRequest(formData));
    dispatch(GetImportExportRequest());
    // } else {
    //   setHasError(true);
    // }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    dispatch(setReviewContextToEmpty());
  };
  // #endregion
  //#region grid region
  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  function dataBound() {
    // console.log("[dataBound]");
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }
  const HeaderAllTemplate = () => {
    return (
      <Box style={{ display: "flex", height: " 17px" }}>
        <Checkbox />
        <Typography style={{ fontSize: "14px", fontWeight: "700" }}>
          All
        </Typography>
      </Box>
    );
  };
  const Alltemplate = (props: any) => {
    const reviews = useAppSelector(
      (state) => state.importExportSlice.selectedReviewRequests
    );
    const handleSelectPendingReviews = () => {
      const isExist = reviews.some((item) => item.id === props.id);
      if (isExist) {
        const updatedReviews = reviews.filter((item) => item.id !== props.id);
        dispatch(setSelectedReviewRequests(updatedReviews));
      } else {
        dispatch(
          setSelectedReviewRequests([
            ...reviews,
            { id: props.id, reviewAction: props.reviewAction },
          ])
        );
      }
    };
    return (
      <Box>
        <Checkbox
          checked={reviews.some((item) => item.id === props.id)}
          onClick={() => handleSelectPendingReviews()}
        />
      </Box>
    );
  };
  const TextTemplate = (props: any) => {
    return (
      <Box>
        {props.column.field === "fileName" && (
          <Typography>{props.fileName} </Typography>
        )}
        {props.column.field === "entityType" && (
          <Typography>{props.entityType}</Typography>
        )}

        {props.column.field === "entityName" && (
          <Typography>{props.entityName}</Typography>
        )}
        {props.column.field === "reviewStatus" && (
          <Typography>{props.reviewStatus}</Typography>
        )}
        {props.column.field === "reviewAction" && (
          <Typography>{props.reviewAction}</Typography>
        )}
      </Box>
    );
  };

  const ReviewActionTemplate = (props: any) => {
    const reviews = useAppSelector(
      (state) => state.importExportSlice.selectedReviewRequests
    );
    const handleReviewAction = (reviewAction: string) => {
      const isSelected = reviews.find((selected) => selected.id === props.id);
      if (isSelected) {
        const updateSelectedReviews = reviews.map((selected) =>
          selected.id === props.id ? { ...selected, reviewAction } : selected
        );
        dispatch(setSelectedReviewRequests(updateSelectedReviews));
      }
      dispatch(
        updateReviewAction({ id: props.id, reviewAction: reviewAction })
      );
    };
    return (
      <div>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {Object.values(ReviewAction).map((item) => (
              <FormControlLabel
                key={item}
                value={item}
                control={<Radio />}
                label={item === "Load" ? "Import" : item}
                checked={props?.reviewAction === item}
                onClick={() => {
                  handleReviewAction(item);
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const ReviewContextTemplate = (props: any) => {
    return (
      <Box>
        <Button
          onClick={() => {
            dispatch(GetReviewContext({ id: props.id }));
            setOpenPopup(true);
          }}
        >
          <CompareArrowsIcon />
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    if (reviewContext) {
      const tabs = Object.keys(reviewContext);
      setSelectedTab(tabs[0]);
    }
  }, [reviewContext]);

  // #endregions
  return (
    // new UI start
    <Box>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <div
            className="detailsCard import-card"
            onClick={() => {}}
            style={{ position: "relative" }}
          >
            <div className="detailsCard__header">
              <Typography className="detailsCard__title">
                Upload File
              </Typography>
            </div>
            <div className="detailsCard__body ">
              <Box className="smallCardIcons">
                <VectorUp />
              </Box>
              <Box className="importFileUplaod-block">
                <MuiFileInput
                  hideSizeText
                  multiple={false}
                  sx={{
                    "& .css-flami6": { display: "none" },
                    "& fieldset": { border: "none" },
                  }}
                  value={files[0]}
                  onChange={(e: File | null) => {
                    if (e !== null) {
                      handleImportFile(e);
                    } else {
                      setHasError(true);
                    }
                  }}
                />
                {hasError && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select one file at a time.
                  </FormHelperText>
                )}
              </Box>
              {/* <p className="smallCardText">{item.desciption}</p> */}
            </div>
          </div>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }} className="review-policy">
          <div className="detailsCard" onClick={() => {}}>
            <div className="detailsCard__header">
              <Typography className="detailsCard__title">
                Review Policy
              </Typography>
            </div>
            <Box className="detailsCard__body">
              <Box className="settingBox">
                <div className="settingBox__part">
                  <FormGroup className="settingBox__field field-part">
                    <h6 className="settingBox__field-title">
                      Review status is New
                    </h6>
                    <Box className="customSelectDrp">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {Object.values(ReviewAction).map((item) => (
                            <FormControlLabel
                              key={item}
                              value={item}
                              control={<Radio />}
                              label={item === "Load" ? "Import" : item}
                              checked={importSettings.new === item}
                              onChange={() => {
                                dispatch(
                                  UpdateImportSettings({
                                    newItem: item,
                                    alreadyExist: importSettings.alreadyExist,
                                  })
                                );
                              }}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </FormGroup>
                  <FormGroup className="settingBox__field field-part">
                    <h6 className="settingBox__field-title">
                      Review Status is Already Exist
                    </h6>
                    <Box className="customSelectDrp">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {Object.values(ReviewAction).map((item) => (
                            <FormControlLabel
                              key={item}
                              value={item}
                              control={<Radio />}
                              label={item === "Load" ? "Import" : item}
                              checked={importSettings.alreadyExist === item}
                              onChange={() => {
                                dispatch(
                                  UpdateImportSettings({
                                    newItem: importSettings.new,
                                    alreadyExist: item,
                                  })
                                );
                              }}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </FormGroup>
                </div>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
      <Box className="pending-reviews">
        <Typography className="pendingReview__title">
          Pending Reviews
        </Typography>
        <GridComponent
          id="grid" //must have!!
          className="gridTable"
          dataSource={pendingReviewslogs}
          dataBound={dataBound.bind(this)}
          allowPaging={true}
          ref={(grid) => (gridInstance = grid as GridComponent)}
          statelessTemplates={["directiveTemplates"]}
          load={load}
          width="auto"
          height="650"
          allowSelection={true}
          selectionSettings={{
            type: "Single",
          }}
          // allowGrouping={true}
          groupSettings={groupOptions}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          allowTextWrap={true}
          filterSettings={{ type: "Menu" }}
          textWrapSettings={textWrapSettings}
          allowPdfExport={true}
          allowExcelExport={true}
          pdfExportComplete={pdfExportComplete}
          excelExportComplete={excelExportComplete}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              visible={false}
              headerText="ID"
              isPrimaryKey={true}
              width="100"
            />
            <ColumnDirective
              width="100"
              textAlign="Left"
              field="name"
              template={Alltemplate}
              headerTemplate={HeaderAllTemplate}
            />
            <ColumnDirective
              headerText="Import File"
              width="150"
              textAlign="Left"
              field="fileName"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="EntityType"
              width="100"
              textAlign="Left"
              field="entityType"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="Import Entity"
              width="100"
              textAlign="Left"
              field="entityName"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="Review Status"
              width="100"
              textAlign="Left"
              field="reviewStatus"
              template={TextTemplate}
            />
            <ColumnDirective
              headerText="Review Context"
              width="100"
              textAlign="Left"
              field="reviewAction"
              template={ReviewContextTemplate}
            />
            <ColumnDirective
              headerText="Review Action"
              width="150"
              textAlign="Left"
              field="reviewAction"
              template={ReviewActionTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Group,
              ContextMenu,
              Toolbar,
              Resize,
              DetailRow,
              Filter,
              Sort,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent>
      </Box>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(
              ApplyReviewAction({ reviewRequest: selectedReviewRequests })
            );
            dispatch(GetImportExportRequest());
            dispatch(setSelectedReviewRequests([]));
          }}
        >
          Apply Review Actions
        </Button>
      </Box>
      <Dialog open={openPopup} className="review">
        <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
          <Typography variant="h3" className="smallModel__title">
            File vs Current Comparison
          </Typography>
          <Button className="smallModel__closeBtn" onClick={handleClosePopup}>
            <Close />
          </Button>
        </Box>
        {!reviewContext ? (
          <div className="rebook-loader">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box className="comparisonTable" p={3}>
            <ul className="innerTabs mb-tab">
              {reviewContext &&
                Object.keys(reviewContext).map((tab) => (
                  <li key={tab} className="innerTabs__item form-tabs">
                    <button
                      onClick={() => setSelectedTab(tab)}
                      className={selectedTab === tab ? "active" : ""}
                    >
                      {tab}
                    </button>
                  </li>
                ))}
            </ul>
            <Box className="comparisonTableInner">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Field</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Current Data</strong>
                    </TableCell>
                    <TableCell>
                      <strong>File Data</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                  {reviewContext &&
                    reviewContext[selectedTab] &&
                    Object.entries(reviewContext[selectedTab]).map(
                      ([key, value]) =>
                        Array.isArray(value) ? (
                          value.map((item, index) => (
                            <TableRow key={`${key}-${index}`}>
                              <TableCell>{`${key} [${index}]`}</TableCell>
                              <TableCell>
                                <JsonView
                                  data={item?.oldValue || {}}
                                  shouldExpandNode={collapseAllNested}
                                />
                              </TableCell>
                              <TableCell>
                                <JsonView
                                  data={item?.newValue || {}}
                                  shouldExpandNode={collapseAllNested}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>
                              <JsonView
                                data={value.oldValue}
                                shouldExpandNode={collapseAllNested}
                              />
                            </TableCell>
                            <TableCell>
                              <JsonView
                                data={value.newValue}
                                shouldExpandNode={collapseAllNested}
                              />
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        )}

        <Box className="assessmentModel__footer">
          <Button type="submit" className="send-btn" onClick={handleClosePopup}>
            Close
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ImportDetails;
