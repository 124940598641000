import axios from "../axios/axios";
import { Response } from "../types/response";
import { BookedSchedule } from "../types/schedule";

export const bookService = async (bookingData: any, fromPlanning: boolean) => {
  try {
    const { data } = await axios.post<any>(`/client/book-service`, {
      ...bookingData,
      fromPlanning,
    });
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const editService = async (bookingData: any, fromPlanning: boolean) => {
  try {
    const { data } = await axios.put<any>(
      `/client/book-service/${bookingData.serviceId}`,
      {
        //userTodayDate: bookingData.userTodayDate,
        //repeatService: bookingData.repeatService,
        bookedClientId: bookingData.bookedClientId,
        bookedProviderId: bookingData.bookedProviderId,

        updateOption: bookingData.updateOption,
        specificDate: bookingData.specificDate,
        fromTime: bookingData.fromTime,
        toTime: bookingData.toTime,

        serviceType: bookingData.serviceType,
        serviceLocation: bookingData.serviceLocation,
        description: bookingData.description,

        category: bookingData.category,

        fromPlanning: fromPlanning,

        //no need to pass or allow update!!!
        //authId: bookingData.authId,
        //authCode: bookingData.authCode,
        //repeatOn: bookingData.repeatOn,
        //repeatCustomEnd: bookingData.repeatCustomEnd,
        //repeatWorkDays: bookingData.repeatWorkDays,
      }
    );
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const deleteService = async (bookingData: any) => {
  try {
    const { data } = await axios.post<any>(
      `/client/book-service/${bookingData.serviceId}`,
      {
        // fromTime: bookingData.fromTime,
        //toTime: bookingData.toTime,
        //userTodayDate: bookingData.userTodayDate,
        // repeatService: bookingData.repeatService,
        bookedClientId: bookingData.bookedClientId,
        bookedProviderId: bookingData.bookedProviderId,
        specificDate: bookingData.specificDate,
        cancelOption: bookingData.cancelOption,
        //whoCancelled: bookingData.whoCancelled,
        //cancellationReason: bookingData.cancellationReason,
        //authId: bookingData.authId,
        //authCode: bookingData.authCode,
      }
    );
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//Delete multi schedule list
export const deleteMultiSchedules = async (schedulesIds: string[]) => {
  try {
    const { data } = await axios.post(`/deleteSchedules`, {
      schedulesIds: schedulesIds,
    });
    return { data };
  } catch (e: any) {
    console.log("exception:", e);
    throw e?.response?.data;
  }
};

export const cancelService = async (
  bookingData: any,
  fromPlanning: boolean
) => {
  try {
    const { data } = await axios.post<any>(
      `/client/book-service/${bookingData.serviceId}`,
      {
        cancelOption: bookingData.cancelOption,
        specificDate: bookingData.specificDate,
        bookedClientId: bookingData.bookedClientId,
        bookedProviderId: bookingData.bookedProviderId,
        whoCancelled: bookingData.whoCancelled,
        cancellationReason: bookingData.cancellationReason,
        isDelete: bookingData.isDelete,
        fromPlanning: fromPlanning,
        billable: bookingData.billableOption,
      }
    );
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//For all schedules for all clients & all employees
export const getAllBookings = async () => {
  try {
    const { data } = await axios.get<Response<BookedSchedule>>(`/schedules`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//For all schedules for all clients & all employees
export const postCalenderList = async (
  clientIds: string[],
  providerIds: string[]
) => {
  let payload = {
    clientIds,
    providerIds,
  };
  try {
    const { data } = await axios.post(`/match`, payload);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getRebookingOptions = async (payloadData: {
  cancelRequestBy: string;
  bookingId: string;
}) => {
  try {
    const data = await axios.post("/admin/rebooking", payloadData);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const createGroupBooking = async (
  bookingData: any,
  fromPlanning: boolean
) => {
  try {
    const data = await axios.post("/group/book-service", {
      ...bookingData,
      fromPlanning,
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const updateGroupBooking = async (
  bookingData: any,
  fromPlanning: boolean
) => {
  try {
    const { data } = await axios.put<any>(
      `/group/book-service/${bookingData.serviceId}`,
      {
        bookedClientIds: bookingData.bookedClientIds,
        bookedProviderId: bookingData.bookedProviderId,

        updateOption: bookingData.updateOption,
        specificDate: bookingData.specificDate,
        fromTime: bookingData.fromTime,
        toTime: bookingData.toTime,
        serviceType: bookingData.serviceType,
        description: bookingData.description,
        serviceLocation: bookingData.serviceLocation,
        category: bookingData.category,
        fromPlanning: fromPlanning,
        userTodayDate: bookingData.userTodayDate,
        clientGroup: bookingData.clientGroup,
      }
    );

    return { data };
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const cancelGroupBooking = async (
  bookingData: any,
  fromPlanning: boolean
) => {
  try {
    const { data } = await axios.post<any>(
      `/group/cancel-service/${bookingData.serviceId}`,
      {
        bookedClientIds: bookingData.bookedClientIds,
        bookedProviderId: bookingData.bookedProviderId,

        category: bookingData.category,
        specificDate: bookingData.specificDate,

        cancelOption: bookingData.cancelOption,
        whoCancelled: bookingData.whoCancelled,
        cancellationReason: bookingData.cancellationReason,
        isDelete: bookingData.isDelete,
        fromPlanning: fromPlanning,
        clientGroup: bookingData.clientGroup,
      }
    );

    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const SchedulesService = {
  bookService,
  editService,
  deleteService,
  cancelService,
  getAllBookings,
  deleteMultiSchedules,
  postCalenderList,
  getRebookingOptions,
  createGroupBooking,
  updateGroupBooking,
  cancelGroupBooking,
};

export default SchedulesService;
