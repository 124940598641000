import {
  Box,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewBoard } from "../../../../redux/features/custom-board/custom-board-slice";
import ClearIcon from "@mui/icons-material/Clear";
import "./NewBoardModal.scss";

const Stages = ({ stageName, stageId, stageIndex, setErrorMsg, setError, primitive, provider, permission, boardType }) => {
  const newStages = useSelector((state) => state.boardSlice.newBoard.stages);

  const dispatch = useDispatch();

  const [showDetails, setDetails] = useState(false);
  const [CheckList, setCheckList] = useState("");
  const nameHandler = (e) => {
    const changedStages = newStages.map((stage) => {
      if (stage.stageName === stageName && stage.index === stageIndex) {
        return {
          ...stage,
          stageName: e.target.value,
        };
      } else {
        return stage;
      }
    });
    dispatch(setNewBoard({ name: "stages", value: changedStages }));
  };

  const goalHandler = (e) => {
    if (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 90) {
      const setGoal = newStages.map((stage, i) => {
        if (stage.stageName === stageName) {
          return {
            ...stage,
            goal: e.target.value,
          };
        } else {
          return stage;
        }
      });
      dispatch(setNewBoard({ name: "stages", value: setGoal }));
    }
  };

  const deleteHandler = () => {
    let deletingStage;
    const filterStage = newStages.filter((s, i) => {
      if (s.stageName === stageName && s.id === stageId) {
        deletingStage = s;
        return false;
      } else {
        return true;
      }
    });

    if (deletingStage.boardColumnNames?.length > 0) {
      setErrorMsg("Can't delete this stage because it contain's users");
      setError(true);
      setTimeout(() => setError(false), 2000);
    } else {
      dispatch(setNewBoard({ name: "stages", value: filterStage }));
    }
  };

  const checkListAddHandler = () => {
    if (!CheckList) return;
    const addChecklist = newStages.map((stage, i) => {
      if (stage.stageName === stageName && stage.id === stageId) {
        return {
          ...stage,
          checkList: [...stage.checkList, CheckList],
        };
      } else {
        return stage;
      }
    });
    dispatch(setNewBoard({ name: "stages", value: addChecklist }));
    setCheckList("");
  };

  const checkListDeleteHandler = (deleteIndex) => {
    const addChecklist = newStages.map((stage, i) => {
      if (stage.stageName === stageName && stage.id === stageId) {
        const filterChecklist = stage.checkList.filter(
          (list, listIndex) => listIndex !== deleteIndex
        );
        return {
          ...stage,
          checkList: filterChecklist,
        };
      } else {
        return stage;
      }
    });

    dispatch(setNewBoard({ name: "stages", value: addChecklist }));
  };

  const permissionHandler = () => {
    let newPermisson = permission;
    if (boardType === "client") {
      // if (permission?.clientView !== undefined) {
      if (permission.viewClient?.includes(stageId)) {
        newPermisson = {
          ...newPermisson,
          viewClient: newPermisson.viewClient?.filter((id) => id !== stageId)
        }
      } else {
        newPermisson = {
          ...newPermisson,
          viewClient: newPermisson.viewClient !== undefined ? [...newPermisson.viewClient, stageId] : [stageId]
        }
      }

    } else if (boardType === "provider") {

      if (permission.viewProvider?.includes(stageId)) {
        newPermisson = {
          ...newPermisson,
          viewProvider: newPermisson.viewProvider?.filter((id) => id !== stageId)
        }
      } else {
        newPermisson = {
          ...newPermisson,
          viewProvider: [...newPermisson.viewProvider, stageId]
        }
      }
    }
    dispatch(setNewBoard({ name: "permission", value: newPermisson }));
  }

  return (
    <Box
      my={2}
      ref={provider.innerRef}
      {...provider.draggableProps}
      {...provider.dragHandleProps}
      minHeight={60}
    >
      <Box
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.14) 0px 3px 8px",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"}>
            <MenuIcon
              fontSize="medium"
              color="disabled"
              sx={{ marginTop: "8px" }}
            />
            <Box className="fromGroup">
              <FormControl
                fullWidth
                style={{ width: "400px" }}
              >
                <TextField
                  className="border-none"
                  placeholder="Stage Name"
                  disabled={primitive}
                  value={
                    newStages.find(
                      (stage) => stage.stageName === stageName
                        && stage.id === stageId)?.stageName
                  }
                  onChange={nameHandler}
                />
              </FormControl>
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {showDetails && (
              <Box
                onClick={() => setDetails(false)}
                sx={{ cursor: "pointer", display: "flex" }}
              >
                <KeyboardArrowUpIcon />
              </Box>
            )}
            {!showDetails && (
              <Box
                onClick={() => setDetails(true)}
                sx={{ cursor: "pointer", display: "flex" }}
              >
                <KeyboardArrowDownIcon />
              </Box>
            )}
          </Box>
        </Box>
        {showDetails && (
          <>
            <div className="stageDetails">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                m={2}
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.14) 0px 3px 8px",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "max-content",
                }}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <AccessTimeIcon fontSize="medium" color="disabled" />
                  <Typography mx={1}>Goal</Typography>
                </Box>
                <Box>
                  <TextField
                    type="number"
                    value={newStages.find((stage) =>
                      stage.stageName === stageName && stage.id === stageId)?.goal}
                    InputProps={{
                      inputProps: {
                        max: 90,
                        min: 1,
                      },
                    }}
                    onChange={goalHandler}
                    size="small"
                    placeholder="Enter days"
                  />
                  {/* <Select
                  renderValue={() => newStages[index].goal}
                  value={newStages[index].goal}
                  sx={{ width: "100px", height: "30px" }}
                  onChange={goalHandler}
                >
                  <MenuItem value={"1 Day"}>1 Day</MenuItem>
                  <MenuItem value={"2 Days"}>2 Days</MenuItem>
                  <MenuItem value={"3 Days"}>3 Days</MenuItem>
                  <MenuItem value={"4 Days"}>4 Days</MenuItem>
                  <MenuItem value={"5 Days"}>5 Days</MenuItem>
                </Select> */}

                </Box>

              </Box>
              {primitive &&
                <Box
                  className="permission-box"

                >
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        checked={boardType === "client" ? permission?.viewClient?.includes(stageId) : permission?.viewProvider?.includes(stageId)}
                        // value={viewPermission}
                        onChange={() => permissionHandler()}
                        control={<Switch color="primary" />}
                        label={boardType === "client" ? "Visible for client:" : "Visible for employee:"}
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>

              }

            </div>

            <Box>
              <InputLabel
                sx={{
                  padding: "5px 16px",
                }}
                className="fromLabel"
                variant="standard"
              >
                Checklist
              </InputLabel>
              <Box
                m={2}
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.14) 0px 3px 8px",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <Box className="fromGroup checklist-bar">
                  <FormControl>
                    <TextField
                      className="border-none"
                      placeholder="Add checklist"
                      value={CheckList}
                      onChange={(e) => setCheckList(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          checkListAddHandler();
                        }
                      }}
                    />
                  </FormControl>
                  <button onClick={checkListAddHandler}>Add</button>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "5px 16px",
                  display: "flex",
                }}
              >
                {newStages
                  .find((stage) =>
                    stage.stageName === stageName && stage.id === stageId)
                  ?.checkList.map((list, i) => (
                    <Box
                      key={list}
                      index={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        background: "#d8ecff",
                        borderRadius: "5px",
                        padding: "5px",
                        margin: "0px 5px",
                      }}
                    >
                      <Typography variant="caption">{list}</Typography>
                      <ClearIcon
                        fontSize="small"
                        onClick={() => checkListDeleteHandler(i)}
                        sx={{ marginLeft: "5px", cursor: "pointer" }}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DeleteIcon
                onClick={deleteHandler}
                fontSize="small"
                sx={{
                  marginTop: "2px",
                  marginLeft: "auto",
                  cursor: "pointer",
                  color: "#096DD9",
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Stages;
