import ClientBoard from "../../clients/client-trello-board/ClientBoard";

interface IIntake {
  updateHandler?: () => void;
  callbacks: any;
  clientIntakeModal: (id: string) => void;
}
const Intake: React.FC<IIntake> = ({
  updateHandler,
  callbacks,
  clientIntakeModal,
}) => {
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0px 5px",
        }}
      >
        <Typography mr={1} variant="h6" fontWeight={"bold"}>
          Stages
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            padding: "0px 5px",
          }}
          onClick={updateHandler}
        >
          <SettingsOutlinedIcon sx={{ fontSize: "25px" }} color="primary" />
          <Typography mr={1}> Setting </Typography>
        </div>
      </div> */}

      <div className="cardBlock cardBlockIntake ">
        <div className="clientBoardSlider-module">
          <div className="clientBoardSlider">
            <ClientBoard
              callbacks={callbacks}
              chatCommand={clientIntakeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intake;
