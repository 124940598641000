import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { Box, Button, Card, FormHelperText, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setAddressProvider,
  setContactDetailsFromForm,
} from "../../../redux/features/providers-main/provider-slice";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import {
  PrefferedContactMethod,
  ContactTimeDetails,
} from "../../../constants/providers";
import { ContactDetails } from "../../../types/provider";
import VerifiedAddressImg from "../../../assets/images/images-svg/verifiedAddress.svg";
import { getAddressObject } from "src/utils/AddressUtils";

//Component Props
interface ProviderContactProps {
  isNew: boolean;
  updateHandler?: () => void;
}

interface AddressObj {
  fullAddress: string;
  lng: number;
  lat: number;
}

const ContactDetailsForm: React.FC<ProviderContactProps> = ({
  isNew,
  updateHandler,
}) => {
  // #region variables region

  //full address value
  const [, setFullAddress] = useState<string>(
    useSelector<RootState, string>(
      (state) => state?.providerSlice?.contactDetails?.address?.fullAddress
    )
  );

  //Variable for handling validations for contact method and time to contact
  const [hasError, setHasError] = useState(false);

  //Variable for active step for stepper
  const { activeStep } = useSelector((state: RootState) => state.activeStep);

  //variable for storing object
  const [addressObj, setAddressObj] = useState<AddressObj>();

  //Contact details object to manage form state in redux
  const contactDetails = useSelector<RootState, ContactDetails>(
    (state) => state.providerSlice.contactDetails
  );

  //App dispatch for redux
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const { control } = useForm();

  // variable for handling null  phonenumber error
  const [contacterror, setContactError] = useState<boolean>(false);

  //variable for handling invalid phonenumber error
  const [validContactMessage, setValidContactMessage] =
    useState<boolean>(false);

  //#endregion

  // #region methods region

  //Handles the submit for the form
  const handleSubmit = (): void => {
    if (
      !contactDetails.contactMethod ||
      !contactDetails.bestTimeToContact ||
      !contactDetails.phoneNumber
      // !contactDetails.address.fullAddress
      //!fullAddress  //comment out by Hui, allow partial address.
    ) {
      setHasError(true);
    } else {
      if (!isNew) {
        /*TODO: still has 1 bug... comment this out by Hui temporarily
        when user only work on add-school address,
        in that case, the addressObj is not populated initially from API side.
        if we turn this code on, we will override addressObj with null. 
        if we want to turn this code on, we will need to ensure addressObj has initial data
        */
        //dispatch(setAddressForProvider(addressObj));

        if (!contacterror) {
          if (updateHandler) {
            updateHandler();
            return;
          }
        }
      }
      if (!contacterror) {
        setHasError(false);
        dispatch(setActiveSteps(activeStep + 1));
      }
    }
  };

  //Call Google API to get GoogleGeoCodeReul
  const handleSelect = (e: any) => {
    let description = e?.label;
    if (!description) return;

    // Get latitude and longitude via utility functions
    geocodeByAddress(description).then(
      async (results: google.maps.GeocoderResult[]) => {
        const addressObject = getAddressObject(results[0].address_components);

        //Get lat & Long Data
        const { lat, lng } = await getLatLng(results[0]);

        dispatch(
          setContactDetailsFromForm({
            name: "city",
            value: addressObject?.city,
          })
        );

        dispatch(
          setContactDetailsFromForm({
            name: "state",
            value: addressObject?.province,
          })
        );

        dispatch(
          setContactDetailsFromForm({
            name: "zipCode",
            value: addressObject?.postal_code,
          })
        );

        let addressStr = description.split(",")[0];
        setFullAddress(addressStr);
        let tempObj = {
          lat: lat,
          lng: lng,
          fullAddress: addressStr,
        };
        setAddressObj(tempObj);
        dispatch(setAddressProvider(tempObj));
      }
    );
  };

  //removes focus when enter is pressed in an input box
  const removeFocus = (id: string): void => {
    document.getElementById(id)?.blur();
  };

  // #endregion

  return (
    <div className="stepperForm">
      <ValidatorForm onSubmit={handleSubmit}>
        <div className="stepperForm__inner">
          {/*<h1 className="stepperForm__title p-0">Contact Information</h1>*/}
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Phone Number</InputLabel>
                <PhoneInput
                  className="border rounded-sm pl-2"
                  defaultCountry="US"
                  defaultValue={contactDetails.phoneNumber}
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  onChange={(e: any) => {
                    if (e == null) {
                      setContactError(true);
                      setValidContactMessage(false);
                    } else {
                      setContactError(false);
                      const phoneRegex =
                        /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
                      if (phoneRegex.test(e) && e.length === 12) {
                        setValidContactMessage(false);
                        dispatch(
                          setContactDetailsFromForm({
                            name: "phoneNumber",
                            value: e,
                          })
                        );
                      } else {
                        setValidContactMessage(true);
                      }
                    }
                  }}
                  control={control}
                  rules={{ required: true, validate: isPossiblePhoneNumber }}
                  numberinputprops={{
                    className: "py-1 pl-2 border rounded-r-sm",
                  }}
                />
                {contacterror ? (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    Please enter phone number
                  </FormHelperText>
                ) : validContactMessage ? (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    Phone number is not valid
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Email</InputLabel>
                <TextValidator
                  className="form-control"
                  id="email"
                  onKeyDown={(e: React.KeyboardEvent<{}>) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("email");
                    }
                  }}
                  onChange={(e: any) =>
                    dispatch(
                      setContactDetailsFromForm({
                        name: "email",
                        value: e.target.value,
                      })
                    )
                  }
                  name="email"
                  value={contactDetails.email}
                  validators={["required", "isEmail"]}
                  errorMessages={["Please enter email", "email is not valid"]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Address{" "}
                  {contactDetails?.address?.fullAddress?.trim().length > 0 &&
                  contactDetails?.address?.lat !== 0 ? (
                    <img
                      className="verifiedAddress"
                      src={VerifiedAddressImg}
                      alt="VerifiedAddressImg"
                    />
                  ) : (
                    ""
                  )}
                </InputLabel>

                <GooglePlacesAutocomplete
                  apiKey="AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE"
                  selectProps={{
                    defaultInputValue: contactDetails.address?.fullAddress,
                    //placeholder: "please enter address here",
                    //value: contactDetails.address?.fullAddress, //NOTE: can't have this!!!
                    setValue: contactDetails.address?.fullAddress,
                    onChange: handleSelect,
                    debounce: 300,
                    styles: {
                      input: (provided: any) => ({
                        ...provided,
                        fontSize: 16,
                      }),
                      option: (provided: any) => ({
                        ...provided,
                        fontSize: 16,
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        fontSize: 16,
                      }),
                    },
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["us"],
                    },
                  }}
                  onLoadFailed={(error) =>
                    console.error("Could not inject Google script", error)
                  }
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">City</InputLabel>
                <TextValidator
                  className="form-control"
                  id="city"
                  onKeyDown={(e: React.KeyboardEvent<{}>) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("city");
                    }
                  }}
                  onChange={(e: any) =>
                    dispatch(
                      setContactDetailsFromForm({
                        name: "city",
                        value: e.target.value,
                      })
                    )
                  }
                  name="city"
                  value={contactDetails.city}
                  validators={["required"]}
                  errorMessages={["Please enter city"]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">State</InputLabel>
                <TextValidator
                  className="form-control"
                  id="state"
                  onKeyDown={(e: React.KeyboardEvent<{}>) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("state");
                    }
                  }}
                  onChange={(e: any) =>
                    dispatch(
                      setContactDetailsFromForm({
                        name: "state",
                        value: e.target.value,
                      })
                    )
                  }
                  name="state"
                  value={contactDetails.state}
                  validators={["required"]}
                  errorMessages={["Please enter state"]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">ZipCode</InputLabel>
                <TextValidator
                  className="form-control"
                  id="zipcode"
                  onKeyDown={(e: React.KeyboardEvent<{}>) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("state");
                    }
                  }}
                  onChange={(e: any) =>
                    dispatch(
                      setContactDetailsFromForm({
                        name: "zipCode",
                        value: e.target.value,
                      })
                    )
                  }
                  name="zip_code"
                  value={contactDetails.zipCode}
                  validators={[
                    "required",
                    // "matchRegexp:^[0-9]{5}(?:-[0-9]{4})?$",
                  ]}
                  errorMessages={[
                    "Please enter zip code",
                    // "zip-code  is not valid",
                  ]}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Contact Method</InputLabel>
                <div className="fromGroup-chips">
                  {PrefferedContactMethod.map((item) => (
                    <Card
                      className={
                        contactDetails.contactMethod === item.value
                          ? "fromGroup-chip-active"
                          : ""
                      }
                      onClick={() =>
                        dispatch(
                          setContactDetailsFromForm({
                            name: "contactMethod",
                            value: item.value,
                          })
                        )
                      }
                      key={item.key}
                    >
                      {item.value}
                    </Card>
                  ))}
                </div>
                {hasError && contactDetails.contactMethod === "" ? (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select any contact method
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Best Time To Contact
                </InputLabel>
                <div className="fromGroup-chips">
                  {ContactTimeDetails.map((item) => (
                    <Card
                      className={
                        contactDetails.bestTimeToContact === item.value
                          ? "fromGroup-chip-active"
                          : ""
                      }
                      onClick={(e) =>
                        dispatch(
                          setContactDetailsFromForm({
                            name: "bestTimeToContact",
                            value: item.value,
                          })
                        )
                      }
                      key={item.key}
                    >
                      {item.value}
                    </Card>
                  ))}
                </div>
                {hasError && contactDetails.bestTimeToContact === "" ? (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please select any contact time
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </div>

        {isNew ? (
          <>
            <Box className="stepperForm__footer">
              <Button
                type="submit"
                color="inherit"
                disabled={activeStep === 0 ? true : false}
                className="border-button"
                onClick={() => dispatch(setActiveSteps(activeStep - 1))}
              >
                Previous
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                type="submit"
                className="button"
                onClick={() => {
                  if (contactDetails.phoneNumber.length < 10) {
                    setContactError(true);
                  }
                  setHasError(true);
                  if (contactDetails?.address?.fullAddress === "") {
                    if (addressObj) {
                      const obj = {
                        name: "address",
                        value: addressObj,
                      };
                      dispatch(setContactDetailsFromForm(obj));
                    }
                  } else {
                    let tempObj = {
                      lat: contactDetails?.address?.lat,
                      lng: contactDetails?.address?.lng,
                      fullAddress: contactDetails?.address?.fullAddress,
                    };
                    const obj = {
                      name: "address",
                      value: tempObj,
                    };
                    dispatch(setContactDetailsFromForm(obj));
                  }
                }}
              >
                Next
              </Button>
            </Box>
          </>
        ) : (
          <div className="largeModel__footer">
            <Button className="button" type="submit">
              Save Changes
            </Button>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};

export default ContactDetailsForm;
