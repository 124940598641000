import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { AppDispatch, RootState, useAppSelector } from "../../../redux/store";
import { ITaskState } from "../../../types/trello-board";
import Board from "../../trello-board/Board";
import {
  moveProviderCardToAnotherBoard,
  setProviderTasks,
} from "../../../redux/features/providers-main/provider-board-slice";
import { OrgService } from "../../../constants/company-user";
import { OrgBU, OrgData } from "../../../types/company";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CardData } from "src/types/board";
import { setBusinessUnitFilters } from "src/redux/features/custom-board/custom-board-slice";
import { GetAllProviders } from "src/redux/features/providers-main/provider-list-slice";
import Loader from "../../../layouts/loader/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Boards = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

interface ProviderBoardProps {
  callbacks: ((id: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const BoardStage: React.FC<ProviderBoardProps> = ({
  callbacks,
  chatCommand,
}) => {
  // #region variables region

  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  //intake stages
  const initonBoardingStages = useSelector<RootState, any>(
    (state) => state.providerOnBoardingStage.stages
  );
  const [onBoardingStages, setOnBoardingStages] =
    useState(initonBoardingStages);

  //client boards
  const inittasks = useSelector<RootState, ITaskState>(
    (state) => state.providerBoardSlice
  );

  const [tasks, setTasks] = useState(inittasks);

  //get all providers from redux
  const providerList = useAppSelector(
    (state) => state.providerListSlice.listData
  );
  //get loading status for getAllproviders api
  const loader = useAppSelector((state) => state.providerListSlice.loader);

  const businessUnitsFilterDetails = useAppSelector(
    (state) => state.boardSlice.selectedBusinessUnits
  );

  const selectedBusinessUnits =
    businessUnitsFilterDetails?.find((item) => item.boardId === "provider")
      ?.businessUnits || [];

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );
  // #endregion

  // #region methods region
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  //handles drop logic
  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (!destination) return;

    // Move Tasks
    if (source.droppableId === destination?.droppableId) {
      // Move a task in same board.
      let reOrderedTasks = [...tasks[destination.droppableId]];
      const sourceTask = {
        ...reOrderedTasks[source.index],
        // boardColumnUpdatedAt: new Date().toString(),
      };
      reOrderedTasks.splice(source.index, 1);
      reOrderedTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setProviderTasks({
          ...tasks,
          [destination?.droppableId]: reOrderedTasks,
        })
      );
      const fromBoardId = onBoardingStages.find(
        (item: any) => item.stageName === source.droppableId
      );

      if (fromBoardId) {
        dispatch(
          moveProviderCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: fromBoardId.id, //null,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else if (source?.droppableId !== destination?.droppableId) {
      // Move task to different board
      let modifiedSourceTasks = [...tasks[source.droppableId]];
      let modifiedDestinationTasks = [...tasks[destination?.droppableId]];
      const sourceTask = {
        ...modifiedSourceTasks[source.index],
        boardColumnUpdatedAt: new Date().toString(),
      };
      modifiedSourceTasks.splice(source.index, 1);
      modifiedDestinationTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setProviderTasks({
          ...tasks,
          [source.droppableId]: modifiedSourceTasks,
          [destination?.droppableId]: modifiedDestinationTasks,
        })
      );
      const fromBoardId = onBoardingStages.find(
        (item: any) => item.stageName === source.droppableId
      );
      const toBoardId = onBoardingStages.find(
        (item: any) => item.stageName === destination.droppableId
      );

      if (fromBoardId && toBoardId) {
        dispatch(
          moveProviderCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: toBoardId.id,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (
      org.orgService &&
      org.orgService.length === 1 &&
      org.orgService.includes(OrgService.MentalHealth)
    ) {
      const singleStage = initonBoardingStages[0];
      setOnBoardingStages([singleStage]);
      setTasks({ [singleStage.stageName]: inittasks[singleStage.stageName] });
    } else {
      setTasks(inittasks);
    }
  }, [initonBoardingStages, org, inittasks]);

  // fetch all providers
  useEffect(() => {
    if (providerList.length === 0) {
      dispatch(GetAllProviders());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerList?.length]);

  //business unit filtering
  useEffect(() => {
    if (
      selectedBusinessUnits.length > 0 &&
      providerList.length > 0 &&
      initonBoardingStages
    ) {
      const filteredProviderIds = providerList
        .filter((provider) =>
          selectedBusinessUnits.some((bu: OrgBU) =>
            provider.employmentDetails.businessUnit.includes(bu.id)
          )
        )
        .map((provider) => provider.employmentDetails.id);
      const filteredTasks = initonBoardingStages.reduce(
        (
          acc: { [x: string]: CardData[] },
          stage: { stageName: string | number }
        ) => {
          const stageCards = inittasks[stage.stageName] || [];
          // Filter cards within the stage based on the selected business unit
          const filteredCards = stageCards.filter((card) =>
            filteredProviderIds.includes(card.entityId)
          );

          acc[stage.stageName] = filteredCards;
          return acc;
        },
        {}
      );

      setTasks(filteredTasks);
    } else {
      setTasks(inittasks);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessUnitsFilterDetails,
    providerList,
    initonBoardingStages,
    inittasks,
  ]);
  // #endregion

  return (
    <>
      <div className="taskHeader">
        <Typography>Filter:</Typography>
        <div className="taskFormController">
          <FormControl className="task-outer">
            <InputLabel id="demo-simple-select-label">
              {"Business Unit"}
            </InputLabel>
            <Select
              className="form-control bufilterDropdown"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: "250px" }}
              value={selectedBusinessUnits}
              onChange={(e) => {
                const selectedId = e.target.value;
                let updatedSelectedBU: OrgBU[] = [...selectedBusinessUnits];

                const isSelected = selectedBusinessUnits.some(
                  (item: OrgBU) => item.id === selectedId
                );

                if (isSelected) {
                  updatedSelectedBU = selectedBusinessUnits.filter(
                    (item: OrgBU) => item.id !== selectedId
                  );
                } else {
                  const newBUFilter = businessUnits.find(
                    (item) => item.id === selectedId
                  );
                  if (newBUFilter) {
                    updatedSelectedBU = [...selectedBusinessUnits, newBUFilter];
                  }
                }
                dispatch(
                  setBusinessUnitFilters({
                    boardId: "provider",
                    businessUnits: updatedSelectedBU,
                  })
                );
              }}
              renderValue={(selected: OrgBU[]) =>
                selected.map((b) => b.businessUnitName).join(", ")
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  removeFocus("demo-simple-select");
                }
              }}
              MenuProps={MenuProps}
            >
              {businessUnits?.map((item: OrgBU) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    checked={selectedBusinessUnits.some(
                      (bu: OrgBU) => bu.id === item.id
                    )}
                  />
                  {item.businessUnitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Loader isShow={loader} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={"boards"}
          type={"boards"}
          direction={"horizontal"}
        >
          {({ innerRef, droppableProps, placeholder }) => (
            <Boards ref={innerRef} {...droppableProps}>
              <div className="allStageCards allStageCardsBoard">
                {Object.keys(tasks).map((stageName, index) => (
                  <Board
                    key={stageName}
                    index={index}
                    stage={
                      onBoardingStages?.filter(
                        (stage: any) => stage.stageName === stageName
                      )[0]
                    }
                    tasks={tasks[stageName]}
                    callbacks={callbacks}
                    chatCommand={chatCommand}
                  />
                ))}
              </div>
              {placeholder}
            </Boards>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default BoardStage;
