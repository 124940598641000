import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Button, Card, Dialog, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Close, DragIndicator, Add } from "@mui/icons-material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { AppDispatch, useAppDispatch, useAppSelector } from "../../redux/store";
import { GetAllProject } from "../../redux/features/project/taskManager-slice";

import { updateBoardIndex } from "../../service/customBoard.service";

import NewBoardModal from "./BoardComponents/addBoard/NewBoardModal";
import NewProjectModal from "./BoardComponents/board-task/NewProjectModal";

import { IHeading } from "../../types/board";

import ClientImg from "../../assets/images/images-svg/User.svg";
import FocusImg from "../../assets/images/images-svg/focus.svg";
import ProjectIcon from "../../assets/images/images-svg/projectIcon.svg";
import ProviderImg from "../../assets/images/images-svg/Team.svg";
import AutomationImg from "../../assets/images/images-svg/Rocket.svg";
import RunsImg from "../../assets/images/images-svg/Sync.svg";

import "./BoardSidePanel.scss";
import {
  setBoardScrollPosition,
  setSelectedBoardTab,
} from "../../redux/features/custom-board/custom-board-slice";
import { useDispatch } from "react-redux";
import { ItemValue } from "survey-core";

interface IBoardProps {
  selectedTab: {
    id: string;
    type: string;
    index?: number | null;
  };
  setSelectedTab: Dispatch<
    SetStateAction<{
      id: string;
      type: string;
      index?: number | null;
    }>
  >;
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

interface IDndComponent {
  selectedTab: {
    id: string;
    type: string;
  };
  setSelectedTab: Dispatch<
    SetStateAction<{
      id: string;
      type: string;
    }>
  >;
  onDragEnd: ({ source, destination }: DropResult) => Promise<void>;
  title: string;
  dndData: IHeading[];
  setScrollPosition: () => void;
}

const DndComponent: React.FC<IDndComponent> = ({
  selectedTab,
  setSelectedTab,
  onDragEnd,
  title,
  dndData,
  setScrollPosition,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      children={
        <div className="Draggable-items">
          <Droppable
            droppableId={title}
            direction="vertical"
            children={(provider) => (
              <div ref={provider.innerRef} {...provider.droppableProps}>
                {dndData.map((board, index) => {
                  return (
                    <Draggable
                      draggableId={board.id}
                      index={index}
                      key={board.id}
                      children={(provider, snapshot) => (
                        <div
                          className="innerTabs__item2"
                          ref={provider.innerRef}
                          {...provider.dragHandleProps}
                          {...provider.draggableProps}
                          style={{
                            backgroundColor: snapshot.isDragging
                              ? "#096dd94f"
                              : "",
                            borderRadius: "4px",
                            ...provider.draggableProps.style,
                          }}
                          key={board.id}
                        >
                          <button
                            onClick={() => {
                              setScrollPosition();
                              setSelectedTab({ id: board.id, type: title });
                              dispatch(
                                setSelectedBoardTab({
                                  id: board.id,
                                  type: title,
                                })
                              );
                            }}
                            className={`itemBtn ${
                              selectedTab.id === `${board.id}`
                                ? "activeBtn"
                                : ""
                            }`}
                          >
                            <DragIndicator />
                            {board.boardName}
                          </button>
                        </div>
                      )}
                    />
                  );
                })}
              </div>
            )}
          />
        </div>
      }
    />
  );
};

const BoardSidePanel: React.FC<IBoardProps> = ({
  selectedTab,
  setSelectedTab,
  drawerOpen,
  setDrawerOpen,
}) => {
  const dispatch = useAppDispatch();
  const [boardModal, setBoardModal] = useState(false);
  const [heading, setHeading] = useState<{
    client: IHeading[];
    provider: IHeading[];
    project: { id: string; title: string; index?: number }[];
  }>({ client: [], provider: [], project: [] });
  const [modal, setModal] = useState(false);

  const customBoards = useAppSelector((state) => state.boardSlice.allBoard);
  const projectList = useAppSelector((state) => state.taskManagerSlice.project);
  const scrollPosition = useAppSelector(
    (state) => state.boardSlice.scrollPosition
  );

  const sideBarData = [
    {
      icon: FocusImg,
      title: "Focus",
      childrens: [
        { id: "My Tasks", title: "My Tasks" },
        { id: "Client Hours", title: "Client Hours" },
        { id: "Employee Hours", title: "Employee Hours" },
        { id: "Intake Dashboard", title: "Intake Dashboard" },
        { id: "Onboarding Dashboard", title: "Onboarding Dashboard" },
      ],
    },
    {
      icon: ClientImg,
      title: "Clients",
      childrens: [{ id: "Intake", title: "Intake" }],
      dndData: heading.client,
    },
    {
      icon: ProviderImg,
      title: "Employees",
      childrens: [{ id: "Onboarding", title: "Onboarding" }],
      dndData: heading.provider,
    },
    {
      icon: ProjectIcon,
      title: "Projects",
      childrens: heading.project,
      add: true,
    },
  ];

  const sideBarBottomData = [
    { icon: AutomationImg, title: "Automation" },
    { icon: RunsImg, title: "Logs" },
  ];

  const onDragEnd = async ({ source, destination }: DropResult) => {
    if (!source || !destination) return;
    if (source.index === destination?.index) return;
    if (source.droppableId !== destination.droppableId) return;
    const tab = source.droppableId === "Clients" ? "client" : "provider";
    let headers = heading[tab];
    if (source.index < destination.index) {
      let reserve: any;

      for (let index = destination.index; index >= source.index; index--) {
        let temp = headers[index];
        if (reserve) headers[index] = { ...reserve, index };
        reserve = temp;
      }
      if (reserve)
        headers[destination.index] = { ...reserve, index: destination.index };
    }

    if (source.index > destination.index) {
      let reserve: any;

      for (let index = destination.index; index <= source.index; index++) {
        let temp = headers[index];
        if (reserve) headers[index] = { ...reserve, index };
        reserve = temp;
      }
      if (reserve)
        headers[destination.index] = { ...reserve, index: destination.index };
    }
    const siftedData = { ...heading, [tab]: headers };
    setHeading(siftedData);
    const uploadData = Object.values(siftedData).flat(1);
    await updateBoardIndex(uploadData);
  };

  const boardExists = (name: string) =>
    customBoards.some(({ boardName }) => boardName === name);

  const handleScrollPosition = () => {
    const [boardTemplate] = document.getElementsByClassName("boardTemplate");
    const data = {
      boardId: selectedTab.id,
      // value: boardTemplate?.scrollLeft,
      scrollLeft: boardTemplate.scrollLeft,
      scrollTop: boardTemplate.scrollTop,
    };
    // const updatedScrollPosition = scrollPosition.map((item) =>
    //   item.boardId === selectedTab.id
    //     ? { ...item, value: boardTemplate?.scrollLeft }
    //     : item
    // );

    const updatedScrollPosition = scrollPosition.map((item) =>
      item.boardId === selectedTab.id
        ? {
            ...item,
            scrollLeft: boardTemplate.scrollLeft,
            scrollTop: boardTemplate.scrollTop,
          }
        : item
    );

    if (!scrollPosition.some((item) => item.boardId === selectedTab.id)) {
      updatedScrollPosition.push(data);
    }
    dispatch(setBoardScrollPosition(updatedScrollPosition));
  };

  useEffect(() => {
    const client = customBoards
      ?.filter((data) => data.boardType === "client")
      .sort(({ index: a }, { index: b }) => (a || 0) - (b || 0))
      .map(({ id, boardName, boardType }) => ({
        id,
        boardType,
        boardName,
      }));
    const provider = customBoards
      ?.filter((data) => data.boardType === "provider")
      .sort(({ index: a }, { index: b }) => (a || 0) - (b || 0))
      .map(({ id, boardName, boardType }) => ({
        id,
        boardType,
        boardName,
      }));
    const project = projectList.map(({ id, name: title }, index) => ({
      id,
      title,
      index,
    }));

    setHeading({ client, provider, project });
  }, [customBoards, projectList]);

  useEffect(() => {
    dispatch(GetAllProject());
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgb(236, 246, 255)",
        display: "sticky",
        left: 0,
      }}
    >
      <div className="sidePanel">
        <button
          className="sidePanel__closeBtn"
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          <Close color="primary" />
        </button>
        <div className="sidePanel_boards">
          <div style={{ padding: "25px", width: "100%" }}>
            <Button
              className="sidePanel_createBtn"
              onClick={() => setBoardModal(true)}
            >
              + Create board
            </Button>
          </div>
          {sideBarData.map(({ title, icon, childrens, dndData, add }) => (
            <Card className="sidePannelContainer" key={title}>
              <div className="sidePannelContainer__header">
                <div className="itemDiv">
                  <img src={icon} alt={title} />
                  {title === "Providers" ? "Employees" : title} 
                </div>
                {add && (
                  <Add
                    onClick={() => setModal(true)}
                    color="info"
                    fontSize="medium"
                    sx={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="sidePannelContainer__body">
                {childrens.map((child, index) => (
                  <button
                    key={child.id}
                    onClick={() => {
                      handleScrollPosition();
                      dispatch(
                        setSelectedBoardTab({
                          id: child.id,
                          type: title,
                        })
                      );
                    }}
                    className={
                      selectedTab.id === child.id
                        ? "itemBtn activeBtn"
                        : "itemBtn"
                    }
                  >
                    {child.title}
                  </button>
                ))}
                {dndData && dndData?.length > 0 ? (
                  <DndComponent
                    title={title === "Providers" ? "Employees" : title} 
                    dndData={dndData}
                    onDragEnd={onDragEnd}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    setScrollPosition={handleScrollPosition}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card>
          ))}
        </div>
        <Card className="bottomDiv">
          {sideBarBottomData.map(({ icon, title }) => (
            <button
              key={title}
              onClick={() =>
                setSelectedTab({ type: title, id: title, index: null })
              }
              className={`itemBtn itemBottomDiv ${
                selectedTab.id === title ? "activeBtn" : ""
              }`}
            >
              <img src={icon} alt="title" />
              {title}
            </button>
          ))}
        </Card>
        <Dialog open={boardModal} className="boardLargeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Create Board
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => setBoardModal(false)}
              >
                <Close />
              </Button>
            </Box>
          </Grid>
          <NewBoardModal
            boardExists={boardExists}
            addedNew={true}
            setModal={setBoardModal}
          />
        </Dialog>
        <Dialog open={modal} className="boardLargeModel">
          <NewProjectModal
            setBoardModal={setModal}
            isEdit={false}
            setSelectedTab={setSelectedTab}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default BoardSidePanel;
