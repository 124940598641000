import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  addNewCustomBoard,
  setNewBoard,
  resetNewBoard,
  updateCustomBoard,
} from "../../../../redux/features/custom-board/custom-board-slice";

import StageComponent from "./StageComponent";

import "./NewBoardModal.scss";

interface INewBoardModal {
  setModal: Function;
  addedNew: boolean;
  boardExists: (boardName: string) => boolean;
}
const NewBoardModal: React.FC<INewBoardModal> = ({
  setModal,
  addedNew,
  boardExists,
}) => {
  //hand;e dispatch data
  const dispatch = useAppDispatch();
  //handle basic form
  const [basicForm, setBasicForm] = useState("basic");
  //handle Error
  const [hasError, setError] = useState(false);
  //handle Message Error
  const [errorMsg, setErrorMsg] = useState("");
  //handle Rank Index
  const [rankIndex, setRankIndex] = useState(1);
  //handle new Board data
  const newBoard = useAppSelector((state) => state.boardSlice.newBoard);

  //#endregion

  //#region methods region
  //handle add Stage handler
  const addStageHandler = () => {
    if (
      newBoard.stages.length > 0 &&
      !newBoard.stages[newBoard.stages.length - 1].stageName
    ) {
      setError(true);
      setErrorMsg("Enter the name of previous stage");

      setTimeout(() => setError(false), 2000);
      return;
    }

    dispatch(
      setNewBoard({
        name: "stages",
        value: [
          ...newBoard.stages,
          {
            id: "",
            name: "",
            goal: "1 day",
            client: [],
            provider: [],
            checkList: [],
            index: rankIndex,
          },
        ],
      })
    );

    setRankIndex(rankIndex + 1);
  };

  //handle submit handler
  const submitHandler = () => {
    if (!newBoard.name) {
      setError(true);
      setErrorMsg("Enter the name of Board");
      setTimeout(() => setError(false), 2000);
      return;
    }

    if (addedNew && boardExists(newBoard.name)) {
      setError(true);
      setErrorMsg("Board name already exists");
      return;
    }
    /* turn off by Hui - relax this!!
    if (!newBoard.description) {
      setError(true);
      setErrorMsg("Enter the description of Board");
      setTimeout(() => setError(false), 2000);
      return;
    }*/
    if (!newBoard.boardType) {
      setError(true);
      setErrorMsg("Select the type of Board");
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!(newBoard.stages.length > 0)) {
      setError(true);
      setErrorMsg("Enter the stages for Board");
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (newBoard.stages.length > 0) {
      for (let i = 0; i < newBoard.stages.length; i++) {
        if (!newBoard.stages[i].stageName) {
          setError(true);
          setErrorMsg("Enter the name of all stages");
          setTimeout(() => setError(false), 2000);
          return;
        }
      }
    }
    if (addedNew) {
      dispatch(addNewCustomBoard());
    } else {
      dispatch(updateCustomBoard());
    }

    setModal(false);
    dispatch(resetNewBoard());
  };
  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (!addedNew) {
      setRankIndex(newBoard.stages.length);
    }
  }, []);
  //#endregion

  return (
    <div className="create-new-board ">
      <div className="tab-section">
        <ul className="innerTabs m-0 mb-8">
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setBasicForm("basic");
              }}
              className={basicForm === "basic" ? "active" : ""}
            >
              Basic
            </button>
          </li>
          <li className="innerTabs__item">
            <button
              onClick={() => {
                setBasicForm("stages");
              }}
              className={basicForm === "stages" ? "active" : ""}
            >
              Stages
            </button>
          </li>
        </ul>
        {basicForm === "stages" && (
          <div onClick={addStageHandler}>
            <span className="linkBtn">+ New Stage</span>
          </div>
        )}
      </div>
      {basicForm === "basic" && (
        <Grid className="create-new-board-inner">
          <Grid size={{ xs: 12, md: 6 }}>
            <Box className="fromGroup mb-8">
              <InputLabel className="fromLabel" variant="standard">
                Title
              </InputLabel>
              <FormControl>
                <TextField
                  disabled={newBoard.primitive}
                  value={newBoard.name}
                  onChange={(e) => {
                    dispatch(
                      setNewBoard({ name: "name", value: e.target.value })
                    );
                  }}
                  onKeyUp={() => {
                    if (addedNew && boardExists(newBoard.name)) {
                      setError(true);
                      setErrorMsg("Board name already exists");
                      return;
                    } else {
                      setErrorMsg("");
                    }
                  }}
                />
              </FormControl>
            </Box>
            <Box className="fromGroup mb-8">
              <InputLabel className="fromLabel">Description</InputLabel>
              <FormControl>
                <TextField
                  value={newBoard.description}
                  disabled={newBoard.primitive}
                  onChange={(e) => {
                    dispatch(
                      setNewBoard({
                        name: "description",
                        value: e.target.value,
                      })
                    );
                  }}
                />
              </FormControl>
            </Box>
            <Box className="fromGroup mb-8">
              <Box className="fromGroup">
                <InputLabel className="fromLabel">Tracking Type</InputLabel>
                <div className="fromGroup-chips">
                  {addedNew ? (
                    <>
                      <Card
                        className={
                          newBoard.boardType === "client"
                            ? "fromGroup-chip-active"
                            : ""
                        }
                        onClick={
                          newBoard.primitive
                            ? () => {}
                            : () =>
                                dispatch(
                                  setNewBoard({
                                    name: "boardType",
                                    value: "client",
                                  })
                                )
                        }
                      >
                        Client
                      </Card>
                      <Card
                        className={
                          newBoard.boardType === "provider"
                            ? "fromGroup-chip-active"
                            : ""
                        }
                        onClick={
                          newBoard.primitive
                            ? () => {}
                            : () =>
                                dispatch(
                                  setNewBoard({
                                    name: "boardType",
                                    value: "provider",
                                  })
                                )
                        }
                      >
                        Employee
                      </Card>
                    </>
                  ) : (
                    <Card
                      className={
                        newBoard.boardType === "client" ||
                        newBoard.boardType === "provider"
                          ? "fromGroup-chip-inactive"
                          : ""
                      }
                    >
                      {newBoard.boardType === "client"
                        ? "Client"
                        : newBoard.boardType === "provider"
                        ? "Employee"
                        : ""}
                    </Card>
                  )}
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      {basicForm === "stages" && (
        <StageComponent
          newBoard={newBoard}
          setError={setError}
          setErrorMsg={setErrorMsg}
          key={newBoard.id}
        />
      )}
      {/* deprecate this !!!
        {basicForm === "automation" && newBoard.automation && (
        <Automation setErrorMsg={setErrorMsg} setError={setError} />
      )} */}
      {hasError && (
        <FormHelperText style={{ color: "#d32f2f" }}>{errorMsg}</FormHelperText>
      )}
      <Box className="create-new-board-footer ptb-15">
        <Button onClick={submitHandler} variant="contained">
          {addedNew ? `Create Board` : `Save Board`}{" "}
        </Button>
      </Box>
    </div>
  );
};

export default NewBoardModal;
