import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "./SubProviderInsuranceDetails";
import { Close, BorderColor } from "@mui/icons-material";
import BackupIcon from "@mui/icons-material/Backup";
import DeleteIcon from "@mui/icons-material/Delete";

import { MenuProps } from "src/constants/providers";
import { MuiFileInput } from "mui-file-input";
import { ProviderCertificate } from "src/types/provider";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { NewDataCatalog } from "src/types/businessSettings";
import { DataCatalogConcepts } from "src/constants/catalog";
import {
  DeleteCertificateById,
  PostCertificate,
  UpdateCertificateById,
} from "src/redux/features/providers-main/provider-list-slice";

interface ICurrentProviderCertificate
  extends Omit<ProviderCertificate, "provider"> {}

const ProviderCertificates: React.FC<{
  providerCertificates: ProviderCertificate[];
  providerId: string;
}> = ({ providerCertificates, providerId }) => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("basic");
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentCertificate, setCurrentCertificate] =
    useState<ICurrentProviderCertificate>({
      id: "",
      certificateName: "",
      expirationDate: "",
      files: [],
    });
  //new uploaded files
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const certificates = useAppSelector<NewDataCatalog[]>((state) =>
    state.dataCatalogSlice.NewDataCatalog.filter(
      (item) => item.catalogName === DataCatalogConcepts.Certificate
    )
  );

  const handleCloseModal = () => {
    //resets the state of the component when the modal is closed.
    setOpenModal(false);
    setHasError(false);
    setCurrentCertificate({
      id: "",
      certificateName: "",
      expirationDate: "",
      files: [],
    });
    setActiveTab("basic");
    setNewFiles([]);
  };

  const handleSave = () => {
    //validate for providerId
    if (!providerId) {
      return;
    }
    //validatation for certificate data
    if (
      !currentCertificate?.certificateName ||
      !currentCertificate?.expirationDate
    ) {
      setHasError(true);
      return;
    }
    if (!currentCertificate.id) {
      // new certificate
      dispatch(
        PostCertificate({
          providerId: providerId,
          certificateName: currentCertificate.certificateName,
          expirationDate: currentCertificate.expirationDate,
          files: newFiles,
        })
      );
    } else {
      // update existing certificate
      dispatch(
        UpdateCertificateById({
          id: currentCertificate.id,
          certificateName: currentCertificate.certificateName,
          expirationDate: currentCertificate.expirationDate,
          files: currentCertificate.files, //existing files
          providerId: providerId,
          newFiles, //newFiles
        })
      );
    }
    handleCloseModal(); //close the modal
  };

  return (
    <Grid size={{ xs: 12 }} sx={{ mt: "22px" }}>
      <div className="listingTable">
        <div className="listingTable__header">
          <Typography className="listingTable__title" gutterBottom variant="h6">
            Certificates
          </Typography>
          <div style={{ position: "relative", textAlign: "right" }}>
            <Button
              onClick={() => {
                setOpenModal(true);
                setActiveTab("basic");
              }}
              className="addBtn"
            >
              + Add Certificate
            </Button>
          </div>
        </div>
        <TableContainer
          component={Paper}
          className="tableResponsive certificatesTable"
        >
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Certificate Name</StyledTableCell>
                <StyledTableCell>Expiration Date</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Files</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerCertificates?.length !== 0 ? (
                providerCertificates?.map((item) => (
                  <StyledTableRow style={{ borderRadius: 20 }} key={item.id}>
                    <StyledTableCell>{item.certificateName}</StyledTableCell>
                    <StyledTableCell>{item.expirationDate}</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                      {item.files.map((item, index) => (
                        <div key={index}>{item.fileName}</div>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="setDate">
                        <span
                          onClick={() => {
                            setActiveTab("basic");
                            setCurrentCertificate(item);
                            setOpenModal(true);
                          }}
                        >
                          <BorderColor />
                        </span>
                        <span
                          onClick={() => {
                            setCurrentCertificate(item);
                            setDeleteConfirmationPopup(true);
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow style={{ borderRadius: 20 }}>
                  <StyledTableCell colSpan={5}>No Certificates</StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </div>

      <Dialog open={openModal} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Add Certificates
          </Typography>
          <Button className="largeModel__closeBtn" onClick={handleCloseModal}>
            <Close />
          </Button>
        </Box>
        <DialogContent>
          <div className="stepperForm__inner">
            <Grid container spacing={3} sx={{ position: "relative" }}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <div className="authListBox">
                  <div className="authListBox__item">
                    <div className="authListBox__head">
                      <p className="authListBox__title">
                        {currentCertificate?.certificateName
                          ? currentCertificate?.certificateName
                          : "Certificate"}
                      </p>
                    </div>
                    <div className="authListBox__body">
                      <span
                        className={
                          activeTab === "basic"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("basic");
                        }}
                      >
                        Basic
                      </span>
                      <span
                        className={
                          activeTab === "files"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("files");
                        }}
                      >
                        Files
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "basic" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, sm: 9 }}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Certificate
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={currentCertificate?.certificateName}
                          id="authInsuranceProvider"
                          onChange={(e: SelectChangeEvent<string>) => {
                            setCurrentCertificate({
                              ...currentCertificate,
                              certificateName: e.target.value,
                            });
                          }}
                          MenuProps={MenuProps}
                        >
                          {certificates.map((item) => (
                            <MenuItem key={item.id} value={item.conceptValue}>
                              {item.conceptValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      {hasError && !currentCertificate?.certificateName && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select certificate name.
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid size={{ xs: 12, sm: 9 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Expiration Date
                        </InputLabel>
                        <TextField
                          id="date"
                          placeholder="Select Date"
                          type="date"
                          value={currentCertificate.expirationDate}
                          sx={{ width: 220 }}
                          onChange={(e) => {
                            setCurrentCertificate({
                              ...currentCertificate,
                              expirationDate: e.target.value,
                            });
                          }}
                        />
                      </Box>
                      {hasError && !currentCertificate?.expirationDate && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select expiration date.
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Box className="fromGroup">
                    <Grid container spacing={3}>
                      <Grid
                        size={{ xs: 12, md: 6, lg: 12 }}
                        className="fileUploadBlock"
                      >
                        <Box className="uploadIconBox">
                          <BackupIcon color="primary" className="uploadIcon" />
                          <Typography>
                            Drag 'n' drop files here or click to add files
                          </Typography>
                        </Box>
                        <MuiFileInput
                          multiple
                          hideSizeText
                          sx={{
                            "& .css-1fel157 > span": { display: "none" },
                            "& .css-flami6": { display: "none" },
                            "& fieldset": { display: "none" },
                          }}
                          value={newFiles}
                          onChange={(e: File[]) => {
                            console.log(e, "e");
                            setNewFiles([...newFiles, ...e]);
                          }}
                        />
                      </Grid>

                      <Grid size={{ xs: 12, md: 6, lg: 12 }}>
                        <div className="uploadProfile__img selectedIn-fileBox client-addAuth-uploadProfile">
                          {currentCertificate.files.length + newFiles.length >
                          0 ? (
                            <>
                              {/* existingFiles */}
                              {currentCertificate.files.map(
                                (item: any, index: number) => (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>{item.fileName}</h1>
                                  </div>
                                )
                              )}
                              {/* newFiles */}
                              {newFiles.map((item, index) => (
                                <div className="selectedIn-file" key={index}>
                                  <h1>{item.name}</h1>
                                  <span>
                                    <DeleteIcon
                                      onClick={() => {
                                        setNewFiles(
                                          newFiles.filter(
                                            (_, fileIndex) =>
                                              fileIndex !== index
                                          )
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          ) : (
                            <p className="selectedIn-fileBlank">
                              Upload insurance and assessment documents here.
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
        <Box className="stepperForm__footer">
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit" className="button" onClick={handleSave}>
            {"Save Changes"}
          </Button>
        </Box>
      </Dialog>
      <Dialog open={deleteConfirmationPopup} className="smallModel">
        <div>
          <DialogContent>
            Do you want to delete this Certificate ?
          </DialogContent>
          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={() => setDeleteConfirmationPopup(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                if (currentCertificate.id) {
                  dispatch(
                    DeleteCertificateById({
                      certificateId: currentCertificate.id,
                    })
                  );
                }
                setDeleteConfirmationPopup(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Grid>
  );
};

export default ProviderCertificates;
