//constant/provider.ts

import { AvailabilityDetails } from "../types/availability";
import {
  ProviderForms,
  Index,
  ProviderAPIResponse,
  Time,
  TimeError,
  Credentials,
  EmploymentDetails,
  ContactDetails,
} from "../types/provider";
import moment from "moment";
import { defaultUtilization } from "./availability";

//provider status
export const ProviderStatuses = [
  "Onboarding",
  "Established",
  "Inactive",
] as const;

//Stepper Label constant for Provider forms
export const providerSteps = [
  { label: "Employment" },
  { label: "Contact" },
  { label: "Availability" },
] as const;

//EmploymentDetails Form constant
export const EmploymentStatus = [
  { key: 0, value: "Full-Time" },
  { key: 1, value: "Part-Time" },
] as const;

//EmploymentDetails Form constant
export const Languages = [
  { key: 0, value: "English" },
  { key: 1, value: "Spanish" },
] as const;

//ContactDetails Form constant
export const PrefferedContactMethod = [
  { key: 0, value: "Phone" },
  { key: 1, value: "Email" },
  { key: 2, value: "Text" },
] as const;

//ContactDetails Form constant
export const ContactTimeDetails = [
  { key: 0, value: "Morning" },
  { key: 1, value: "Afternoon" },
  { key: 2, value: "Evening" },
] as const;

//AvailabilityDetailsForm constant
export const AvailabilityTimesOne: string[] = [
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
  "12:00 AM",
];

//AvailabilityDetailsForm constant
export const AvailabilityTimes: Time[] = [
  { key: 0, value: "06:00 AM" },
  { key: 1, value: "06:15 AM" },
  { key: 2, value: "06:30 AM" },
  { key: 3, value: "06:45 AM" },
  { key: 4, value: "07:00 AM" },
  { key: 5, value: "07:15 AM" },
  { key: 6, value: "07:30 AM" },
  { key: 7, value: "07:45 AM" },
  { key: 8, value: "08:00 AM" },
  { key: 9, value: "08:15 AM" },
  { key: 10, value: "08:30 AM" },
  { key: 11, value: "08:45 AM" },
  { key: 12, value: "09:00 AM" },
  { key: 13, value: "09:15 AM" },
  { key: 14, value: "09:30 AM" },
  { key: 15, value: "09:45 AM" },
  { key: 16, value: "10:00 AM" },
  { key: 17, value: "10:15 AM" },
  { key: 18, value: "10:30 AM" },
  { key: 19, value: "10:45 AM" },
  { key: 20, value: "11:00 AM" },
  { key: 21, value: "11:15 AM" },
  { key: 22, value: "11:30 AM" },
  { key: 23, value: "11:45 AM" },
  { key: 24, value: "12:00 PM" },
  { key: 25, value: "12:15 PM" },
  { key: 26, value: "12:30 PM" },
  { key: 27, value: "12:45 PM" },
  { key: 28, value: "01:00 PM" },
  { key: 29, value: "01:15 PM" },
  { key: 30, value: "01:30 PM" },
  { key: 31, value: "01:45 PM" },
  { key: 32, value: "02:00 PM" },
  { key: 33, value: "02:15 PM" },
  { key: 34, value: "02:30 PM" },
  { key: 35, value: "02:45 PM" },
  { key: 36, value: "03:00 PM" },
  { key: 37, value: "03:15 PM" },
  { key: 38, value: "03:30 PM" },
  { key: 39, value: "03:45 PM" },
  { key: 40, value: "04:00 PM" },
  { key: 41, value: "04:15 PM" },
  { key: 42, value: "04:30 PM" },
  { key: 43, value: "04:45 PM" },
  { key: 44, value: "05:00 PM" },
  { key: 45, value: "05:15 PM" },
  { key: 46, value: "05:30 PM" },
  { key: 47, value: "05:45 PM" },
  { key: 48, value: "06:00 PM" },
  { key: 49, value: "06:15 PM" },
  { key: 50, value: "06:30 PM" },
  { key: 51, value: "06:45 PM" },
  { key: 52, value: "07:00 PM" },
  { key: 53, value: "07:15 PM" },
  { key: 54, value: "07:30 PM" },
  { key: 55, value: "07:45 PM" },
  { key: 56, value: "08:00 PM" },
  { key: 57, value: "08:15 PM" },
  { key: 58, value: "08:30 PM" },
  { key: 59, value: "08:45 PM" },
  { key: 60, value: "09:00 PM" },
  { key: 61, value: "09:15 PM" },
  { key: 62, value: "09:30 PM" },
  { key: 63, value: "09:45 PM" },
  { key: 64, value: "10:00 PM" },
  { key: 65, value: "10:15 PM" },
  { key: 66, value: "10:30 PM" },
  { key: 67, value: "10:45 PM" },
  { key: 68, value: "11:00 PM" },
  { key: 69, value: "11:15 PM" },
  { key: 70, value: "11:30 PM" },
  { key: 71, value: "11:45 PM" },
  { key: 72, value: "12:00 AM" },
];

//AvailabilityDetailsForm constant
export const AvailabilityDays = [
  { key: 0, value: "Sunday", checked: false },
  { key: 1, value: "Monday", checked: false },
  { key: 2, value: "Tuesday", checked: false },
  { key: 3, value: "Wednesday", checked: false },
  { key: 4, value: "Thursday", checked: false },
  { key: 5, value: "Friday", checked: false },
  { key: 6, value: "Saturday", checked: false },
] as const;

//FilterForm constant
export const filterProviderStatuses = [
  "Onboarding",
  "Established",
  "Inactive",
] as const;

export enum EntityScheduleStatus {
  NoSchedule = "No Schedule",
  PartialSchedule = "Partial Schedule",
  FullSchedule = "Full Schedule",
  NoAuth = "No Auth",
  AuthExpired = "Auth Expired",
  NoAvailability = "No Availability",
  AvailabilityExpired = "Availability Expired",
}

// export const filterScheduleStatuses = [
//   "No Schedule",
//   "Partial Schedule",
//   "Full Schedule",
//   "No Auth",
//   "Auth Expired",
//   "No Availability",
//   "Availability Expired",
// ] as const;

//FilterForm constant
export const filterAvailabilityTypes = ["Current", "Expired"] as const;

//FilterForm constant
export const filterOnTrackChangeTypes = [
  "New Hire/Signed Offer Letter",
  "HR Paperwork Pending",
  "Credentialing Paperwork Pending",
  "Credentialing Packet: Complete",
  "Credentialing: Apps Submitted to All Payers",
  "Onboarding/Training in Process",
  "Credentialing: Complete",
] as const;

//FilterForm constant
export const filterConcernedStageTypes = [
  "Waiting for HR Paperwork",
  "Waiting for Credentialing",
  "Onboarding discontinued",
] as const;

//Menu props for the select Mui component or element
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Array to manage selected days validation errors
export const TimeErrors: TimeError[] = [
  { startTime: false, endTime: false },
  { startTime: false, endTime: false },
  { startTime: false, endTime: false },
  { startTime: false, endTime: false },
  { startTime: false, endTime: false },
  { startTime: false, endTime: false },
  { startTime: false, endTime: false },
];

//stores the start and end index for the selected days to manage the select drop down
export const TimeIndex: Index[] = [
  { startIndex: 0, endIndex: 0 },
  { startIndex: 0, endIndex: 0 },
  { startIndex: 0, endIndex: 0 },
  { startIndex: 0, endIndex: 0 },
  { startIndex: 0, endIndex: 0 },
  { startIndex: 0, endIndex: 0 },
  { startIndex: 0, endIndex: 0 },
];

// initial state of ProviderInsurance
export const ProviderInsurance: Credentials = {
  name: "",
  id: "",
  insuranceId: "",
  providerId: "",
  complete: true,
  expirationDate: "",
  files: [],
};

// initial state of employementDetails
export const employmentDetails: EmploymentDetails = {
  id: "",
  //providerRemainingHours: 0,
  //bookedServices: [],
  createdAt: "",
  updatedAt: "",
  firstName: "",
  lastName: "",
  businessUnit: [],
  providerType: "Onboarding",
  employmentStatus: "",
  languages: [],
  type: "",
  specialities: [],
  checklistHistory: [],
  onBoardingStage: "New Hire/Signed Offer Letter",
  providerProfile: null,
  messageCount: 0,
  isSupervisor: false,
  providerList: [],
  clientList: [],
  supervisor: {
    id: "",
    team: [],
    client: [],
    createdAt: "",
    updatedAt: "",
  },
  lastMessage: 0,
  client:[]
};

// initial state of Contact Details
export const contactDetails: ContactDetails = {
  id: "",
  createdAt: "",
  updatedAt: "",
  entityType: "Provider",
  entityId: null,
  phoneNumber: "",
  email: "",
  address: { fullAddress: "", lng: 0, lat: 0 },
  schoolAddress: { fullAddress: "", lng: 0, lat: 0 },
  schoolCity: "",
  schoolState: "",
  schoolZipCode: "",
  city: "",
  state: "",
  zipCode: "",
  contactMethod: "",
  bestTimeToContact: "",
  otherAddresses: [],
};

// initial state for Availability
export const defaultProviderAvailability: AvailabilityDetails = {
  id: "",
  entityType: "Provider",
  entityId: null,
  beginDate: moment().local().format("YYYY-MM-DD"),
  endDate: moment().local().add(6, "M").local().format("YYYY-MM-DD"),
  createdAt: "",
  updatedAt: "",
  selectedDays: [
    {
      dayOfWeek: "",
      startTime: "",
      endTime: "",
    },
  ],
  heatMap: [],
  timeSlots: [],
  availabilitySelectedDays: [],
  availabilityName: "",
};

//initial state for the providerSlice
export const ProvidersInitialState: ProviderForms = {
  employmentDetails: employmentDetails,
  contactDetails: contactDetails,
  availabilityDetails: [defaultProviderAvailability],
  providerInsurance: [ProviderInsurance],
  providerFiles: [],
  providerMessages: [],
  supervisor: {
    id: "",
    supervisorId: "",
    client: [
      {
        id: "",
        parentFirstName: "",
        parentLastName: "",
        childFirstName: "",
        childLastName: "",
        childDateOfBirth: "",
        childGender: "",
        childLanguages: [],
        businessUnit: "",
        concerns: "",
        hearAboutUs: "",
        insuranceProvider: "",
        clientType: "Intake",
        onTrackStage: "",
        checklistHistory: [],
        intakeStage: "New",
        clientProfile: {},
        files: [],
      },
    ],

    team: [
      {
        id: "",
        createdAt: "",
        updatedAt: "",
        firstName: "",
        lastName: "",
        businessUnit: "",
        providerType: "Onboarding",
        employmentStatus: "",
        languages: [],
        type: "",
        files: [],
        checklistHistory: [],
        onBoardingStage: "New Hire/Signed Offer Letter",
        providerProfile: null,
        isSupervisor: false,
        //canBeSupervisor: true,
        supervisorId: "",
      },
    ],
  },
  //canBeSupervisor: true,
  schedules: [],
  bookedServices: [],
  providerUtilization: defaultUtilization,
  providerCertificates: []
};

//Initial state for Providers API response
export const ProviderAPIResponseInitialState: ProviderAPIResponse = {
  errors: {
    postError: false,
    updateError: false,
    getListError: false,
    getSingleError: false,
    deleteError: false,
  },
  success: {
    postSuccess: false,
    updateSuccess: false,
    getListSuccess: false,
    getSingleSuccess: false,
    deleteSuccess: false,
  },
  pending: {
    postPending: false,
    updatePending: false,
    getListPending: false,
    getSinglePending: false,
    deletePending: false,
  },
  messages: {
    postMessage: "",
    updateMessage: "",
    getListMessage: "",
    getSingleMessage: "",
    deleteMessage: "",
  },
};

//Types for Images
export const ImageTypes: string[] = ["jpg", "jpeg", "png"];

//WeekDays
export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// export const ProviderCheckList: string[] = [
//   "RBT Training in Process",
//   "RBT Training Complete",
//   "Orientation Training in Process",
//   "Orientation Training Complete",
//   "RBT Exam Scheduled",
//   "RBT Exam Passed",
//   "License Pending",
//   "CAQH Pending",
//   "NPI Pending",
//   "Paperwork Complete",
//   "Waiting for HR Paperwork",
//   "Waiting for Credentialing",
//   "Onboarding Discontinued",
// ];

export const providerPopoverOptions: string[] = [
  "Update Employment",
  "Update Contact",
  "Update Availability",
  "Delete Card",
];
